import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {LoginComponent} from '../pages/login/login';
import { RegisterComponent } from '../pages/register/register';
import { RegisterTestComponent } from '../pages/registerTest/registerTest';
import {AdminComponent} from '../pages/admin/admin';
import {MenuComponent} from '../pages/menu/menu';
import {CustomersComponent} from '../pages/customer/customer';
import {BillComponent} from '../pages/bill/bill';
import {CashComponent} from '../pages/cash/cash';
import {ProductComponent} from '../pages/product/product';
import {RapportsComponent} from '../pages/rapports/rapports';
import {SettingsComponent} from '../pages/settings/settings';
import {StatsComponent} from '../pages/stats/stats';
import { ExportComponent } from '../pages/export/export';
import {ChangelogComponent} from '../pages/changelog/changelog';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:idLicense', component: LoginComponent },
  { path: 'register/:token', component: RegisterComponent },
  { path: 'register', component: RegisterTestComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'customers', component: CustomersComponent },
  { path: 'customers/:idCustomer', component: CustomersComponent },
  { path: 'bills', component: BillComponent },
  { path: 'cash', component: CashComponent },
  { path: 'product', component: ProductComponent },
  { path: 'rapports', component: RapportsComponent },
  { path: 'rapports/:idCashRegister/:date', component: RapportsComponent },
  { path: 'rapports/:idCashRegister/:date/:subPage', component: RapportsComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'settings/:subPage', component: SettingsComponent },
  { path: 'settings/:subPage/:idCashRegister', component: SettingsComponent },
  { path: 'stats', component: StatsComponent },
  { path: 'export', component: ExportComponent },
  { path: 'changelog', component: ChangelogComponent },
  { path: '', redirectTo: '/login',  pathMatch: 'full' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
