import {Input, Output, Component, Inject, EventEmitter, OnInit } from '@angular/core';
import { TicketLite, Payment } from '../../model/ticket';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'ticket',
  templateUrl: 'ticket.html',
  styleUrls:['./ticket.css']
})

export class TicketDisplay implements OnInit{

  @Input() ticket: TicketLite;
  @Input() anotherPayments:any[] = [];
  @Input() onlinePayments:Payment[] = [];

  vatList = [];

  constructor(public userService: UserService) {

  }

  ngOnInit(){
    this.generateVatList();
    if(this.ticket.isCumulativeTicket()){
      this.ticket.fusionRecashAndCash();
    }
    this.ticket.payments.sort((payment1, payment2) => {
      var name1 = payment1.formatTypePayment(this.anotherPayments);
      var name2 = payment2.formatTypePayment(this.anotherPayments);
      if(name1 < name2) return -1;
      else if(name1 > name2) return 1;
      return 0;
    });
  }

  generateVatList(){
    if(this.ticket.tvaList.length == 0 || this.ticket.tvaList[0].ht == 0){
      var vatListTemp = {};
      for(var i = 0; i < this.ticket.shoppingList.length; i++){
        var shoppingLine = this.ticket.shoppingList[i];
        var tva = shoppingLine.tva;
        var _id = tva._id;
        if(vatListTemp[_id] == null){
          vatListTemp[_id] = tva;
          vatListTemp[_id].ttc = 0;
          vatListTemp[_id].ht = 0;
          vatListTemp[_id].total = 0;
        }
        vatListTemp[_id].ttc = Number((vatListTemp[_id].ttc +shoppingLine.totalTTCPriceWithReduction).toFixed(2));
        vatListTemp[_id].ht = Number((vatListTemp[_id].ht + shoppingLine.totalHTPriceWithReduction).toFixed(2));
        vatListTemp[_id].total = Number((vatListTemp[_id].total + (shoppingLine.totalTTCPriceWithReduction - shoppingLine.totalHTPriceWithReduction)).toFixed(2));
      }
      this.vatList = Object.values(vatListTemp);
    }
    else {
      this.vatList = this.ticket.tvaList;
    }
    this.vatList.sort(function(vat1, vat2){
      return vat1.value - vat2.value;
    });
  }
}
