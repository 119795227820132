
/**
 * global ticket is the global model of ticket
 */
export class GlobalTicket {
  public shoppingList:ShoppingLine[] = [];
  public payments:Payment[] = [];
  public date = new Date();
  public reduction = {amount:0, type:'purcent', checked:true};
  public type = 'ticket';

  constructor() {

  }

  setReduction(amount, type){
    this.reduction.amount = amount;
    this.reduction.type = type;
    this.initReductionChecked();
  }

  initReductionChecked(){
    if(this.reduction.type == 'currency'){
      this.reduction.checked = false;
    }
    else {
      this.reduction.checked = true;
    }
  }

  setGlobalReduction(amount, type){
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      shoppingLine.setReduction(amount, type);
    }
  }

  swapReductionType(){
    if(!this.reduction.checked) this.reduction.type = 'currency';
    else this.reduction.type = 'purcent';
  }

  getTotalTTCWithoutReduction(){
    var total = 0;
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      total += shoppingLine.getTotalTTCPrice();
    }
    return total;
  }

  getTotalTTC(){
    var total = this.getTotalTTCWithoutReduction() * 1;
    return this.applyReduction(total);
  }

  getTotalHT(){
    var total = 0;
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      total += shoppingLine.getTotalHTPrice() * 1;
    }
    return this.applyReduction(total);
  }

  getTotalTVA(){
    return this.arround(this.getTotalTTC() - this.getTotalHT());
  }

  getQuantities(){
    var quantities = 0;
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      quantities += shoppingLine.getQuantityCalculated() * 1;
    }
    return quantities;
  }

  applyReduction(amount){
    if(this.reduction.type == 'currency'){
      amount -= this.reduction.amount;
    }
    else {
      amount -= (amount * (this.reduction.amount / 100));
    }
    return this.arround(amount);
  }

  isReceipt(){
    return this.type == 'receipt';
  }

  isSimpleTicket(){
    return this.type == 'ticket';
  }

  isXTicket(){
    return this.type == 'x';
  }

  isZTicket(){
    return this.type == 'z';
  }

  isBigZTicket(){
    return this.type == 'bigZ';
  }

  isBigBigZTicket(){
    return this.type == 'bigBigZ';
  }

  isCumulativeTicket(){
    return this.isXTicket() || this.isZTicket() || this.isBigZTicket() || this.isBigBigZTicket();
  }

  setZeroIfReductionAmountNull(){
    if(this.reduction.amount == null){
      this.reduction.amount = 0;
    }
  }

  getPaymentByType(type){
    for(var i = 0; i < this.payments.length; i++){
      var payment = this.payments[i];
      if(payment.type == type){
        return payment;
      }
    }
    return null;
  }

  getTotalPayment(){
    var total = 0;
    for(var i = 0; i < this.payments.length; i++){
      var payment = this.payments[i];
      total += payment.amount * 1;
    }
    return this.arround(total);
  }

  arround(value){
    return Number((value * 1).toFixed(2));
  }
}


/**
*
*/
export class Ticket extends GlobalTicket {
  public idTicketGenerated = '';
  public createDate = new Date();
  public place = null;
  public cashRegister = null;
  public user = null;
  public customer = null;
  public idBooking = null;
  public idBookings = [];
  public idBill = null;

  constructor() {
    super();
  }

  setCustomer(customer){
    this.customer = customer;
    if(this.customer != null && this.customer.tickets != null){
      this.customer.tickets.sort(function(ticketA, ticketB){
        return ticketA.id - ticketB.id;
      });
      console.log(this.customer.tickets);
    }
  }

  addIdBooking(idBooking){
    if(this.idBooking == null && this.idBookings.length == 0){
      this.idBooking = idBooking;
    }
    else {
      if(this.idBooking != null){
        this.idBookings.push(this.idBooking);
        this.idBooking = null;
      }
      this.idBookings.push(idBooking);
    }
  }

  noHaveBooking(){
    return this.idBooking == null && this.idBookings.length == 0;
  }

  getIdBookingsInTicket(){
    var idBookings = [];
    if(this.idBooking != null){
      idBookings.push(this.idBooking);
    }
    idBookings = idBookings.concat(this.idBookings);
    return idBookings;
  }

  clearTime(date:Date){
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  checkDate(){
    var actualDate = this.clearTime(new Date());
    var date = this.clearTime(new Date(this.date));
    if(date.getTime() < actualDate.getTime()){
      this.date = new Date(date);
      this.date.setHours(23);
      this.date.setMinutes(59);
      this.date.setSeconds(59);
      this.date.setMilliseconds(99);
    }
    else if(date.getTime() == actualDate.getTime()){
      this.setActualDate();
    }
  }

  setToOpenDate(){
    if(this.cashRegister != null){
      var openDate = new Date(this.cashRegister.openDate);
      var date = new Date(this.date);
      date.setFullYear(openDate.getFullYear());
      date.setMonth(openDate.getMonth());
      date.setDate(openDate.getDate());
      this.date = new Date(date);
      this.checkDate();
    }
  }

  setActualDate(){
    this.date = new Date();
  }

  setDate(year, month, day){
    this.setActualDate();
    this.date.setFullYear(year);
    this.date.setMonth(month);
    this.date.setDate(day);
  }

  getDate(){
    var date = new Date(this.date);
    var actualDate = new Date();
    if(date.getFullYear() == actualDate.getFullYear() &&
      date.getMonth() == actualDate.getMonth() &&
      date.getDate() == actualDate.getDate()){
      date = new Date(actualDate);
    }
    return date;
  }

  isCashRegisterIsOpened(){
    if(this.cashRegister == null) return false;
    return this.cashRegister.openDate != null && (this.cashRegister.closeDate == null ||
      new Date(this.cashRegister.openDate).getTime() > new Date(this.cashRegister.closeDate).getTime());
  }

  setDateNotClose(){
    if(this.cashRegister != null){
      var closeDate = new Date(this.cashRegister.closeDate);
      if(this.isCashRegisterIsOpened()){
        closeDate = new Date(this.cashRegister.openDate);
      }
      var date = new Date(closeDate);
      date.setTime(date.getTime() + 1000);
      this.date = new Date(date);
      this.checkDate();
    }
  }

  addShoppingLine(shoppingLine){
    var found = false;
    for(var i = 0; i < this.shoppingList.length; i++){
      var localShoppingLine = this.shoppingList[i];
      if(localShoppingLine._id != null &&
        localShoppingLine._id == shoppingLine._id &&
        localShoppingLine.isSamePrice(shoppingLine.price)){
        found = true;
        localShoppingLine.setQuantity(localShoppingLine.quantity + shoppingLine.quantity);
        break;
      }
    }
    if(!found){
      this.shoppingList.push(shoppingLine);
    }
  }

  addNewShoppingLine(shoppingLine){
    this.shoppingList.push(shoppingLine);
  }

  haveOnlyOfferShoppingLine(){
    if(this.shoppingList.length == 0) return false;
    for(var i = 0; i < this.shoppingList.length; i++){
      var localShoppingLine = this.shoppingList[i];
      if(localShoppingLine.price > 0) return false;
    }
    return true;
  }

  isCustomerValid(){
    return this.customer != null && ((typeof this.customer) == 'object');
  }

  deleteShoppingLine(index){
    this.shoppingList.splice(index, 1);
  }

  deletePayments(index){
    this.payments.splice(index, 1);
  }

  getRemainingPayment(){
    var value = this.getTotalTTC() - this.getTotalPayment();
    return this.arround(value);
  }

  getRemainingPaymentPositive(){
    var value = this.arround(this.getTotalTTC() - this.getTotalPayment());
    if(value < 0) value = 0;
    return value;
  }

  getGiveBackPayment(){
    var value = this.getTotalPayment() - this.getTotalTTC();
    return value;
  }


  placeNotNull(){  return this.place != null; }
  cashRegisterNotNull(){  return this.cashRegister != null; }
  userNotNull(){ return this.user != null; }
  clientNotNull(){ return this.customer != null; }

  isEmpty(){
    return !this.clientNotNull() && (this.shoppingList.length == 0);
  }

  ticketIsOKForDeferredPayment(){
    return this.placeNotNull() &&
    this.cashRegisterNotNull() &&
    this.userNotNull() &&
    this.isCustomerValid() &&
    (this.getTotalTTC() != 0 || this.haveOnlyOfferShoppingLine());
  }

  ticketIsOKForPayment(type){
    var isOk = false;
    if(type == 'ticket' || type == null){
      isOk = this.placeNotNull() &&
      this.cashRegisterNotNull() &&
      this.userNotNull() &&
      (this.isCustomerValid() || this.idBooking == null) &&
      (this.getTotalTTC() != 0 || this.haveOnlyOfferShoppingLine());
    }
    else if(type == 'receipt'){
      isOk = this.placeNotNull() &&
       this.cashRegisterNotNull() &&
       this.userNotNull() &&
       this.isCustomerValid();
    }
    return isOk;
  }

  ticketIsOK(){
    var isOk = false;
    if(this.type == 'ticket'){
      isOk = this.placeNotNull() &&
        this.cashRegisterNotNull() &&
        this.userNotNull() &&
        (this.isCustomerValid() || this.idBooking == null) &&
        (this.getTotalTTC() != 0 || this.haveOnlyOfferShoppingLine()) &&
        this.getRemainingPayment() <= 0;
    }
    else if(this.type == 'receipt'){
      isOk = this.placeNotNull() &&
       this.cashRegisterNotNull() &&
       this.userNotNull() &&
       this.isCustomerValid() &&
       this.getTotalPayment() != 0;
    }
    return isOk;
  }

  toTicketLite(){
    var date = this.getDate();
    var idCustomer = null;
    if(this.isCustomerValid()){
      idCustomer = this.customer._id;
    }
    var shoppingList = [];
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      shoppingLine.publicName = shoppingLine.generatePublicName();
      shoppingLine.totalHTPriceWithoutReduction = shoppingLine.getTotalHTPriceWithoutReduction();
      shoppingLine.totalTTCPriceWithoutReduction = shoppingLine.getTotalTTCPriceWithoutReduction();
      shoppingLine.quantity = shoppingLine.getQuantityCalculated();
      shoppingList.push(shoppingLine);
    }
    var idBookings = null;
    for(var i = 0; i < this.idBookings.length; i++){
      var idBooking = this.idBookings[i];
      if(idBookings == null) idBookings = '';
      idBookings += '&' + idBooking + '&';
    }
    return {
      date:date,
      type:this.type,
      idPlace:this.place.id,
      idCashRegister:this.cashRegister._id,
      idUser:this.user._id,
      idCustomer:idCustomer,
      idBooking:this.idBooking,
      idBookings:idBookings,
      idBill:this.idBill,
      shoppingList:shoppingList,
      payments:this.payments,
      reduction:{
        amount:this.reduction.amount,
        type:this.reduction.type
      }
    };
  }
}

/**
 *
 */
export class ShoppingLine{
  public _id:string = null;
  public name:string = '';
  public publicName:string = '';
  public reference:string = '';
  public quantity:number = 1;
  public price:number = 0;
  public priceTTC:number = 0;
  public tva:any = 0;
  public outlay:boolean = false;
  public reduction = {amount:0, type:'currency', checked:false};
  public product = null;
  public thresholded:boolean = false;
  public thresholdType = 0;
  public thresholdPrices = [];
  public freeWord = 'offert';

  /*** Calculated values **/
  public HTPrice:number;
  public TTCPrice:number;
  public totalHTPriceWithoutReduction:number;
  public totalTTCPriceWithoutReduction:number;
  public totalHTPriceWithReduction:number;
  public totalTTCPriceWithReduction:number;

  constructor(product, tva, quantity = 1){
    this._id = product._id;
    this.name = product.name;
    this.publicName = product.publicName;
    this.reference = product.reference;
    this.product = product;
    if(product.thresholded != null) {
      if(typeof product.thresholded == 'string') product.thresholded = (product.thresholded == 'true');
      this.thresholded = product.thresholded;
    }
    if(product.thresholdType != null) this.thresholdType = product.thresholdType;
    if(product.thresholdPrices != null) this.thresholdPrices = product.thresholdPrices;
    this.tva = tva;
    this.quantity = quantity;
    if(product == null || this.thresholded == false || this.thresholdPrices == null || this.thresholdPrices.length == 0) {
      this.price = product.price * 1;
      if(product.priceTTC != null && product.priceTTC > 0 && product.price != 0){
        this.priceTTC = product.priceTTC * 1;
      }
      else if(product.totalHTPriceWithoutReduction) {
        this.priceTTC = product.totalTTCPriceWithoutReduction / product.quantity;
      }
      else {
        this.priceTTC = this.toTTCPrice(product.price);
      }
    }
    else {
      this.price = this.getHTPrice();
      this.priceTTC = this.getTTCPrice();
    }
    if(product.outlay != null) this.outlay = product.outlay;
    if(product.freeWord != null) this.freeWord = product.freeWord;
    if(product.reduction != null){
      this.reduction = product.reduction;
      this.initReductionChecked();
    }
  }

  setReduction(amount, type){
    this.reduction.amount = amount;
    this.reduction.type = type;
    this.initReductionChecked();
  }

  initReductionChecked(){
    if(this.reduction.type == 'currency'){
      this.reduction.checked = false;
    }
    else {
      this.reduction.checked = true;
    }
  }

  swapReductionType(){
    if(!this.reduction.checked) this.reduction.type = 'currency';
    else this.reduction.type = 'purcent';
  }

  addQuantity(number){
    this.quantity += number;
    this.changePriceWithQuantity();
  }

  setQuantity(quantity){
    this.quantity = quantity;
    this.changePriceWithQuantity();
  }

  changePriceWithQuantity(){
    if(this.isSamePriceThreshold(this.price)){
      if(this.thresholded && this.thresholdPrices.length > 1){
        for(var i = 0; i < this.thresholdPrices.length; i++){
          var quantity = Math.abs(this.quantity);
          var thresholdPrice = this.thresholdPrices[i];
          if(thresholdPrice.min <= quantity && quantity <= thresholdPrice.max){
            if(this.thresholdType == 0) this.price = thresholdPrice.price;
            else this.price = thresholdPrice.price * this.quantity;
          }
        }
      }
    }
  }

  isSamePriceThreshold(price){
    if(this.thresholded && this.thresholdPrices.length > 0){
      for(var i = 0; i < this.thresholdPrices.length; i++){
        var thresholdPrice = this.thresholdPrices[i];
        if(thresholdPrice.price == price){
          return true;
        }
      }
    }
    return false;
  }

  isSamePrice(price){
    if(this.isSamePriceThreshold(price)) return true;
    return this.price == price;
  }

  getHTPrice(){
    return this.toHTPrice(this.getTTCPrice());
  }

  getThresholdPrices(quantity){
    if(this.thresholded){
      for(var i = 0; i < this.thresholdPrices.length; i++){
        quantity = Math.abs(quantity);
        var thresholdPrice = this.thresholdPrices[i];
        if(thresholdPrice.min <= quantity && quantity <= thresholdPrice.max){
          return thresholdPrice;
        }
      }
      if(this.thresholdPrices.length > 0){
        return this.thresholdPrices[this.thresholdPrices.length - 1];
      }
    }
    return null;
  }

  getTTCPrice(){
    if(this.thresholded){
      for(var i = 0; i < this.thresholdPrices.length; i++){
        var quantity = Math.abs(this.quantity);
        var thresholdPrice = this.thresholdPrices[i];
        if(thresholdPrice.min <= quantity && quantity <= thresholdPrice.max){
          return thresholdPrice.priceTTC * 1 + thresholdPrice.fixedPriceTTC * 1;
        }
      }
      if(this.thresholdPrices.length > 0){
        var thresholdPrice = this.thresholdPrices[this.thresholdPrices.length - 1];
        return thresholdPrice.priceTTC * 1  + thresholdPrice.fixedPriceTTC * 1;
      }
    }
    return this.priceTTC * 1;
  }

  toHTPrice(price){
    return this.arround(price / (1 + this.tva.value / 100));
  }

  toTTCPrice(price){
    return this.arround(price * (1 + this.tva.value / 100));
  }

  applyReduction(amount){
    if(this.reduction.type == 'currency'){
      amount -= this.reduction.amount;
    }
    else {
      amount -= (amount * (this.reduction.amount / 100));
    }
    return this.arround(amount);
  }

  applyReductionHT(amount){
    if(this.reduction.type == 'currency'){
      var amountReduction = (this.reduction.amount / (1 + (this.tva.value / 100)));
      amount -= this.arround(amountReduction);
    }
    else {
      amount -= (amount * (this.reduction.amount / 100));
    }
    return this.arround(amount);
  }

  getQuantityCalculated(){
    var quantity = this.quantity;
    if(this.thresholded && this.thresholdType == 0 && quantity > 0){ quantity = 1; }
    if(this.thresholded && this.thresholdType == 0 && quantity < 0){ quantity = -1; }
    return quantity;
  }

  getTotalTTCPriceWithoutReduction(){
    var total = 0;
    var thresholdPrice = this.getThresholdPrices(this.quantity);
    if(thresholdPrice == null){
      total = this.getTTCPrice() * this.getQuantityCalculated();
    }
    else {
      var supPersons = Math.max(0, this.quantity - thresholdPrice.max);
      total = (thresholdPrice.priceTTC * Math.abs(this.quantity - supPersons)) * 1 + thresholdPrice.fixedPriceTTC * 1 + thresholdPrice.priceSupPersonTTC * 1 * supPersons;
      if(this.quantity < 0) total = total * -1;
    }
    return this.arround(total);
  }

  getTotalTTCPrice(){
    return this.applyReduction(this.getTotalTTCPriceWithoutReduction());
  }

  getTotalHTPriceWithoutReduction(){
    var total = this.toHTPrice(this.getTotalTTCPriceWithoutReduction());
    return this.arround(total);
  }

  getTotalHTPrice(){
    return this.applyReductionHT(this.getTotalHTPriceWithoutReduction());
  }

  setZeroIfReductionAmountNull(){
    if(this.reduction.amount == null){
      this.reduction.amount = 0;
    }
  }

  setZeroIfPriceNull(){
    if(this.price == null){
      this.price = 0;
    }
  }

  setOneIfQuantityNull(){
    if(this.quantity == null){
      this.quantity = 1;
    }
  }

  generatePublicName(){
    if(!this.thresholded || this.thresholdType == 1) return this.publicName;
    return this.publicName + ' (x' + Math.abs(this.quantity) + ')';
  }

  arround(value){
    return Number((value * 1).toFixed(2));
  }
}

/**
 *
 */
export class Payment{
  public number = 1; //Nombre de fois présent
  public externalId = '';
  public recash = null;

  constructor(public type:string, public amount:number){
    this.type = type;
    this.amount = amount;
  }

  static globalFormatTypePayment(anotherPayments, type:string, amount:number):string{
    var suffix = '';
    if(amount < 0) suffix = 'Remboursement ';
    if(type == 'cash') return suffix + 'Espèce';
    else if(type == 'recash') return 'Rendu';
    else if(type == 'check') return suffix + 'Chèque';
    else if(type == 'card') return suffix + 'Carte bancaire';
    else if(type == 'customer') return suffix + 'Compte client';
    else if(type == 'credit') return 'Avoir';
    else if(type == 'defer') return suffix + 'Différé';
    else if(type == 'systempay') return suffix + 'Systempay';
    else if(type == 'monext') return suffix + 'MONEXT PAYLINE';
    else if(type == 'paypal') return suffix + 'Paypal';
    else if(type == 'monetico') return suffix + 'Monetico';
    else if(type == 'stripe') return suffix + 'Stripe';
    else if(type == 'paybox') return suffix + 'Paybox';
    for(var i = 0; i < anotherPayments.length; i++){
      var anotherPayment = anotherPayments[i];
      if(type == anotherPayment.id){
        return suffix + anotherPayment.name;
      }
    }
    return type;
  }

  static globalFormatTypeCustomerPayment(anotherPayments, type:string, amount:number):string{
    if(type.indexOf('customer') != -1){
      var tokens = type.split('_');
      if(tokens.length >= 2) {
        var param = tokens.slice(1).join('_');
        return Payment.globalFormatTypePayment(anotherPayments, tokens[0], amount) + '(' + Payment.globalFormatTypePayment(anotherPayments, param, 1) + ')';
      }
      return Payment.globalFormatTypePayment(anotherPayments, type, amount);
    }
    return Payment.globalFormatTypePayment(anotherPayments, type, amount);
  }

  formatTypePayment(anotherPayments):string{
    return Payment.globalFormatTypeCustomerPayment(anotherPayments, this.type, this.amount);
  }

  getAmount():number{
    if(this.type == 'recash') return this.amount * -1;
    return this.amount;
  }

  setZeroIfAmountNull():void{
    if(this.amount == null){
      this.amount = 0;
    }
  }

  clone():Payment{
    var payment = new Payment(this.type, this.amount);
    payment.number = this.number;
    payment.externalId = this.externalId;
    return payment;
  }
}

export class TicketLite extends GlobalTicket{
  public _id = '';
  public id = 0;
  public date = null;
  public type = '';
  public settings = {customer:''};
  public idPlace = null;
  public idCashRegister = null;
  public idUser = null;
  public idCustomer = null;
  public idBooking = null;
  public idBill = null;
  public state = '';
  public tvaList = [];
  public reduction = null;
  public idPayments = [];
  public idTickets = [];
  public nbTickets = 0;
  public nbTicketsCancelled = 0;
  public nbSales = 0;
  public nbReceipts = 0;
  public nbReceiptsCancelled = 0;
  public totalHTPriceWithoutReduction = 0;
  public totalTTCPriceWithoutReduction = 0;
  public totalHTPrice = 0;
  public totalTTCPrice = 0;
  public cause = '';
  public movements = [];
  public totalTickets = 0;
  public globalHT = 0;
  public globalTTC = 0;
  public test = 0;

  constructor(json:any){
    super();
    this._id = json._id;
    this.id = json.id;
    this.date = new Date(json.date);
    this.type = json.type;
    this.settings = json.settings;
    this.idPlace = json.idPlace;
    this.idCashRegister = json.idCashRegister;
    this.idUser = json.idUser;
    this.idCustomer = json.idCustomer;
    this.idBooking = json.idBooking;
    this.idBill = json.idBill;
    if(json.reduction != null){
      this.reduction = json.reduction;
    }
    for(var i = 0; i < json.shoppingList.length; i++){
      var jsonShoppingLine = json.shoppingList[i];
      var shoppingLine = new ShoppingLine(jsonShoppingLine, JSON.parse(JSON.stringify(jsonShoppingLine.tva)), jsonShoppingLine.quantity);
      shoppingLine.reduction = jsonShoppingLine.reduction;
      shoppingLine.HTPrice = jsonShoppingLine.HTPrice * 1;
      shoppingLine.TTCPrice = jsonShoppingLine.TTCPrice * 1;
      shoppingLine.totalHTPriceWithoutReduction = jsonShoppingLine.totalHTPriceWithoutReduction * 1;
      shoppingLine.totalTTCPriceWithoutReduction = jsonShoppingLine.totalTTCPriceWithoutReduction * 1;
      shoppingLine.totalHTPriceWithReduction = jsonShoppingLine.totalHTPriceWithReduction * 1;
      shoppingLine.totalTTCPriceWithReduction = jsonShoppingLine.totalTTCPriceWithReduction * 1;
      this.shoppingList.push(shoppingLine);
    }
    for(var i = 0; i < json.payments.length; i++){
      var jsonPayment = json.payments[i];
      var payment = new Payment(jsonPayment.type, jsonPayment.amount);
      payment.number = jsonPayment.number;
      payment.externalId = jsonPayment.externalId;
      this.payments.push(payment);
    }
    this.state = json.state;
    this.reduction = json.reduction;
    this.idPayments = json.idPayments;
    this.idTickets = json.idTickets;
    this.tvaList = json.tvaList;
    this.nbTickets = json.nbTickets * 1;
    this.nbTicketsCancelled = json.nbTicketsCancelled * 1;
    this.nbSales = json.nbSales * 1;
    this.nbReceipts = json.nbReceipts * 1;
    this.nbReceiptsCancelled = json.nbReceiptsCancelled * 1;
    this.totalHTPriceWithoutReduction = json.totalHTPriceWithoutReduction * 1;
    this.totalTTCPriceWithoutReduction = json.totalTTCPriceWithoutReduction * 1;
    this.totalHTPrice = json.totalHTPrice * 1;
    this.totalTTCPrice = json.totalTTCPrice * 1;
    this.cause = json.cause;
    for(var i = 0; i < json.paymentsReceipt.length; i++){
      var jsonPayment = json.paymentsReceipt[i];
      var payment = new Payment(jsonPayment.type, jsonPayment.amount * 1);
      payment.number = jsonPayment.number * 1;
      this.movements.push(payment);
    }
    this.totalTickets = json.totalTickets * 1 + json.totalReceipts * 1;
    this.globalHT = json.globalHT;
    this.globalTTC = json.globalTTC;
    this.test = json.test;
  }

  isOk(){
    return this.state == 'ok' || this.state == 'test';
  }

  isTestTicket(){
    return this.state == 'test';
  }

  isCancelled(){
    return this.state == 'cancelled';
  }

  isOkButCancelled(){
    return this.state == 'ok_cancelled';
  }

  setOkButCancelled(cause){
    this.state = 'ok_cancelled';
    this.cause = cause;
  }

  displayTotalPrice(){
    if(this.isReceipt()) return this.getTotalPayment();
    return this.totalTTCPrice;
  }

  getTotalMovements(){
    var total = 0;
    for(var i = 0; i < this.movements.length; i++){
      var payment = this.movements[i];
      total += payment.amount;
    }
    return this.arround(total);
  }

  getNumberSales(){
    return this.nbSales;
  }

  getMeanBasket(){
    if(this.getNumberSales() == 0) return 0;
    return this.arround(this.totalTTCPrice / this.getNumberSales());
  }

  getPaymentNumberWithCustomerAcount(){
    var payment = this.getPaymentByType('customer');
    if(payment != null) return payment.number;
    return 0;
  }

  getPaymentAmountWithCustomerAcount(){
    var payment = this.getPaymentByType('customer');
    if(payment != null) return payment.amount;
    return 0;
  }

  havePaymentType(type){
    for(var i = 0; i < this.payments.length; i++){
      var payment = this.payments[i];
      if(payment.type == type) return true;
    }
    for(var i = 0; i < this.movements.length; i++){
      var movement = this.movements[i];
      if(movement.type == type) return true;
    }
    return false;
  }

  sumTVA(tva){
    var total = 0;
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      if(shoppingLine.tva._id == tva._id){
        total += shoppingLine.totalTTCPriceWithReduction;
      }
    }
    return total;
  }

  getShoppingLineByIdProduct(idProduct):ShoppingLine{
    for(var i = 0; i < this.shoppingList.length; i++){
      var shoppingLine = this.shoppingList[i];
      if(shoppingLine._id == idProduct){
        return shoppingLine;
      }
    }
    return null;
  }

  fusionRecashAndCash(){
    var recash = this.getPaymentByType('recash');
    if(recash){
      var newPayments = [];
      for(var i = 0; i < this.payments.length; i++){
        var payment = this.payments[i];
        if(payment.type != 'recash'){
          if(payment.type == 'cash'){
            payment.amount = Number(payment.amount) + Number(recash.amount);
            payment.recash = Number(recash.amount);
          }
          newPayments.push(payment);
        }
      }
      this.payments = newPayments;
    }
  }

}
