import {Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

@Component({
  selector: 'booking-dialog',
  templateUrl: 'bookingDialog.html',
  styleUrls:['./bookingDialog.css']
})

export class BookingDialog {

  public bookingsSelected = [];
  public bookings:any[] = [];
  public many:boolean = false;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<BookingDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.data != null){
      for(var i = 0 ; i < this.data.bookings.length; i++){
        var booking = this.data.bookings[i];
        if(booking != null){
          booking.json = JSON.parse(booking.stringified);
          booking.idCreation = booking.json.idCreation;
          this.bookings.push(booking);
        }
      }
      for(var i =0; i < this.bookings.length; i++){
        if(this.bookings[i] != null){
          this.bookings[i] = this.calculateBookingInformations(this.bookings[i]);
        }
      }
    }
  }

  alreadySelected(booking){
    var idBooking = booking.idCreation!=-1?booking.idCreation:booking.idBooking;
    return this.data.idBookings.indexOf(idBooking) != -1;
  }

  alreadyBookingSelected(booking){
    for(var i = 0; i < this.bookingsSelected.length; i++){
      var element = this.bookingsSelected[i];
      if(element.idBooking == booking.idBooking){
        return i;
      }
    }
    return -1;
  }

  calculateBookingInformations(booking){
    var json = JSON.parse(booking.stringified);
    booking.customer = json.customer;

    var idPlaces = [];
    for(var i = 0; i < json.appointments.length; i++){
      var appointment = json.appointments[i];
      if(appointment.idPlace.length > 0 && idPlaces.indexOf(appointment.idPlace) == -1){
        idPlaces.push(appointment.idPlace);
      }
    }

    booking.idPlaces = idPlaces;
    booking.displayPlaces = this.displayPlaces(booking,json.places);
    booking.groupIdBookings = [];
    if(json.groupIdBookings != null){
      for(var i = 0; i < json.groupIdBookings.length; i++){
        var idCreation = json.groupIdBookings[i];
        if(this.getBookingByIdCreation(idCreation) != null){
          booking.groupIdBookings.push(idCreation);
        }
      }
    }
    return booking;
  }

  haveBookingSelected(){ return this.bookingsSelected != null; }

  getBookingByIdCreation(idCreation){
    return this.bookings.find(element => {
      var idBooking = element.idCreation>-1?element.idCreation:element.idBooking;
      if(idBooking == idCreation) return element;
    });
  }

  addSelectBooking(booking){
    if(!this.alreadySelected(booking)){
      if(booking.groupIdBookings.length > 1) this.many = true;
      if(!this.many || (this.bookingsSelected.length > 0 && this.bookingsSelected[0].customer.externalId != booking.customer.externalId)){
        this.bookingsSelected = [booking];
      }
      else {
        var index = this.alreadyBookingSelected(booking);
        if(index == -1){
          this.bookingsSelected.push(booking);
        }
        else {
          this.bookingsSelected.splice(index, 1);
        }
        for(var i = 0; i < booking.groupIdBookings.length; i++){
          var idBooking = booking.groupIdBookings[i];
          var anotherBooking = this.getBookingByIdCreation(idBooking);
          if(anotherBooking!=null && booking.idBooking != anotherBooking.idBooking && !this.alreadySelected(booking)){
            var index = this.alreadyBookingSelected(anotherBooking);
            if(index == -1){
              this.bookingsSelected.push(anotherBooking);
            }
            else {
              this.bookingsSelected.splice(index, 1);
            }
            anotherBooking.groupIdBookings = [];
          }
        }
        booking.groupIdBookings = [];
      }
      if(!this.many){
        this.okDialog();
      }
    }
  }

  displayPlaces(booking, places){
    var display = '';
    if(places != null){
      for(var i = 0; i < places.length; i++){
        var place = places[i];
        if(booking.idPlaces.indexOf(place.id) != -1){
          if(display != '') display += ', ';
          display += place.name['fr_FR'];
        }
      }
    }
    return display;
  }

  okDialog() {
    this.dialogRef.close({bookings:JSON.parse(JSON.stringify(this.bookingsSelected)), action:'load'});
  }

  deleteBookings() {
    if(this.bookingsSelected.length >0){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title: 'Ne plus encaisser ces réservations ?',
          text: 'Voulez-vous vraiment ne plus encaisser ces réservations ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.dialogRef.close({bookings:JSON.parse(JSON.stringify(this.bookingsSelected)), action:'delete'});
        }
      });
    }
  }

  deleteAllBookings(){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '350px',
      data: {
        title: 'Etes-vous sur ?',
        text: 'Voulez-vous vider la liste des réservations en attente ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.dialogRef.close({bookings:[], action:'deleteAll'});
      }
    });
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  displayP
}
