import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './user.service';


@Injectable()
export class NavService{
  constructor( private router: Router, private location: Location){

  }

  changeRoute(route, query = null){
    if(query == null){
      this.router.navigate([route]);
    }
    else {
      this.router.navigate([route], { queryParams: query });
    }
  }

  changeRouteAfterLogin(userService:UserService){
    if(!userService.isConnected()){
      this.toLoginPage();
    }
    else if(userService.isAdmin()){
      this.changeRoute('admin');
    }
    else {
      this.changeRoute('menu');
    }
  }

  getRegisterURL(token){
    return window.location.origin + '/register/' + token;
  }

  goSettingsTva(){
    this.router.navigate(['settings/tva']);
  }

  goSettingsBill(){
    this.router.navigate(['settings/bill']);
  }

  goCustomerDetails(idCustomer){
    this.router.navigate(['customers/' + idCustomer]);
  }

  toLoginPage(){
    this.router.navigate(['/login']);
  }

  goBack(){
    return this.location.back();
  }


}
