import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ErrorsService{

  private errors = {};

  constructor(private http: HttpClient) {
    this.http.get('assets/errors-fr.json').subscribe(data => {
      var array = Object.values(data);
      for(var i = 0; i < array.length; i++){
        var error = array[i];
        this.errors[error.id] = error.text;
      }
    },
    err => {
      console.log('Something went wrong!');
    });
  }

  getErrorText(error){
    var text = error.message;
    if(this.errors[error.type] != null){
      text = this.errors[error.type];
    }
    return text;
  }

  getErrorTextByType(type){
    var text = type;
    if(this.errors[type] != null){
      text = this.errors[type];
    }
    return text;
  }
}
