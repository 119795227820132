import {Input, Output, Component, Inject, EventEmitter } from '@angular/core';
import { TicketLite } from '../../model/ticket'


@Component({
  selector: 'ticket-line',
  templateUrl: 'ticketLine.html',
  styleUrls:['./ticketLine.css']
})

export class TicketLineDisplay {

  @Input() ticket: TicketLite;
  @Input() cancellable: Boolean;
  @Output() viewDetails: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() openBill: EventEmitter<any> = new EventEmitter();


  constructor() {
  }


  viewDetailsTicket(){
    this.viewDetails.emit(this.ticket);
  }

  cancelTicket(){
    if(this.cancellable){
      this.cancel.emit(this.ticket);
    }
  }

  openBillTicket(){
    this.openBill.emit(this.ticket.idBill);
  }
}
