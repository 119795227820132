import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirmDialog.html',
  styleUrls:['./confirmDialog.css']
})


export class ConfirmDialog {

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

}
