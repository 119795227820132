import {Input, Output, Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

@Component({
  selector: 'bookings-selector',
  templateUrl: 'bookingsSelector.html',
  styleUrls:['./bookingsSelector.css']
})

export class BookingsSelectorComponent{

  public bookingsSelected = [];
  public many:boolean = false;
  @Input() bookings: any[] = [];
  @Input() idBookings: any[] = [];
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() load: EventEmitter<any> = new EventEmitter();
  @Output() deleteBookings: EventEmitter<any> = new EventEmitter();
  @Output() deleteAll: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog) {

  }

  alreadySelected(booking){
    var idBooking = booking.idCreation!=-1?booking.idCreation:booking.idBooking;
    return this.idBookings.indexOf(idBooking) != -1;
  }

  alreadyBookingSelected(booking){
    for(var i = 0; i < this.bookingsSelected.length; i++){
      var element = this.bookingsSelected[i];
      if(element.idBooking == booking.idBooking){
        return i;
      }
    }
    return -1;
  }

  haveBookingSelected(){ return this.bookingsSelected != null; }

  getBookingByIdCreation(idCreation){
    return this.bookings.find(element => {
      var idBooking = element.idCreation>-1?element.idCreation:element.idBooking;
      if(idBooking == idCreation) return element;
    });
  }

  addSelectBooking(booking){
    if(!this.alreadySelected(booking)){
      if(booking.groupIdBookings.length > 1) this.many = true;
      if(!this.many || (this.bookingsSelected.length > 0 && this.bookingsSelected[0].customer.externalId != booking.customer.externalId)){
        this.bookingsSelected = [booking];
      }
      else {
        var index = this.alreadyBookingSelected(booking);
        if(index == -1){
          this.bookingsSelected.push(booking);
        }
        else {
          this.bookingsSelected.splice(index, 1);
        }
        for(var i = 0; i < booking.groupIdBookings.length; i++){
          var idBooking = booking.groupIdBookings[i];
          var anotherBooking = this.getBookingByIdCreation(idBooking);
          if(anotherBooking!=null && booking.idBooking != anotherBooking.idBooking && !this.alreadySelected(booking)){
            var index = this.alreadyBookingSelected(anotherBooking);
            if(index == -1){
              this.bookingsSelected.push(anotherBooking);
            }
            else {
              this.bookingsSelected.splice(index, 1);
            }
            anotherBooking.groupIdBookings = [];
          }
        }
        booking.groupIdBookings = [];
      }
      if(!this.many){
        this.loadBookings();
      }
    }
  }

  loadBookings() {
    this.load.emit(JSON.parse(JSON.stringify(this.bookingsSelected)));
  }

  launchDeleteBookings() {
    if(this.bookingsSelected.length >0){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title: 'Ne plus encaisser ces réservations ?',
          text: 'Voulez-vous vraiment ne plus encaisser ces réservations ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.deleteBookings.emit(JSON.parse(JSON.stringify(this.bookingsSelected)));
        }
      });
    }
  }

  launchDeleteAllBookings(){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '350px',
      data: {
        title: 'Etes-vous sur ?',
        text: 'Voulez-vous vider la liste des réservations en attente ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.deleteAll.emit();
      }
    });
  }

  cancelChooseBooking() {
    this.cancel.emit();
  }

}
