import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'page-register',
  templateUrl: 'register.html',
  styleUrls:['./register.css']
})

export class RegisterComponent implements OnInit {
  public maintenance:any = null;
  private token:string = '';
  private pseudo:string = '';
  private email:string = '';
  private name:string = '';
  private url:string = '';

  public error:boolean = false;

  constructor(public userService: UserService, public navService:NavService,
  private route: ActivatedRoute, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    if(this.token){
      this.userService.getLicenseByToken(this.token, function(license){
        console.log(license);
        this.email = license.email;
      }.bind(this), function(error){
        this.error = true;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
    else {
      this.error = true;
    }
    this.userService.getWithoutConnection('api/v1/maintenance/', (maintenance) => {
      this.maintenance = maintenance;
      console.log(maintenance);
    }, (error) => {
      let snackBarRef = this.snackBar.open(error.message, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    });
  }

  isValidEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValidURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  canRegister(){
    return this.token!=null && this.pseudo.length > 0 && this.isValidEmail(this.email) && this.name.length > 0 && this.isValidURL(this.url);
  }

  register(){
    if(this.canRegister()){
      this.userService.register(this.token, this.pseudo, this.email, this.name, this.url, this.successCallback.bind(this), this.errorCallback.bind(this));
    }
  }

  successCallback(data){
    this.navService.changeRouteAfterLogin(this.userService);
  }

  errorCallback(error){
    let snackBarRef = this.snackBar.open(error, 'OK', {
      duration: 8000,
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }
}
