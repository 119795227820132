import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog, MatSort, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';
import { CustomerDialog } from '../../components/customerDialog/customerDialog';
import { TicketDialog } from '../../components/ticketDialog/ticketDialog';
import { BillDialog } from '../../components/billDialog/billDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { TicketLite } from '../../model/ticket'

@Component({
  selector: 'page-customer',
  templateUrl: 'customer.html',
  styleUrls:['./customer.css']
})

export class CustomersComponent implements OnInit {

  public loadCustomersLaunched:boolean = false;
  public loadCustomerLaunched:boolean = false;
  public generateBillWithTicketLaunched:boolean = false;
  public addEditCustomerLaunched:boolean = false;
  public generateAllBalancesCustomersLaunched:boolean = false;

  private currentCustomer = null;
  private currentCustomerTickets:TicketLite[] = [];
  private currentCustomerBills = [];
  private customers = [];
  private totalCustomers = 0;
  private currentPageIndex = 1;
  private currentPageSize = 10;
  private searchCustomer = '';
  private formOrder = {order:'lastName', asc:true};
  private subPage = 'tickets';
  private searchTimer = null;

  @ViewChild('paginator', { static: false }) paginator;

  constructor(public userService: UserService, public navService:NavService, public dialog: MatDialog, private route: ActivatedRoute, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      this.userService.initializeData(function(){
        this.loadCustomers();
        var idCustomer = this.route.snapshot.paramMap.get('idCustomer');
        if(idCustomer){
          this.getCustomer(idCustomer);
        }
      }.bind(this), function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  getLicense(){
    return this.userService.getLicense();
  }

 isTestMode():boolean{
    return this.userService.isTestMode();
  }


 
  loadCustomers(){
    if(!this.loadCustomersLaunched){
      this.loadCustomersLaunched = true;
      this.userService.getCustomersByPage(this.formOrder.order, this.formOrder.asc, this.currentPageIndex - 1, this.currentPageSize, function(data){
        this.customers = data.customers;
        this.totalCustomers = data.total;
        this.loadCustomersLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  getCustomersSearch(){
    clearTimeout(this.searchTimer);
    if(this.searchCustomer.length >= 3){
      this.searchTimer = setTimeout(function(){
        this.loadCustomersLaunched = true;
        this.userService.getCustomersSearch(this.searchCustomer, function(customers){
          this.customers = customers;
          this.currentPageIndex = 1;
          this.loadCustomersLaunched = false;
        }.bind(this), function(error){

        });
      }.bind(this), 500);
    }
    else if(this.searchCustomer.length == 0){
      this.loadCustomers();
    }
  }

  generateAllBalancesCustomers(){
    if(!this.generateAllBalancesCustomersLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: { title:'Regénérer les balances', text: 'Voulez-vous regénérer les balances de tous les clients (cette action peut prendre quelques minutes) ? <i><br />Cette fonctionnalité est utile "une seule fois" pour les licenses créées avant le 1 novembre 2018</i>'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.generateAllBalancesCustomersLaunched = true;
          this.userService.generateAllBalancesCustomers(function(result){
            this.generateAllBalancesCustomersLaunched = false;
            let snackBarRef = this.snackBar.open('Fini !', 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this), function(error){
            this.generateAllBalancesCustomersLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }

  goCashRegister(){
    this.navService.changeRoute('cash');
  }

  goRESAOnlineCustomer(customer){
    var license = this.userService.getLicense();
    if(customer==null || customer.externalId < 0 || license.url == null || license.url.length == 0) return '';
    return license.url + '/wp-admin/admin.php?page=resa_appointments&view=display&subPage=clients&id=' + customer.externalId;
  }

  getPseudo():String{
    var pseudo:String = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicenseName():String{
    var name:String = '';
    if(this.userService.haveLicense()){
      name = this.userService.getLicense().name;
    }
    return name;
  }

  isViewCurrentCustomer(){ return this.currentCustomer != null; }

  getColumnsCategoriesUsersTable(){
    return ['lastName', 'firstName', 'credits', 'synchronization', 'modify', 'view'];
  }

  addCustomer(){
    let dialogRef = this.dialog.open(CustomerDialog, {
      width: '80vw',
      height: '90%',
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && !this.addEditCustomerLaunched){
        this.addEditCustomerLaunched = true;
        this.userService.addCustomer(result, function(result){
          this.addEditCustomerLaunched = false;
          this.currentCustomer = result;
        }.bind(this), function(error){
          this.addEditCustomerLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    });
  }

  editCustomer(customer){
    let dialogRef = this.dialog.open(CustomerDialog, {
      width: '80vw',
      height: '90%',
      data: customer
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && !this.addEditCustomerLaunched){
        result._id = customer._id;
        this.addEditCustomerLaunched = true;
        this.userService.editCustomer(result, function(result){
          this.addEditCustomerLaunched = false;
          this.currentCustomer = result;
        }.bind(this), function(error){
          this.addEditCustomerLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    });
  }

  pushCustomer(idCustomer){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Re/synchroniser ?', text: 'Voulez-vous synchroniser ou resynchroniser ce client ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.userService.pushCustomer(idCustomer, function(result){
          if(result._id == idCustomer){
            let snackBarRef = this.snackBar.open('Synchronisation ok !', 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }
        }.bind(this), function(error){
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    });
  }

  clearCurrentCustomer(){
    this.currentCustomer = null;
    this.currentCustomerTickets = [];
    this.currentCustomerBills = [];
  }

  getCustomer(idCustomer){
    if(!this.loadCustomerLaunched){
      this.loadCustomerLaunched = true;
      this.userService.getCustomer(idCustomer, function(data){
        this.currentCustomer = data;
        this.currentCustomerTickets = [];
        for(var i = 0; i < data.tickets.length; i++){
          var ticket = new TicketLite(data.tickets[i]);
          this.currentCustomerTickets.push(ticket);
        }
        this.currentCustomerBills = data.bills;
        this.loadCustomerLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.loadCustomerLaunched = false;
      }.bind(this));
    }
  }


  getPaymentsTypes(idCashRegister){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(idCashRegister != null && idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }

  getPaymentsTypesByIdCashRegister(idCashRegister){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }

  isDisplayTickets(){ return this.subPage == 'tickets'; }
  isDisplayBills(){ return this.subPage == 'bills'; }

  displayTickets(){ this.subPage = 'tickets'; }
  displayBills(){ this.subPage = 'bills'; }


  openTicket(ticket){
    if(ticket){
      var paymentsType = this.getPaymentsTypesByIdCashRegister(ticket.idCashRegister);
      var anotherPayments = []
      if(paymentsType != null){
        anotherPayments = paymentsType.anotherPayments;
      }
      let dialogRef = this.dialog.open(TicketDialog, {
        width: '80vw',
        height: '90%',
        data: {
          ticket:ticket,
          anotherPayments:anotherPayments
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'openBill'){
          this.openBillBydIdBill(ticket.idBill);
        }
        else if(result == 'generateBill'){
          this.generateBillWithTicket(ticket);
        }
      });
    }
  }

  goCash(bill){
    if(bill != null){
      this.navService.changeRoute('cash', {bill:bill._id});
    }
  }

  getCashRegisters(){
    var cashRegisters = [];
    if(this.userService.haveLicense()){
      for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
        var cashRegister = this.getLicense().modelCashRegisters[i];
        cashRegisters.push(cashRegister);
      }
    }
    return cashRegisters;
  }

  getBillByIdBill(idBill){
    for(var i = 0; i < this.currentCustomerBills.length; i++){
      var bill = this.currentCustomerBills[i];
      if(bill._id == idBill){
        return bill;
      }
    }
    return bill;
  }

  calculateAllAnotherPayments(){
    var alreadyIds = [];
    var allAnotherPayments = [];
    var cashRegisters = this.getCashRegisters();
    for(var i = 0; i < cashRegisters.length; i++){
      var cashRegister = cashRegisters[i];
      var paymentsType = this.getPaymentsTypesByIdCashRegister(cashRegister._id);
      var anotherPayments = [];
      if(paymentsType != null){
        anotherPayments = paymentsType.anotherPayments;
      }
      for(var j = 0; j < anotherPayments.length; j++){
        var anotherPayment = anotherPayments[j];
        if(alreadyIds.indexOf(anotherPayment.id) == -1){
          allAnotherPayments.push(anotherPayment);
          alreadyIds.push(anotherPayment.id);
        }
      }
    }
    return allAnotherPayments;
  }

  openBillBydIdBill(idBill){
    this.openBill(this.getBillByIdBill(idBill));
  }

  openBill(bill){
    if(bill){
      var anotherPayments = this.calculateAllAnotherPayments();
      let dialogRef = this.dialog.open(BillDialog, {
        width: '80vw',
        height: '90%',
        data: {
          bill:bill,
          anotherPayments:anotherPayments,
          displayCancelButton:this.canCancellableOnlyByBill(bill)
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'payBill'){
          this.goCash(bill);
        }
        else if(result == 'cancelBill'){
          this.goCancel(bill);
        }
        else if(result.action != null && result.action == 'sendBill'){
          this.sendBill(bill, result.pdf);
        }
      });
    }
  }

  canCancellableOnlyByBill(bill){
    var cashRegisters = this.userService.getCashRegisterById(bill.ticket.idCashRegister);
    return new Date(bill.ticket.date) <= new Date(cashRegisters.closeDate);
  }

  goCancel(bill){
    if(bill != null){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Annuler la facture n°' + bill.id + ' ?',
          text: 'Voulez-vous vraiment annuler la facture n°' + bill.id + ' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
            this.navService.changeRoute('cash', {bill:bill._id, type:'cancel'});
          }
      });
    }
  }

  generateBillWithTicket(ticket){
    if(!this.generateBillWithTicketLaunched && ticket != null){
      this.generateBillWithTicketLaunched = true;
      this.userService.generateBillWithTicket(ticket._id, function(bill){
        this.generateBillWithTicketLaunched = false;
        ticket.idBill = bill._id;
        this.currentCustomerBills.push(bill);
        this.openBill(bill);
      }.bind(this), function(error){
        this.generateBillWithTicketLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));

    }
  }

  sendBill(bill, pdf){
    if(bill != null && !this.generateBillWithTicketLaunched){
      this.generateBillWithTicketLaunched = true;
      this.userService.sendBillByEmail(bill._id, pdf, function(data){
        let snackBarRef = this.snackBar.open('Envoyé', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.generateBillWithTicketLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.generateBillWithTicketLaunched = false;
      }.bind(this));
    }
  }


}
