import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'customer-dialog',
  templateUrl: 'customerDialog.html',
  styleUrls:['./customerDialog.css']
})


export class CustomerDialog {

  public externalId = -1;
  public form = {lastName:'',firstName:'', email:'', company:'', phone:'', address:'', postalCode:'', town:'', country:'', privateNotes:'', synchronizeRESA:true}
  constructor(public dialogRef: MatDialogRef<CustomerDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.data != null){
      this.externalId = this.data.externalId;
      this.form = JSON.parse(JSON.stringify(this.data));
      this.form.synchronizeRESA = true;
    }
  }

  isOkCustomer(){
    return this.form.lastName != null &&
            this.form.firstName != null &&
            this.form.lastName.length > 0 &&
            this.form.firstName.length > 0;
  }

  okDialog() {
    if(this.isOkCustomer()){
      this.dialogRef.close(JSON.parse(JSON.stringify(this.form)));
    }
  }

  cancelDialog() {
    this.dialogRef.close();
  }
}
