import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { DatePipe,DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatRadioModule, MatInputModule,
        MatDialogModule, MatTableModule, MatSortModule, MatSelectModule,
        MatGridListModule, MatToolbarModule, MatIconModule,
        MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
        MatFormFieldModule, MatSlideToggleModule, MatPaginatorModule, MatTooltipModule,MatSnackBarModule,MatMenuModule } from '@angular/material';
import {MatTabsModule} from '@angular/material/tabs';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from './datepicker-i18n';
import { ChartsModule } from 'ng2-charts';

import { HtmlSpecialDecodePipe, FormatNewLineHtml } from '../pipes/customPipes';

import { AppComponent } from './app.component';
import { AlertDialog } from '../components/alertDialog/alertDialog';
import { TicketDialog } from '../components/ticketDialog/ticketDialog';
import { BillDialog } from '../components/billDialog/billDialog';
import { BookingDialog } from '../components/bookingDialog/bookingDialog';
import { ConfirmDialog } from '../components/confirmDialog/confirmDialog';
import { CustomerDialog } from '../components/customerDialog/customerDialog';
import { CategoriesGrid } from '../components/categoriesGrid/categoriesGrid';
import { PromptDialog } from '../components/promptDialog/promptDialog';
import { TicketDisplay } from '../components/ticket/ticket';
import { TicketLineDisplay } from '../components/ticketLine/ticketLine';
import { FloatTabDialog } from '../components/floatTabDialog/floatTabDialog';
import { BillDisplay } from '../components/bill/bill';
import { PadDialog } from '../components/padDialog/padDialog';

import { BookingsSelectorComponent } from '../components/bookingsSelector/bookingsSelector';
import { PadComponent } from '../components/pad/pad';
import { LoaderComponent } from '../components/loader/loader';
import { LoginComponent } from '../pages/login/login';
import { RegisterComponent } from '../pages/register/register';
import { RegisterTestComponent } from '../pages/registerTest/registerTest';
import { AdminComponent } from '../pages/admin/admin';
import { MenuComponent } from '../pages/menu/menu';
import { CustomersComponent } from '../pages/customer/customer';
import { BillComponent } from '../pages/bill/bill';
import { CashComponent } from '../pages/cash/cash';
import { ProductComponent } from '../pages/product/product';
import { RapportsComponent } from '../pages/rapports/rapports';
import { SettingsComponent } from '../pages/settings/settings';
import { StatsComponent } from '../pages/stats/stats';
import { ExportComponent } from '../pages/export/export';
import { ChangelogComponent } from '../pages/changelog/changelog';

import { UserService } from '../services/user.service';
import { NavService } from '../services/nav.service';
import { AdminService } from '../services/admin.service';
import { LicenseService } from '../services/license.service';
import { ErrorsService } from '../services/errors.service';
import { StorageService } from '../services/storage.service';

import { AppRoutingModule } from './app.routing';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RegisterTestComponent,
    AdminComponent,
    MenuComponent,
    CustomersComponent,
    BillComponent,
    CashComponent,
    ProductComponent,
    RapportsComponent,
    SettingsComponent,
    StatsComponent,
    ExportComponent,
    ChangelogComponent,
    BookingsSelectorComponent,
    AlertDialog,
    TicketDialog,
    BillDialog,
    BookingDialog,
    ConfirmDialog,
    CustomerDialog,
    CategoriesGrid,
    PromptDialog,
    TicketDisplay,
    BillDisplay,
    PadDialog,
    TicketLineDisplay,
    FloatTabDialog,
    PadComponent,
    LoaderComponent,
    HtmlSpecialDecodePipe,
    FormatNewLineHtml
  ],
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatPaginatorModule,
	  MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    ChartsModule,
	   MatTabsModule
  ],
  providers: [
    UserService,
    NavService,
    AdminService,
    LicenseService,
    ErrorsService,
    StorageService,
    DatePipe,
    DecimalPipe,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    AlertDialog,
    ConfirmDialog,
    CustomerDialog,
    BookingDialog,
    PromptDialog,
    TicketDialog,
    BillDialog,
    FloatTabDialog,
    PadDialog
  ],
})
export class AppModule { }
