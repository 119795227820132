import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'prompt-dialog',
  templateUrl: 'promptDialog.html',
  styleUrls:['./promptDialog.css']
})


export class PromptDialog {

  public input:string = '';
  public optional:boolean = false;
  public default:string = '';

  constructor(public dialogRef: MatDialogRef<PromptDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data.optional != null){
      this.optional = data.optional;
    }
    if(data.default != null){
      this.input = data.default;
    }
  }

  cancelDialog(){
    this.dialogRef.close();
  }

  okDialog(){
    this.dialogRef.close({note:this.input});
  }

}
