import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'htmlSpecialDecode'})
export class HtmlSpecialDecodePipe implements PipeTransform {
  transform(value: string, withoutBr: boolean = false): string {
    if(value != null){
      value = value.replace(new RegExp('&#039;', 'g'),'\'');
      value = value.replace(new RegExp('&quot;', 'g'),'"');
      value = value.replace(new RegExp('&lt;', 'g'),'<');
      value = value.replace(new RegExp('&gt;', 'g'),'>');
      value = value.replace(new RegExp('&amp;', 'g'),'&');
      value = value.replace(new RegExp('\\\\', 'g'),'\\');
      if(withoutBr == null || !withoutBr){
        value = value.replace(new RegExp('<br />', 'g'), '\n');
      }
    }
    return value;
  }
}


@Pipe({name: 'formatNewLineHtml'})
export class FormatNewLineHtml implements PipeTransform {
  transform(value: string): string {
    if(value != null){
      value = value.replace(/\n/g, '<br \>');
    }
    return value;
  }
}
