import {Input, Output, Component, Inject, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { MatDialog } from '@angular/material';


@Component({
  selector: 'categories-grid',
  templateUrl: 'categoriesGrid.html',
  styleUrls:['./categoriesGrid.css']
})

export class CategoriesGrid {

  private currentLineCategory = null;
  private currentLineGroup = null;

  @Input() viewSettings:boolean = false;
  @Input() settings: any;
  @Input() categories: any[];
  @Input() products: any[];
  @Input() groups: any[];
  @Input() tvaList: any[];
  @Input() edition:boolean = false;
  @Output() clickOnProductEmitter: EventEmitter<any> = new EventEmitter();
  @Output() clickOnGroupEmitter: EventEmitter<any> = new EventEmitter();
  @Output() clickOnSettings: EventEmitter<any> = new EventEmitter();
  @Output() changed: EventEmitter<any> = new EventEmitter();

  private oldPos = 0;
  private newPos = 0;
  private reorganizeTimeoutOrder = null;
  private reorganizeTimeoutCategories = null;
  private reorganizeTimeoutProducts = null;
  private reorganizeTimeoutGroups = null;

  constructor(public dialog: MatDialog) {

  }

  isEditionMode(){
    return this.edition;
  }

  getCategoryById(idCategory){
    if(idCategory != null){
      for(var i = 0; i < this.categories.length; i++){
        if(this.categories[i]._id == idCategory){
          return this.categories[i];
        }
      }
    }
    return null;
  }

  getProductById(idProduct){
    if(idProduct != null){
      for(var i = 0; i < this.products.length; i++){
        if(this.products[i]._id == idProduct){
          return this.products[i];
        }
      }
    }
    return null;
  }

  getTvaById(idTva){
    if(idTva != null){
      for(var i = 0; i < this.tvaList.length; i++){
        if(this.tvaList[i]._id == idTva){
          return this.tvaList[i];
        }
      }
    }
    return null;
  }

  getGroupById(idGroup){
    if(idGroup != null){
      for(var i = 0; i < this.groups.length; i++){
        if(this.groups[i]._id == idGroup){
          return this.groups[i];
        }
      }
    }
    return null;
  }

  getColorGroup(idGroup){
    var group = this.getGroupById(idGroup);
    if(group && group.color) return group.color;
    else return '';
  }

  getCategoriesPath(idCategory){
    var categories = [];
    if(idCategory != null){
      var category = this.getCategoryById(idCategory);
      categories.push(category);
      if(category.parent != null){
        categories = this.getCategoriesPath(category.parent).concat(categories);
      }
    }
    return categories;
  }

  getOrder(){
    if(this.currentLineCategory == null){
      if(this.settings == null) return null;
      return this.settings.order;
    }
    return this.currentLineCategory.order;
  }

  getCategories(){
    var categories = [];
    if(this.currentLineCategory == null){
      categories = this.settings.categories;
    }
    else {
      categories = this.currentLineCategory.categories;
    }
    if(this.isEditionMode()) return categories;
    return this.filterVisible(categories);
  }

  getProducts(){
    var products = [];
    if(this.currentLineCategory == null){
      products = this.settings.products;
    }
    else {
      products = this.currentLineCategory.products;
    }
    if(this.isEditionMode()) return products;
    return this.filterVisible(products);
  }

  getGroups(){
    var groups = [];
    if(this.currentLineCategory == null){
      groups = this.settings.groups;
    }
    else {
      groups = this.currentLineCategory.groups;
    }
    if(this.isEditionMode()) return groups;
    return this.filterVisible(groups);
  }

  filterVisible(array){
    var result = [];
    if(array != null){
      for(var i = 0; i < array.length; i++){
        var element = array[i];
        if(element.visible){
          result.push(element);
        }
      }
    }
    return result;
  }

  setCurrentLineCategory(line){
    this.currentLineCategory = line;
  }

  setCurrentLineGroup(line){
    this.currentLineGroup = line;
  }

  getLineCategoryById(idCategory, arrayLines){
    for(var i = 0; i < arrayLines.length; i++){
      var line = arrayLines[i];
      if(line.category == idCategory) return line;
      else {
        var resultLine = this.getLineCategoryById(idCategory, line.categories);
        if(resultLine != null) {
          return resultLine;
        }
      }
    }
    return null;
  }

  removeCategoryByIndex(index){
    var category = this.getCategoryById(this.getCategories()[index]);
    var categoryName =  '';
    if(category){
      categoryName = category.name;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous retirer la catégory ' + categoryName + ' de l\'affichage ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getCategories().splice(index, 1);
      }
    });
  }

  removeProductByIndex(index){
    var product = this.getProductById(this.getProducts()[index]);
    var productName =  '';
    if(product){
      productName = product.name;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous retirer le produit ' + productName + ' de l\'affichage ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getProducts().splice(index, 1);
      }
    });
  }

  removeGroupByIndex(index){
    var group = this.getProductById(this.getGroups()[index]);
    var groupName =  '';
    if(group){
      groupName = group.name;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous retirer le groupe ' + groupName + ' de l\'affichage ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getGroups().splice(index, 1);
      }
    });
  }

  launchTimeoutReorganizeCategories(oldPos, newPos){
    if(this.reorganizeTimeoutCategories){
      clearTimeout(this.reorganizeTimeoutCategories);
    }
    this.oldPos = oldPos;
    this.newPos = newPos;
    this.reorganizeTimeoutCategories = setTimeout(function(){ this.reorganizeCategories() }.bind(this), 1000);
  }

  reorganizeCategories(){
    var categories = this.getCategories();
    this.switchTwoPos(categories, this.oldPos, this.newPos);
    for(var i = 0; i < categories.length; i++){
      categories[i].pos = i + 1;
    }
  }

  launchTimeoutReorganizeProducts(oldPos, newPos){
    if(this.reorganizeTimeoutProducts){
      clearTimeout(this.reorganizeTimeoutProducts);
    }
    this.oldPos = oldPos;
    this.newPos = newPos;
    this.reorganizeTimeoutProducts = setTimeout(function(){ this.reorganizeProducts() }.bind(this), 1000);
  }

  reorganizeProducts(){
    var products = this.getProducts();
    this.switchTwoPos(products, this.oldPos, this.newPos);
    for(var i = 0; i < products.length; i++){
      products[i].pos = i + 1;
    }
  }

  launchTimeoutReorganizeGroups(oldPos, newPos){
    if(this.reorganizeTimeoutGroups){
      clearTimeout(this.reorganizeTimeoutGroups);
    }
    this.oldPos = oldPos;
    this.newPos = newPos;
    this.reorganizeTimeoutGroups = setTimeout(function(){ this.reorganizeGroups() }.bind(this), 1000);
  }

  reorganizeGroups(){
    var groups = this.getGroups();
    this.switchTwoPos(groups, this.oldPos, this.newPos);
    for(var i = 0; i < groups.length; i++){
      groups[i].pos = i + 1;
    }
  }

  switchTwoPos(array, oldPos, newPos){
    if(newPos >= 0 && newPos < array.length){
      array.splice(newPos, 0, array.splice(oldPos, 1)[0]);
    }
  }

  launchTimeoutReorganizeOrder(){
    if(this.reorganizeTimeoutOrder){
      clearTimeout(this.reorganizeTimeoutOrder);
    }
    this.reorganizeTimeoutOrder = setTimeout(function(){ this.reorganizeOrder() }.bind(this), 1000);
  }

  reorganizeOrder(){
    var order = this.getOrder();
    order.categories.pos = order.categories.newPos;
    order.products.pos = order.products.newPos;
    order.groups.pos = order.groups.newPos;
  }

  clickOnProduct(product){
    this.clickOnProductEmitter.emit(product);
  }

  clickOnGroup(group){
    this.clickOnGroupEmitter.emit(group);
  }

  clickOnSettingsIcon(){
    this.clickOnSettings.emit();
  }

  change(){
    this.changed.emit(true);
  }

  getHtPriceProduct(product){
    if(!product.thresholded || product.thresholdPrices.length == 0) return product.price;
    else {
      return product.thresholdPrices[0].price + product.thresholdPrices[0].fixedPrice;
    }
  }

  getTTCPriceProduct(product){
    if(!product.thresholded || product.thresholdPrices.length == 0) return product.priceTTC;
    else {
      return product.thresholdPrices[0].priceTTC  + product.thresholdPrices[0].fixedPriceTTC;
    }
  }

  calculateTTCPriceGroup(group){
    var total = 0;
    for(var i = 0; i < group.products.length; i++){
      var product = this.getProductById(group.products[i]);
      total += this.getTTCPriceProduct(product);
    }
    return total;
  }

}
