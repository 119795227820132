import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { server } from '../app/global';

import { Ticket } from '../model/ticket';

import { ErrorsService } from './errors.service';
import { StorageService } from './storage.service';

@Injectable()
export class UserService extends StorageService{
  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8'
  });
  private user;
  private license;
  private products:any[] = [];
  private bookings:any[] = [];
  public lastTimeoutBooking = null;
  public setIntervalProducts = null;

  constructor(private http: HttpClient, private errorsService:ErrorsService) {
    super();
    this.initializeLoad();
  }

  initializeLoad(){
    var object = this.load();
    if(object && object.user && new Date(object.user.tokenValidity) > new Date()){
      this.user = object.user;
    }
  }

  saveUserInStorage(){
    this.saveData('user', this.user);
  }

  /**
   * save tickets in storage
   */
  saveTicketsInStorage(tickets){
    this.saveData('tickets', tickets);
  }

  /**
   *
   */
  loadTicketsInStorage(){
    var object = this.load();
    var tickets = [];
    if(object && object.tickets != null){
      tickets = object.tickets;
    }
    return tickets;
  }

  /**
   * return true if is connected
   */
  isConnected():boolean{
    return this.user != null;
  }

  /**
   * return true if the user is admin
   */
  isAdmin():boolean{
    return this.user != null && this.user.admin;
  }

  /**
   * return true if the user is manager
   */
  isManager():boolean{
    return this.user != null && this.user.role!=null && this.user.role.indexOf('manager') != -1;
  }

  /**
   * return true if the user is vendor
   */
  isVendor():boolean{
    return this.user != null && this.user.role!=null && this.user.role.indexOf('vendor') != -1;
  }

  /**
   * return true if sublicense is test mode
   */
  isTestMode():boolean{
    return this.license != null && this.license.mode == 'test';
  }

  /**
   * return true if have a license
   */
  haveLicense():boolean{
    return this.license != null;
  }

  /**
   * return the token
   */
  getToken():boolean{
    return this.user.token
  }

  /**
   * return the user
   */
  getUser(){
    return this.user;
  }

  /**
   * return the user
   */
  getLicense(){
    return this.license;
  }

  /**
   * set license the license
   */
  setLicense(license){
    this.license = license;
  }

  /**
   * return the products
   */
  getProducts(){
    return this.products;
  }

  getCashRegisterById(idCashRegisters){
    var cashRegisters = [];
    if(this.haveLicense()){
      for(var i = 0; i < this.license.modelCashRegisters.length; i++){
        var cashRegister = this.license.modelCashRegisters[i];
        if(cashRegister._id == idCashRegisters){
          return cashRegister;
        }
      }
    }
    return null;
  }

  /**
   * clear license
   */
  clearLicense(){
    this.license = null;
  }

  /**
  * authentification to generate a new token
  */
  authentification(pseudo:string, password:string, idLicense:string, successCallback, errorCallback){
    if(pseudo != null && password != null) {
      var data = {
        pseudo: pseudo,
        password: password,
        idLicense:idLicense
      }
      this.http.post(server + 'api/v1/user/connection', data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.user = data;
        this.saveUserInStorage();
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
  }

  /**
  * register new license
  */
  register(token:string, pseudo:string, email:string, name:string, url:string, successCallback, errorCallback){
    if(token != null && pseudo != null && email != null && name != null && url != null) {
      var data = {
        pseudo: pseudo,
        email: email,
        name: name,
        url:url
      }
      this.http.post(server + 'api/v1/license/register/' + token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.user = data;
        this.saveUserInStorage();
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
  }

  /**
  * register new license
  */
  registerNewLicense(pseudo:string, email:string, name:string, url:string, successCallback, errorCallback){
    if(pseudo != null && email != null && name != null && url != null) {
      var data = {
        pseudo: pseudo,
        email: email,
        name: name,
        url:url
      }
      this.http.put(server + 'api/v1/license/createLicense/', data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.user = data;
        this.saveUserInStorage();
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
  }

  /**
  * disconnect user
  */
  disconnect(successCallback, errorCallback){
    if(this.isConnected()) {
      var idLicense = null;
      if(this.haveLicense() && !this.isAdmin()) idLicense = this.license.idLicense;
      var data = {
        token: this.user.token,
        idLicense: idLicense
      }
      this.http.post(server + 'api/v1/user/disconnection', data, {
        headers: this.headers
      }).subscribe(data => {
        this.user = null;
        this.saveUserInStorage();
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
   * initialize data
   */
  initializeData(successCallback, errorCallback){
    if(this.license == null){
      this.getLicenseServer(this.getUser().idLicense, function(){
        //this.getCustomersServer(function(customers){
          this.getProductsServer(successCallback, errorCallback);
        //}.bind(this), errorCallback);
      }.bind(this), errorCallback);
    }
    else {
      successCallback();
    }
  }

  /**
  * authentification to generate a new token
  */
  getLicenseServer(idLicense, successCallback, errorCallback){
    if(this.isConnected()) {
      this.http.get(server + 'api/v1/license/' + this.user.token + '/' + idLicense)
      .subscribe(data => {
        this.license = data;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get license by token
  */
  getLicenseByToken(token, successCallback, errorCallback){
    this.http.get(server + 'api/v1/license/generate/' + token)
    .subscribe(data => {
      if(successCallback != null){
        successCallback(data);
      }
    }, error => {
      if(errorCallback != null && error.status != 0){
        errorCallback(this.errorsService.getErrorText(error.error));
      }
      else {
        errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
      }
    });
  }

  /**
  * get the customers by id license
  */
  getCustomersServer(successCallback, errorCallback){
    if(this.isConnected()) {
      this.http.get(server + 'api/v1/customers/' + this.user.token + '/' + this.user.idLicense)
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * search the customers by id license
  */
  getCustomersSearch(search, successCallback, errorCallback){
    if(this.isConnected() && search != null && search.length >= 3) {
      var data = {
        idLicense: this.user.idLicense,
        search:search
      }
      this.http.post(server + 'api/v1/customers/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get the customers by page
  */
  getCustomersByPage(order, asc, page, limit, successCallback, errorCallback){
    if(this.isConnected() && page!=null && limit != null) {
      var data = {
        idLicense: this.user.idLicense,
        page:page,
        limit:limit,
        order:order,
        asc:asc?'1':'-1'
      }
      this.http.post(server + 'api/v1/customersByPage/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get the bills by page
  */
  getBillsByPage(page, limit, filters, successCallback, errorCallback){
    if(this.isConnected() && page!=null && limit != null && filters!= null) {
      var data = {
        filters: filters
      }
      this.http.post(server + 'api/v1/bills/' + this.user.token + '/' + this.user.idLicense+ '/' + page + '/' + limit, data)
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get the products by id license
  */
  getProductsServer(successCallback, errorCallback){
    if(this.isConnected()) {
      this.http.get(server + 'api/v1/products/' + this.user.token + '/' + this.user.idLicense)
      .subscribe((data:any) => {
        this.products = data;
        if(this.setIntervalProducts == null){
          this.setIntervalProducts = setInterval(() => { this.getLastProductsServer() }, 7500);
        }
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get the products by id license
  */
  getLastProductsServer(){
    if(this.isConnected() && this.products.length > 0) {
      var lastModificationDate = null;
      for(var i = 0; i < this.products.length; i++){
        var product = this.products[i];
        var date = new Date(product.modificationDate);
        if(lastModificationDate == null || lastModificationDate.getTime() < date.getTime()){
          lastModificationDate = new Date(date);
        }
      }
      lastModificationDate.setSeconds(lastModificationDate.getSeconds() + 1);
      var data = {
        idLicense: this.user.idLicense,
        date:lastModificationDate
      }
      this.http.post(server + 'api/v1/lastProducts/' + this.user.token, data, {
        headers: this.headers
      }).subscribe((data:any) => {
        for(var i = 0; i < data.length; i++){
          this.updateArrayById(this.products, data[i]);
        }
      }, error => {

      });
    }
  }

  /**
  * authentification to generate a new token
  */
  generationPassword(idUser:string, idLicense, successCallback, errorCallback){
    if(idUser != null && idLicense != null && this.isConnected()) {
      var data = {
        idUser: idUser,
      }
      this.http.post(server + 'api/v1/user/generatePassword/' + this.user.token + '/' + idLicense, data, {
        headers: this.headers
      })
      .subscribe(data => {
        //this.user = data;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * create a new ticket
  */
  createTicket(ticket:Ticket, createBill:boolean, expirationDate, successCallback, errorCallback){
    if(ticket != null && createBill != null && this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense,
        ticket:ticket.toTicketLite(),
        createBill:createBill,
        expirationDate:expirationDate
      }
      this.http.put(server + 'api/v1/ticket/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * add customer
  */
  addCustomer(customer, successCallback, errorCallback){
    if(customer != null && this.isConnected() && this.haveLicense()) {
      customer.idLicense = this.license.idLicense;
      var data = {
        idLicense: this.license.idLicense,
        url:this.license.url,
        customer: customer
      }
      this.http.put(server + 'api/v1/customer/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * edit customer
  */
  editCustomer(customer, successCallback, errorCallback){
    if(customer != null && this.isConnected() && this.haveLicense()) {
      customer.idLicense = this.license.idLicense;
      var data = {
        idLicense: this.license.idLicense,
        url:this.license.url,
        customer: customer
      }
      this.http.post(server + 'api/v1/customer/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * push customer
  */
  pushCustomer(idCustomer, successCallback, errorCallback){
    if(idCustomer != null && this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense,
        idCustomer:idCustomer
      }
      this.http.post(server + 'api/v1/customer/resaonline/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }



  /**
  * generates all balances customers
  */
  generateAllBalancesCustomers(successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense
      }
      this.http.post(server + 'api/v1/customersBalances/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get customer
   */
  getCustomer(idCustomer, successCallback, errorCallback){
    if(idCustomer != null && this.isConnected() && this.haveLicense()) {
      this.http.get(server + 'api/v1/customer/' + this.user.token+ '/' + this.license.idLicense + '/' + idCustomer)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get customer
   */
  getCustomerByExternalId(externalId, successCallback, errorCallback){
    if(externalId != null && this.isConnected() && this.haveLicense()) {
      this.http.get(server + 'api/v1/customerByExternalId/' + this.user.token+ '/' + this.license.idLicense + '/' + externalId)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * get the bill by id bill
  */
  getBill(idBill, successCallback, errorCallback){
    if(this.isConnected() && idBill != null) {
      this.http.get(server + 'api/v1/bill/' + this.user.token + '/' + this.user.idLicense+ '/' + idBill)
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get the bill by id ticket
  */
  getBillByIdTicket(idTicket, successCallback, errorCallback){
    if(this.isConnected() && idTicket != null) {
      this.http.get(server + 'api/v1/billByTicket/' + this.user.token + '/' + this.user.idLicense+ '/' + idTicket)
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * get the bill by id ticket
  */
  sendBillByEmail(idBill, pdf, successCallback, errorCallback){
    if(this.isConnected() && idBill != null && pdf != null) {
      var data = {
        idBill: idBill,
        pdf:pdf
      }
      this.http.post(server + 'api/v1/sendBill/' + this.user.token + '/' + this.user.idLicense, data, {
        headers: this.headers
      }).subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback({message:'Not connected'});
  }

  /**
  * load resa services
  */
  loadRESAServices(successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()) {
      this.http.post(this.license.url + '/wp-admin/admin-ajax.php', 'action=getServices&idLicense='+this.license.idLicense, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data.services, data.places);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorTextByType('bad_request_getRESAServices'));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('bad_request_getRESAServices'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * load bookings
  */
  loadBookings(idBookings, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idBookings!=null) {
      var data = {
        action:'getBookings',
        idLicense:this.license.idLicense,
        idBookings:idBookings
      }

      this.http.post(this.license.url + '/wp-admin/admin-ajax.php', 'action=getBookings&idLicense='+this.license.idLicense+'&idBookings='+idBookings.join(','), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('bad_request_getRESAServices'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * add product
  */
  addProduct(product, successCallback, errorCallback){
    if(product != null && this.isConnected() && this.haveLicense()) {
      product.idLicense = this.license.idLicense;
      var data = {
        idLicense: this.license.idLicense,
        product: product
      }
      this.http.put(server + 'api/v1/product/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.products.push(data.product);
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * add products
  */
  addProducts(products, successCallback, errorCallback){
    if(products != null && this.isConnected() && this.haveLicense()) {
      for(var i = 0; i < products.length; i++) products[i].idLicense = this.license.idLicense;
      var data = {
        idLicense: this.license.idLicense,
        products: products
      }
      this.http.put(server + 'api/v1/products/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.products = this.products.concat(data.products);
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * edit product
  */
  editProduct(product, successCallback, errorCallback){
    if(product != null && product._id != null && product.idLicense && this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense,
        product: product
      }
      this.http.post(server + 'api/v1/product/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.updateArrayById(this.products, data.product);
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * delete product
  */
  deleteProduct(idProduct, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idProduct!=null) {
      this.http.delete(server + 'api/v1/product/' + this.user.token+ '/' + this.license.idLicense+'/' + idProduct)
      .subscribe(data => {
        this.license = data;
        this.removeArrayById(this.products, idProduct);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * add product
  */
  getRESAProducts(successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense
      }
      this.http.post(server + 'api/v1/resaProducts/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * resynchonization RESA Product
  */
  resynchonizationRESAProduct(product, successCallback, errorCallback){
    if(product != null && product._id != null && product.idLicense && this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense,
        product: product
      }
      this.http.post(server + 'api/v1/resynchonized/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.updateArrayById(this.products, data.product);
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * open CashRegister
   */
  openCashRegister(idCashRegister, date, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister){
      var data = {
        idLicense: this.license.idLicense,
        idCashRegister: idCashRegister,
        date:date,
      }
      this.http.post(server + 'api/v1/cashRegister/open/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.updateCashRegisterInLicense(data.cashRegister);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * closing out CashRegister
   */
  closingOutCashRegister(idCashRegister, closeDay, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister){
      var data = {
        idLicense: this.license.idLicense,
        idCashRegister: idCashRegister,
        closeDay:closeDay,
      }
      this.http.post(server + 'api/v1/cashRegister/close/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }


  /**
   * closing out CashRegister
   */
  closingOutMonthCashRegister(idCashRegister, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister){
      var data = {
        idLicense: this.license.idLicense,
        idCashRegister: idCashRegister
      }
      this.http.post(server + 'api/v1/cashRegister/closeMonth/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * closing out year
   */
  closingOutYearCashRegister(idCashRegister, force, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && force!=null){
      var data = {
        idLicense: this.license.idLicense,
        idCashRegister: idCashRegister,
        force:force
      }
      this.http.post(server + 'api/v1/cashRegister/year/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe((data:any) => {
        this.license = data.license;
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * cancel ticket
   */
  cancelTicket(idTicket, cause, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idTicket != null){
      var data = {
        idLicense: this.license.idLicense,
        idTicket: idTicket,
        cause: cause
      }
      this.http.post(server + 'api/v1/ticketCancel/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
  * create a bill with ticket
  */
  generateBillWithTicket(idTicket, successCallback, errorCallback){
    if(idTicket != null && this.isConnected() && this.haveLicense()) {
      var data = {
        idLicense: this.license.idLicense,
        idTicket:idTicket
      }
      this.http.put(server + 'api/v1/ticketBill/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   *  calculate error vat reduction
   */
  calculateErrorVatReductionEurosV1_6_1(successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()){
      this.http.get(server + 'api/v1/errorVat/' + this.user.token+ '/' + this.license.idLicense)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get ticket by date for a cash register
   */
  getTicketsByDate(idCashRegister, date, dateEnd, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && dateEnd != null && date != null){
      this.http.get(server + 'api/v1/tickets/' + this.user.token + '/' + this.license.idLicense + '/' + idCashRegister + '/' + date+ '/' + dateEnd)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get ticket by date for a cash register
   */
  getZTicketsByDate(idCashRegister, date, dateEnd, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && dateEnd != null && date != null){
      this.http.get(server + 'api/v1/zTickets/' + this.user.token + '/' + this.license.idLicense + '/' + idCashRegister + '/' + date+ '/' + dateEnd)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get tickets online
   */
  getTicketsOnline(idCashRegister, lastId, typeTicket, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister != null && lastId != null && typeTicket!=null){
      var data = {
        idLicense:this.license.idLicense,
        idCashRegister:idCashRegister,
        lastId:lastId,
        typeTicket:typeTicket
      }
      this.http.post(server + 'api/v1/ticketsOnline/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get last big big ticket
   */
  getLastBigBigZTicket(idCashRegister, lastId, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister != null && lastId != null){
      var data = {
        idLicense:this.license.idLicense,
        idCashRegister:idCashRegister,
        lastId:lastId
      }
      this.http.post(server + 'api/v1/bigBigZTicket/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get x ticket
   */
  getXTicket(idCashRegister, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister != null){
      this.http.get(server + 'api/v1/xticket/' + this.user.token + '/' + this.license.idLicense + '/' + idCashRegister)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get the current float
   */
  getFloatMovements(idCashRegister, startDate, endDate, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister != null){
      this.http.get(server + 'api/v1/floats/' + this.user.token + '/' + this.license.idLicense + '/' + idCashRegister + '/' + startDate + '/' + endDate)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get the current float
   */
  getCurrentFloat(idCashRegister, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister != null){
      this.http.get(server + 'api/v1/float/' + this.user.token + '/' + this.license.idLicense + '/' + idCashRegister)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * make an operation
   */
  makeAnOperation(idCashRegister, form, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idCashRegister != null && form != null){
      var data = {
        idLicense: this.license.idLicense,
        idCashRegister:idCashRegister,
        type:form.type,
        amount:form.amount,
        note: form.note
      }
      this.http.put(server + 'api/v1/float/' + this.user.token, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }


  /**
   * get bookings
   */
  getBookings(successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()){
      this.http.get(server + 'api/v1/bookings/' + this.user.token + '/' + this.license.idLicense)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get tickets by bookings
   */
  getTicketsByIdBooking(idBooking, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()){
      this.http.get(server + 'api/v1/ticketsByIdBooking/' + this.license.idLicense + '/' + idBooking)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * delete booking
   */
  deleteBooking(idBooking, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && idBooking != null){
      this.http.delete(server + 'api/v1/booking/' + this.user.token + '/' + this.license.idLicense + '/' + idBooking)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * delete all booking
   */
  deleteAllBookings(successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense()){
      this.http.delete(server + 'api/v1/bookings/' + this.user.token + '/' + this.license.idLicense)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * get statistics
   */
  getStatistics(startDate, endDate, successCallback, errorCallback){
    if(this.isConnected() && this.haveLicense() && startDate != null && endDate != null){
      var data = {
        startDate: startDate,
        endDate: endDate,
      }
      this.http.post(server + 'api/v1/stats/' + this.user.token + '/' + this.license.idLicense, data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

  /**
   * update the cash register of this license
   */
  updateCashRegisterInLicense(cashRegister){
    if(this.license != null && this.license.modelCashRegisters != null){
      for(var i = 0; i < this.license.modelCashRegisters.length; i++){
        if(this.license.modelCashRegisters[i]._id == cashRegister._id){
          cashRegister.test = this.license.modelCashRegisters[i].test;
          this.license.modelCashRegisters[i] = cashRegister;
        }
      }
    }
  }

  updateArrayById(array, model){
    for(var i = 0; i < array.length; i++){
      var local = array[i];
      if(local._id == model._id){
        array[i] = model;
      }
    }
  }

  removeArrayById(array, id){
    var index = -1;
    for(var i = 0; i < array.length; i++){
      var local = array[i];
      if(local._id == id){
        index = i;
      }
    }
    if(index != -1){
      array.splice(index, 1);
    }
  }

  /*****************************************************************************************
********************************************************************************************
****************************************************************************************/

  getUrl(url){
    url = url.replace(':token', this.getToken());
    return server + url;
  }

  /**
   * do get http method
   */
  get(url, successCallback, errorCallback){
    if(this.isConnected()) {
      this.http.get(this.getUrl(url))
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.error != null){
          errorCallback(error.error);
        }
        else {
          errorCallback({message:'error'});
        }
      });
    }
  }

  /**
   * do get http method
   */
  getWithoutConnection(url, successCallback, errorCallback){
    this.http.get(server + url)
    .subscribe(data => {
      if(successCallback != null){
        successCallback(data);
      }
    }, error => {
      if(errorCallback != null && error.error != null){
        errorCallback(error.error);
      }
      else {
        errorCallback({message:'error'});
      }
    });
  }

  /**
   * do post http method
   */
  put(url, data, successCallback, errorCallback){
    if(this.isConnected() && data != null) {
      this.http.put(this.getUrl(url), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.error != null){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback({message:'error'});
        }
      });
    }
  }

  /**
   * do post http method
   */
  post(url, data, successCallback, errorCallback){
    this.postWithHeaders(url, data, this.headers, successCallback, errorCallback);
  }

  /**
   * do post http method
   */
  postGlobal(url, data, successCallback, errorCallback){
    this.postGlobalWithHeaders(url, data, this.headers, successCallback, errorCallback);
  }

  /**
   * generate http headers
   */
  generateHttpHeaders(json){
    return new HttpHeaders(json);
  }

  /**
   * do post http method
   */
  postWithHeaders(url, data, headers, successCallback, errorCallback){
    if(this.isConnected() && data != null) {
      this.http.post(this.getUrl(url), data, {
        headers: headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.error != null){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback({message:'error'});
        }
      });
    }
  }

  /**
   * do post http method
   */
  postGlobalWithHeaders(url, data, headers, successCallback, errorCallback){
    if(this.isConnected() && data != null) {
      url = url.replace(':token', this.getToken());
      this.http.post(url, data, {
        headers: headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.error != null){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback({message:'error'});
        }
      });
    }
  }

  /**
   * do get http method
   */
  delete(url, successCallback, errorCallback){
    if(this.isConnected()) {
      this.http.delete(this.getUrl(url))
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.error != null){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback({message:'error'});
        }
      });
    }
  }

}
