import {Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';

declare let html2pdf;

@Component({
  selector: 'bill-dialog',
  templateUrl: 'billDialog.html',
  styleUrls:['./billDialog.css']
})


export class BillDialog {

  public bill = null;
  public anotherPayments = null;
  public displayPayButton:boolean = true;
  public displayCancelButton:boolean = true;
  public loadBookings:boolean = false;
  public extras = {bookings:[], services:[]};

  constructor(public userService: UserService, public dialogRef: MatDialogRef<BillDialog>, @Inject(MAT_DIALOG_DATA) public data: any,  public dialog: MatDialog) {
    if(this.data != null){
      this.bill = this.data.bill;
      this.anotherPayments = this.data.anotherPayments;
      if(this.data.displayPayButton != null){ this.displayPayButton = this.data.displayPayButton; }
      if(this.data.displayCancelButton != null){ this.displayCancelButton = this.data.displayCancelButton; }
    }
  }

  printBill(){
    var content = document.getElementById('printBill').innerHTML;
		var newWindow = window.open ('','', "menubar=yes,scrollbars=yes,resizable=yes");
		newWindow.document.open();
		newWindow.document.write("<html><head><title></title></head><body>"+content+"</body></html>");
		var arrStyleSheets = document.getElementsByTagName("link");
		for (var i = 0; i < arrStyleSheets.length; i++){
      if(arrStyleSheets[i].href.indexOf('bootstrap.min.css') == -1){
  			newWindow.document.head.appendChild(arrStyleSheets[i].cloneNode(true));
      }
		}
		var arrStyle = document.getElementsByTagName("style");
		for (var i = 0; i < arrStyle.length; i++){
			newWindow.document.head.appendChild(arrStyle[i].cloneNode(true));
		}
		newWindow.document.close();
		newWindow.focus();
		setTimeout(function(){
			newWindow.print();
			newWindow.close(); },
			1000);
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  downloadBill(){
    var date = new Date();
    const input = document.getElementById("printBill");
    input.style.transform = input.style.webkitTransform = 'scale(0.85)';
    html2pdf(input,{
      margin:       12,
      filename:     'facture_' + this.bill.id + '.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192 },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' }
    });
  }


  sendBillAction(){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '350px',
      data: {
        title:'Envoyer la facture n°' + this.bill.id + ' ?',
        text: 'Voulez-vous vraiment envoyer la facture n°' + this.bill.id + ', cela peut mettre un peu de temps ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const input = document.getElementById("printBill");
        html2pdf(input,{
          margin:       12,
          filename:     'facture_' + this.bill.id + '.pdf',
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { dpi: 192 },
          jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' },
          save: false,
          callback : function(res){ this.sendBill(res); }.bind(this)
        });
      }
    });
  }

  sendBill(pdf){
    this.dialogRef.close({action:'sendBill', pdf:pdf});
  }

  payBill(){
    this.dialogRef.close('payBill');
  }

  cancelBill(){
    this.dialogRef.close('cancelBill');
  }

  getIdBookings(idBookings) {
    var result = [];
    if(idBookings != null){
      var idBookings = idBookings.split('&&');
      for(var i = 0; i < idBookings.length; i++){
        var localIdBookings = idBookings[i].split('&');
        if(localIdBookings.length == 1) result.push(localIdBookings[0]);
        else if(localIdBookings.length == 2) {
          if(localIdBookings[0].length > 0) result.push(localIdBookings[0]);
          else if(localIdBookings[1].length > 0) result.push(localIdBookings[1]);
        }
      }
    }
    return result;
  }

  getTicketIdBookings(){
    var idBookings = this.getIdBookings(this.bill.ticket.idBookings);
    if(this.bill.ticket.idBooking != null) idBookings.push(this.bill.ticket.idBooking);
    return idBookings;
  }

  getBookings(){
    if(!this.loadBookings){
      this.loadBookings = true;
      this.userService.loadBookings(this.getTicketIdBookings(), function(json){
        this.loadBookings = false;
        this.extras.bookings = json.bookings;
        this.extras.services = json.extra;
        console.log(this.extras);
      }.bind(this), function(error){
        this.loadBookings = false;
        console.log(error);
      }.bind(this));
    }
  }
}
