import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { server } from '../app/global';

import { ErrorsService } from './errors.service';
import { UserService } from './user.service';

@Injectable()
export class LicenseService{

  private headers = new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8' });

  constructor(private userService:UserService, private http: HttpClient, private errorsService:ErrorsService) {

  }

  uploadTicketImage(data, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.post(server + 'api/v1/subLicenseTicketImage/' + this.userService.getToken(), data, {
        headers: {
        }
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  uploadBillImage(data, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.post(server + 'api/v1/subLicenseBillImage/' + this.userService.getToken(), data, {
        headers: {
        }
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editSubLicense(idLicense, subLicense, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.post(server + 'api/v1/subLicense/' + this.userService.getToken(), {idLicense:idLicense, subLicense:subLicense}, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  addPlace(idLicense, name, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense, name:name};
      this.http.put(server + 'api/v1/license/place/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editPlace(idLicense, idPlace, name, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense, idPlace:idPlace, name:name};
      this.http.post(server + 'api/v1/license/place/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deletePlace(idLicense, idPlace, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.delete(server + 'api/v1/license/place/' + this.userService.getToken()+'/'+ idLicense +'/'+ idPlace)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  addCashRegister(idLicense, cashRegister, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense, cashRegister:cashRegister};
      this.http.put(server + 'api/v1/license/cash/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editCashRegister(idLicense, cashRegister, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense, cashRegister:cashRegister};
      this.http.post(server + 'api/v1/license/cash/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deleteCashRegister(idLicense, idCashRegister, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.delete(server + 'api/v1/license/cash/' + this.userService.getToken()+'/'+ idLicense +'/'+ idCashRegister)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  addUser(idLicense, user, idCashRegisters, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense, user:user, idCashRegisters:idCashRegisters};
      this.http.put(server + 'api/v1/license/user/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editUser(idLicense, user, idCashRegisters, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense, user:user, idCashRegisters:idCashRegisters};
      this.http.post(server + 'api/v1/license/user/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deleteUser(idLicense, idUser, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.delete(server + 'api/v1/license/user/' + this.userService.getToken() + '/' + idLicense+'/'+idUser)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }


  addTva(idLicense, tva, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && tva != null){
      var data = {idLicense:idLicense, tva:tva};
      this.http.put(server + 'api/v1/license/tva/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editTva(idLicense, tva, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && tva!=null){
      var data = {idLicense:idLicense, tva:tva};
      this.http.post(server + 'api/v1/license/tva/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deleteTva(idLicense, tvaLabel, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && tvaLabel!=null){
      this.http.delete(server + 'api/v1/license/tva/' + this.userService.getToken()+'/'+ idLicense +'/'+ tvaLabel)
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  synchronizeResaOnlineVatList(idLicense, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense};
      this.http.post(server + 'api/v1/license/resaVatList/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  synchronizeResaOnlineVariables(idLicense, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      var data = {idLicense:idLicense};
      this.http.post(server + 'api/v1/license/resaVariables/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  addCategory(idLicense, category, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && category != null){
      var data = {idLicense:idLicense, category:category};
      this.http.put(server + 'api/v1/license/category/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editCategory(idLicense, category, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && category!=null){
      var data = {idLicense:idLicense, category:category};
      this.http.post(server + 'api/v1/license/category/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deleteCategory(idLicense, idCategory, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && idCategory!=null){
      this.http.delete(server + 'api/v1/license/category/' + this.userService.getToken()+'/'+ idLicense +'/'+ idCategory)
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  addGroup(idLicense, group, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && group != null){
      var data = {idLicense:idLicense, group:group};
      this.http.put(server + 'api/v1/license/group/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editGroup(idLicense, group, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && group!=null){
      var data = {idLicense:idLicense, group:group};
      this.http.post(server + 'api/v1/license/group/' + this.userService.getToken(), data, {
        headers: this.headers
      })
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deleteGroup(idLicense, idGroup, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && idGroup!=null){
      this.http.delete(server + 'api/v1/license/group/' + this.userService.getToken()+'/'+ idLicense +'/'+ idGroup)
      .subscribe(data => {
        this.userService.setLicense(data);
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }
}
