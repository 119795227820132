import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { FormControl } from '@angular/forms';

import { AlertDialog } from '../../components/alertDialog/alertDialog';
import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';
import { CustomerDialog } from '../../components/customerDialog/customerDialog';
import { BookingDialog } from '../../components/bookingDialog/bookingDialog';
import { BillDialog } from '../../components/billDialog/billDialog';
import { PadDialog } from '../../components/padDialog/padDialog';
import { PromptDialog } from '../../components/promptDialog/promptDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

import { Ticket, ShoppingLine, Payment, TicketLite } from '../../model/ticket'

declare let html2pdf;

@Component({
  selector: 'page-cash',
  templateUrl: 'cash2.html',
  styleUrls:['./cash2.css', './cash.css']
})

export class CashComponent implements OnInit {

  private tickets = [];
  public ticket:Ticket = null;
  private padNumber:string = '';
  private paymentView:boolean = false;
  private ticketCreated:TicketLite = null;
  private billCreated = false;
  private createBill = false;
  private cancelBill = false;
  private currentBill = null;
  private chooseOfExpirationDate = 30;
  private expirationDate = null;

  private customerFormControl: FormControl = new FormControl();
  private filteredCustomers: any[];

  private inputApplyValue:any = {type:'', index:0};

  private bookings = [];
  private bookingsSelected:any[] = [];
  private lastTimeoutBooking = null;
  public saveTickedLaunched:boolean = false;
  public closingOutLaunched:boolean = false;
  public loadTicketLaunched:boolean = false;
  public loadBillLaunched:boolean = false;
  public searchCustomerLaunched:boolean = false;
  public addEditCustomerLaunched:boolean = false;
  public loadBookingLaunched:boolean = false;
  public sendBillsLaunched:boolean = false;
  public editDatePlaceCash:boolean = false;
  public sideMenu = {display:false, panel:'tab-bookings'};
  public ticketDate = null;

  public typePaymentsCustomer = [];


  private searchTimer = null;

  constructor(public userService: UserService, public navService:NavService,
    public dialog: MatDialog, private datePipe: DatePipe, public snackBar: MatSnackBar, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      this.userService.initializeData(function(){
        this.initializeTicket();
        this.launchTimeoutBooking();
        this.checkPathParams();
        this.initEditDatePlaceCash();
      }.bind(this), function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  isAdmin(){ return this.userService.isAdmin(); }
  isManager(){ return this.userService.isManager(); }
  isVendor(){ return this.userService.isVendor(); }

  isTestMode(){ return this.userService.isTestMode(); }


  onDateSelect(event){
    this.ticket.setDate(event.year, event.month - 1, event.day);
  }

  setTicketDateVar(date){
    this.ticketDate = {
      year:date.getFullYear(),
      month:date.getMonth() + 1,
      day:date.getDate()
    }
  }

  initEditDatePlaceCash(){
    this.editDatePlaceCash = this.ticket.date == null || this.ticket.place == null || this.ticket.cashRegister == null;
  }

  setEditDatePlaceCash(editDate){
    this.editDatePlaceCash = editDate;
  }

  setDateNotClose(){
    this.ticket.setDateNotClose()
    this.setTicketDateVar(this.ticket.date);
  }

  setToOpenDate(){
    this.ticket.setToOpenDate()
    this.setTicketDateVar(this.ticket.date);
  }

  setActualDate(){
    this.ticket.setActualDate()
    this.setTicketDateVar(this.ticket.date);
  }

  /**
   * Chech path params
   */
  checkPathParams(){
    this.route.queryParams.subscribe(params => {
      if(params.bill){
        this.userService.getBill(params.bill, function(bill){
          this.loadBill(bill, params.type);
        }.bind(this), function(error){
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    });
  }


  launchTimeoutBooking(){
    if(this.userService.lastTimeoutBooking){
      clearTimeout(this.userService.lastTimeoutBooking);
    }
    this.userService.lastTimeoutBooking = setTimeout(function(){
      this.userService.getBookings(function(bookings){
        this.bookings = [];
        for(var i = 0; i < bookings.length; i++){
          var booking = bookings[i];
          booking.json = JSON.parse(booking.stringified);
          booking.idCreation = booking.json.idCreation;
          this.bookings.push(booking);
        }
        for(var i = 0; i < bookings.length; i++){
          this.bookings[i] = this.calculateBookingInformations(this.bookings[i]);
        }
        this.reloadIdBookings();
        this.launchTimeoutBooking();
      }.bind(this), function(error){ console.log(error); });
    }.bind(this), 2000);
  }

  reloadIdBookings(){
    if(this.getIdBookingsInTicket().length > 0 && this.bookingsSelected.length == 0){
      var bookingsToLoad = [];
      var idBookings = this.getIdBookingsInTicket();
      for(var i = 0; i < idBookings.length; i++){
        var idBooking = idBookings[i];
        for(var j = 0; j < this.bookings.length; j++){
          var booking = JSON.parse(JSON.stringify(this.bookings[j]));
          booking.stringified = JSON.parse(booking.stringified);
          if((booking.idCreation>-1?booking.idCreation:booking.idBooking) == idBooking){
            this.bookingsSelected.push(booking);
          }
        }
      }
    }
  }

  initializeTicket(){
    this.tickets = this.userService.loadTicketsInStorage();
    for(var i = 0; i < this.tickets.length; i++){
      this.tickets[i] = this.ticketFromJSON(this.tickets[i]);
    }
    this.ticketCreated = null;
    this.billCreated = false;
    if(this.tickets.length > 0 && this.tickets[0]){
      this.loadATicket(this.tickets[0]);
    }
    else {
      this.ticket = new Ticket();
      this.ticket.user = this.getUserById(this.userService.getUser()._id);
      if(this.ticket.user != null){
        var idCashRegisters = this.getIdCashRegistersByIdUser(this.ticket.user._id);
        if(idCashRegisters.length > 0) {
          this.ticket.cashRegister = this.getCashRegisterById(idCashRegisters[0]);
        }
        if(this.ticket.cashRegister != null && this.ticket.cashRegister.places.length > 0){
          this.ticket.place = this.getPlaceById(this.ticket.cashRegister.places[0]);
        }
      }
    }
    this.ticket.date = new Date();
    this.setTicketDateVar(this.ticket.date);
    this.saveTicketsInStorage();
  }

  getIndexTicket(idTicketGenerated){
    for(var i = 0; i < this.tickets.length; i++){
      var ticket = this.tickets[i];
      if(ticket.idTicketGenerated == idTicketGenerated){
        return i;
      }
    }
    return -1;
  }

  haveOneNotEmpty(){
    if(this.tickets.length > 1){
      for(var i = 0; i < this.tickets.length; i++){
        var ticket = this.tickets[i];
        if(!ticket.isEmpty()){
          return true;
        }
      }
    }
    return false;
  }

  haveOneEmpty(){
    for(var i = 0; i < this.tickets.length; i++){
      var ticket = this.tickets[i];
      if(ticket.isEmpty()){
        return true;
      }
    }
    return false;
  }

  deleteTicket(idTicketGenerated){
    this.bookingsSelected = [];
    this.tickets.splice(this.getIndexTicket(idTicketGenerated), 1);
    if(this.tickets.length == 0 || this.isCurrentTicket(idTicketGenerated)){
      var alreadyEmptyTickey = false;
      for(var i = 0; i < this.tickets.length; i++){
        var ticket = this.tickets[i];
        if(ticket.isEmpty()){
          alreadyEmptyTickey = true;
          this.loadATicket(ticket);
          break;
        }
      }
      if(!alreadyEmptyTickey){
        this.createNewTicket();
      }
    }
    else {
      this.saveTicketsInStorage();
    }
  }

  createNewTicket(launchStopPayment = true){
    if(this.bookingsSelected.length > 0){
      this.bookingsSelected = [];
      this.deleteTicket(this.ticket.idTicketGenerated);
    }
    this.currentBill = null;
    this.bookingsSelected = [];
    this.ticketCreated = null;
    this.billCreated = false;
    if(launchStopPayment){
      this.stopPaymentView();
    }
    var newTicket = new Ticket();
    newTicket.date = this.ticket.date;
    var actualDate = new Date();
    if(newTicket.date.getFullYear() == actualDate.getFullYear() &&
      newTicket.date.getMonth() == actualDate.getMonth() &&
      newTicket.date.getDate() == actualDate.getDate()){
      newTicket.date = new Date(actualDate);
    }
    if(this.ticket.place != null) newTicket.place = this.getPlaceById(this.ticket.place.id);
    if(this.ticket.cashRegister != null) newTicket.cashRegister = this.getCashRegisterById(this.ticket.cashRegister._id);
    if(newTicket.cashRegister != null){
      if(newTicket.cashRegister.places.indexOf(newTicket.place.id) == -1) {
        newTicket.cashRegister = null;
      }
      else {
        if(this.ticket.user != null) newTicket.user = this.getUserById(this.ticket.user._id);
      }
    }
    this.ticket = newTicket;
    this.setTicketDateVar(this.ticket.date);
    if(!this.haveOneEmpty()){
      this.addTicketInTickets(this.ticket);
    }
    else {
      this.loadEmptyTicket();
    }
    this.saveTicketsInStorage();
  }

  loadEmptyTicket(){
    for(var i = 0; i < this.tickets.length; i++){
      var ticket = this.tickets[i];
      if(ticket.isEmpty()){
        this.loadATicket(ticket);
        break;
      }
    }
  }

  breackTicket(){
    if(!this.ticket.isEmpty()){
      var alreadyEmptyTickey = false;
      for(var i = 0; i < this.tickets.length; i++){
        var ticket = this.tickets[i];
        if(ticket.isEmpty()){
          alreadyEmptyTickey = true;
          this.loadATicket(ticket);
          break;
        }
      }
      if(!alreadyEmptyTickey){
        this.createNewTicket();
      }
    }
  }

  generateNewId(array, field, prefix):string{
    var actual = [];
    if(array=='' ||	array==false){
      array = [];
    }
    else {
      for(var i = 0; i < array.length; i++){
        actual.push(array[i][field]);
      }
    }
    var index = 0;
    var idName = index;
    if(prefix.length > 0) idName = prefix + index;
    var found = true;
    while(found){
      index++;
      idName = index;
      if(prefix.length > 0) idName = prefix + index;
      found = actual.indexOf(idName) !=-1;
    }
    return idName + '';
  }

  isCurrentTicket(idTicketGenerated){
    return this.ticket.idTicketGenerated == idTicketGenerated;
  }

  addTicketInTickets(ticket){
    this.tickets.push(ticket);
    ticket.idTicketGenerated = this.generateNewId(this.tickets, 'idTicketGenerated', 'ticket');
  }

  ticketFromJSON(ticketJSON){
    var ticket = new Ticket();
    ticket.idTicketGenerated = ticketJSON.idTicketGenerated;
    ticket.createDate = new Date(ticketJSON.createDate);
    ticket.date = new Date(ticketJSON.date);
    ticket.idBooking = ticketJSON.idBooking;
    ticket.idBookings = ticketJSON.idBookings;
    if(ticketJSON.place != null) ticket.place = this.getPlaceById(ticketJSON.place.id);
    if(ticketJSON.cashRegister != null) ticket.cashRegister = this.getCashRegisterById(ticketJSON.cashRegister._id);
    if(ticket.cashRegister != null){
      if(ticket.cashRegister.places.indexOf(ticket.place.id) == -1) {
        ticket.cashRegister = null;
      }
      else {
        if(ticketJSON.user != null){
          ticket.user = this.getUserById(ticketJSON.user._id);
        }
      }
    }
    if(ticketJSON.reduction != null){
      ticket.reduction = ticketJSON.reduction;
      ticket.initReductionChecked();
    }
    for(var i = 0; i < ticketJSON.shoppingList.length; i++){
      var ticketShoppingLine = ticketJSON.shoppingList[i];
      var shoppingLine = new ShoppingLine(ticketShoppingLine, ticketShoppingLine.tva, ticketShoppingLine.quantity);
      shoppingLine.reduction = ticketShoppingLine.reduction;
      shoppingLine.initReductionChecked();
      shoppingLine.product = ticketShoppingLine.product;
      ticket.shoppingList.push(shoppingLine);
    }
    ticket.customer = ticketJSON.customer;
    return ticket;
  }

  loadATicket(ticket){
    if(ticket != null){
      if(this.bookingsSelected.length > 0){
        this.bookingsSelected = [];
        this.deleteTicket(this.ticket.idTicketGenerated);
      }
      this.loadTicketLaunched = true;
      this.bookingsSelected = [];
      this.ticket = ticket;
      this.ticket.date = new Date(ticket.date);
      this.setTicketDateVar(this.ticket.date);
      if(ticket.place != null) this.ticket.place = this.getPlaceById(ticket.place.id);
      if(ticket.cashRegister != null) this.ticket.cashRegister = this.getCashRegisterById(ticket.cashRegister._id);
      if(this.ticket.cashRegister != null){
        if(this.ticket.cashRegister.places.indexOf(ticket.place.id) == -1) {
          this.ticket.cashRegister = null;
        }
        else {
          if(ticket.user != null) this.ticket.user = this.getUserById(ticket.user._id);
        }
      }
      if(ticket.reduction != null){
        this.ticket.reduction = ticket.reduction;
        this.ticket.initReductionChecked();
      }
      /*
      for(var i = 0; i < ticket.shoppingList.length; i++){
        var ticketShoppingLine = ticket.shoppingList[i];
        var shoppingLine = new ShoppingLine(ticketShoppingLine, ticketShoppingLine.tva, ticketShoppingLine.quantity);
        shoppingLine.reduction = ticketShoppingLine.reduction;
        shoppingLine.initReductionChecked();
        shoppingLine.product = ticketShoppingLine.product;
        this.ticket.shoppingList.push(shoppingLine);
      }
      */
      if(ticket.customer != null && ticket.customer._id != null){
        this.userService.getCustomer(ticket.customer._id, function(customer){
          this.loadTicketLaunched = false;
          this.setCustomer(customer);
          this.reloadIdBookings();
        }.bind(this), function(error){
          this.loadTicketLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
      else {
        this.loadTicketLaunched = false;
      }
      this.stopPaymentView();
    }
  }

  formatIdBookings(idBookings) {
    var result = [];
    if(idBookings != null){
      var idBookings = idBookings.split('&&');
      for(var i = 0; i < idBookings.length; i++){
        var localIdBookings = idBookings[i].split('&');
        if(localIdBookings.length == 1) result.push(localIdBookings[0]);
        else if(localIdBookings.length == 2) {
          if(localIdBookings[0].length > 0) result.push(localIdBookings[0]);
          else if(localIdBookings[1].length > 0) result.push(localIdBookings[1]);
        }
      }
    }
    return result;
  }

  loadBill(bill, type = 'ok'){
    if(bill != null){
      this.loadBillLaunched = true;
      this.createNewTicket();
      this.currentBill = bill;
      this.ticket.idBill = bill._id;
      if(bill.ticket.idBooking != null) this.ticket.idBooking = bill.ticket.idBooking;
      if(bill.ticket.idBookings != null) this.ticket.idBookings = this.formatIdBookings(bill.ticket.idBookings);
      if(bill.ticket.idCashRegister != null){
        var cashRegister = this.getCashRegisterById(bill.ticket.idCashRegister);
        if(cashRegister != null){
          this.ticket.cashRegister = cashRegister;
        }
      }
      var sign = 1;
      if(type == 'cancel') sign = -1;
      if(bill.ticket.reduction != null){
        this.ticket.reduction = bill.ticket.reduction;
        this.ticket.initReductionChecked();
      }
      for(var i = 0; i < bill.ticket.shoppingList.length; i++){
        var ticketShoppingLine = bill.ticket.shoppingList[i];
        var shoppingLine = new ShoppingLine(ticketShoppingLine, ticketShoppingLine.tva, (ticketShoppingLine.quantity * sign));
        shoppingLine.reduction = ticketShoppingLine.reduction;
        shoppingLine.initReductionChecked();
        this.ticket.shoppingList.push(shoppingLine);
      }
      this.userService.getCustomer(bill.ticket.idCustomer, function(customer){
        this.setCustomer(customer);
        this.goPaymentAdvancePaymentView();
        if(type == 'cancel'){
          this.createBill = true;
          this.cancelBill = true;
          this.ticket.type = 'ticket';
          this.addPaymentWithPrice('credit', 0);
          this.chooseOfExpirationDate = -1;
          this.calculateExpirationDate();
        }
        this.loadBillLaunched = false;
      }.bind(this), function(error){
        this.loadBillLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  clearCashRegisterAndUser(){
    this.ticket.cashRegister = null;
    this.ticket.user = null;
  }

  clearUser(){
    this.ticket.user = null;
  }

  saveTicketsInStorage(){
    this.userService.saveTicketsInStorage(this.tickets);
    //this.userService.saveTicketsInStorage([this.ticket]);
  }

  isRendererView(){ return this.ticketCreated != null; }
  isPaymentView(){ return this.paymentView; }
  goPaymentView(){
    this.paymentView = true;
    this.ticket.type = 'ticket';
  }

  goPaymentAdvancePaymentView(){
    this.paymentView = true;
    this.ticket.type = 'receipt';
  }

  goPaymentDeferredPaymentView(){
    this.paymentView = true;
    this.createBill = true;
    this.cancelBill = false;
    this.ticket.type = 'ticket';
    this.calculateExpirationDate();
  }

  createTicketWithCash(){
    if(this.ticket.payments.length == 0){
      this.ticket.type = 'ticket';
      let dialogRef = this.dialog.open(PromptDialog, {
        width: '350px',
        data: {
          title:'Encaisser le ticket en espèce ?',
          text: 'Indiquez le montant de l\'espèce ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          var input = result.note;
          if(!isNaN(input)){
            this.addPaymentWithPrice('cash', input);
            if(this.ticket.ticketIsOK()){
              this.saveTicket();
            }
            else {
              let snackBarRef = this.snackBar.open('Le montant du ticket n\'est pas bon !', 'OK', {
                duration: 8000,
              });
              snackBarRef.onAction().subscribe(() => {
                snackBarRef.dismiss();
              });
              this.goPaymentView();
            }
          }
          else {
            let snackBarRef = this.snackBar.open('Ceci n\'est pas un nombre valide !', 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }
        }
      });
    }
  }

  createTicketWithCard(){
    if(this.ticket.payments.length == 0){
      this.ticket.type = 'ticket';
      this.addPayment('card');
      if(this.ticket.ticketIsOK()){
        this.saveTicket();
      }
      else {
        let snackBarRef = this.snackBar.open('Le montant du ticket n\'est pas bon !', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.goPaymentView();
      }
    }
  }


  stopPaymentView(){
    this.paymentView = false;
    this.createBill = false;
    this.cancelBill = false;
    this.ticket.payments = [];
    this.ticketCreated = null;
    if(this.ticket.idBill != null){
      this.createNewTicket(false);
      this.navService.goBack();
    }
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }

  goCustomers(){
    this.navService.changeRoute('customers');
  }

  goBills(){
    this.navService.changeRoute('bills');
  }

  goRapport(){
    if(this.ticket.cashRegister != null){
      this.navService.changeRoute('rapports/' + this.ticket.cashRegister._id + '/'+ new Date(this.ticket.cashRegister.openDate).getTime());
    }
  }

  goRapportToClosing(){
    if(this.ticket.cashRegister != null){
      this.navService.changeRoute('rapports/' + this.ticket.cashRegister._id + '/'+ new Date(this.ticket.cashRegister.openDate).getTime()+'/closingOut');
    }
  }

  goRapportToFloat(){
    if(this.ticket.cashRegister != null){
      this.navService.changeRoute('rapports/' + this.ticket.cashRegister._id + '/'+ new Date(this.ticket.cashRegister.openDate).getTime()+'/float');
    }
  }

  goSettingsDisplayCashRegister(){
    if(this.ticket.cashRegister != null){
      this.navService.changeRoute('settings/display/' + this.ticket.cashRegister._id);
    }
  }

  goSettingsMeansOfPaymentCashRegister(){
    if(this.ticket.cashRegister != null){
      this.navService.changeRoute('settings/meansOfPayment/' + this.ticket.cashRegister._id);
    }
  }

  openSideMenu(panel){
    this.sideMenu.display = true;
    this.sideMenu.panel = panel;
  }

  closeSideMenu(){
    this.sideMenu.display = false;
  }

  displayPlaces(booking, places){
    var display = '';
    if(places != null){
      for(var i = 0; i < places.length; i++){
        var place = places[i];
        if(booking.idPlaces.indexOf(place.id) != -1){
          if(display != '') display += ', ';
          display += place.name['fr_FR'];
        }
      }
    }
    return display;
  }

  getBookingByIdCreation(idCreation){
    return this.bookings.find(element => {
      var idBooking = element.idCreation>-1?element.idCreation:element.idBooking;
      if(idBooking == idCreation) return element;
    });
  }


  calculateBookingInformations(booking){
    var json = JSON.parse(booking.stringified);
    booking.customer = json.customer;
    booking.json = json;
    booking.idCreation = booking.json.idCreation;

    var idPlaces = [];
    for(var i = 0; i < json.appointments.length; i++){
      var appointment = json.appointments[i];
      if(appointment.idPlace.length > 0 && idPlaces.indexOf(appointment.idPlace) == -1){
        idPlaces.push(appointment.idPlace);
      }
    }

    booking.idPlaces = idPlaces;
    booking.displayPlaces = this.displayPlaces(booking,json.places);
    booking.groupIdBookings = [];
    if(json.groupIdBookings != null){
      for(var i = 0; i < json.groupIdBookings.length; i++){
        var idCreation = json.groupIdBookings[i] * 1;
        if(this.getBookingByIdCreation(idCreation) != null){
          booking.groupIdBookings.push(idCreation);
        }
      }
    }
    return booking;
  }

  getIdBookingsInTicket(){
    return this.ticket.getIdBookingsInTicket();
  }

  loadBookings(bookings){
    this.closeSideMenu();
    for(var i = 0; i < bookings.length; i++){
      var booking = bookings[i];
      this.loadBooking(booking, i == 0);
    }
  }

  deleteBookings(bookings){
    this.closeSideMenu();
    this.loadBookingLaunched = true;
    this.launchDeleteBookings(bookings, 0, function(){
      this.loadBookingLaunched = false;
      this.deleteTicket(this.ticket.idTicketGenerated);
      this.createNewTicket();
    }.bind(this));
  }

  deleteAllBookings(){
    this.closeSideMenu();
    this.loadBookingLaunched = true;
    this.userService.deleteAllBookings(function(){
      this.loadBookingLaunched = false;
      this.createNewTicket();
      let snackBarRef = this.snackBar.open('Suppression effectuée', 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this), function(error){
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this));
  }

  chooseBooking(){
    if(this.bookings.length > 0){
      var idBookings = this.getIdBookingsInTicket();
      let dialogRef = this.dialog.open(BookingDialog, {
        width: '50%',
        data: {bookings:this.bookings, idBookings:idBookings},
      });
      dialogRef.afterClosed().subscribe(data => {
        if(data!=null && data.action == 'load' && data.bookings){
          this.loadBookings(data.bookings);
        }
        else if(data!=null && data.action == 'delete' && data.bookings){
          this.deleteBookings(data.bookings);
        }
        else if(data!=null && data.action == 'deleteAll' && data.bookings){
          this.deleteAllBookings();
        }
      });
    }
  }

  loadBooking(booking, loadCustomer){
    if(this.ticket.noHaveBooking() || (this.bookingsSelected.length > 0 && this.bookingsSelected[0].customer.externalId != booking.customer.externalId)){
      this.createNewTicket();
    }
    this.loadBookingLaunched = true;
    booking.stringified = JSON.parse(booking.stringified);
    this.bookingsSelected.push(booking);
    this.ticket.addIdBooking(booking.idCreation!=-1?booking.idCreation:booking.idBooking);
    if(loadCustomer){
      this.userService.getCustomerByExternalId(booking.stringified.idCustomer, function(customer){
        this.getTicketsByIdBooking(booking);
        this.setCustomer(customer);
      }.bind(this), function(error){
        this.getTicketsByIdBooking(booking);
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
    else {
      this.getTicketsByIdBooking(booking);
    }
  }

  getTotalBookings(){
    var price = 0;
    for(var i = 0; i < this.bookingsSelected.length; i++){
      var booking = this.bookingsSelected[i];
      price += booking.stringified.totalPrice * 1;
    }
    return price;
  }

  getNeedToPaymentBookings(){
    var price = 0;
    for(var i = 0; i < this.bookingsSelected.length; i++){
      var booking = this.bookingsSelected[i];
      price += booking.stringified.needToPay * 1;
    }
    return price;
  }

  isSameTotalThatBooking(){
    if(this.bookingsSelected.length == 0) return true;
    else if(this.ticket.getTotalTTC() <= 0) return true;
    return this.getTotalBookings() == this.ticket.getTotalTTC();
  }

  getPlaceName(idPlace){
    if(this.bookingsSelected.length > 0){
      var places = this.bookingsSelected[0].stringified.places;
      for(var i = 0; i < places.length; i++){
        var place = places[i];
        if(place.id == idPlace){
          return place.name['fr_FR'];
        }
      }
    }
    return '';
  }

  getAppointmentsNumberPrices(){
    var appointmentsNumberPrices = [];
    if(this.bookingsSelected.length > 0){
      for(var i = 0; i < this.bookingsSelected[0].stringified.appointments.length; i++){
        var appointment = this.bookingsSelected[0].stringified.appointments[i];
        for(var j = 0; j < appointment.appointmentNumberPrices.length; j++){
          appointmentsNumberPrices.push({
            appointment: appointment,
            appointmentNumberPrices: appointment.appointmentNumberPrices[j]
          });
        }
      }
    }
    return appointmentsNumberPrices;
  }


  getAppointmentService(idAppointment, booking){
    if(booking.stringified.extra != null){
      for(var i = 0; i < booking.stringified.extra.length; i++){
        var extra = booking.stringified.extra[i];
        if(extra.type == 'service' && extra.idAppointment == idAppointment){
          return extra;
        }
      }
    }
    return null;
  }

  getAppointmentServiceName(idAppointment, booking){
    var service = this.getAppointmentService(idAppointment, booking);
    if(service) return service.name;
    return '';
  }

  getAppointmentServicePrice(idAppointment, idAppointmentNumberPrice, booking){
    if(booking.stringified.extra != null){
      for(var i = 0; i < booking.stringified.extra.length; i++){
        var extra = booking.stringified.extra[i];
        if(extra.type == 'service' && extra.idAppointment == idAppointment){
          for(var j = 0; j < extra.prices.length; j++){
            if(extra.prices[j].idAppointmentNumberPrice == idAppointmentNumberPrice){
              return extra.prices[j];
            }
          }
        }
      }
    }
    return null;
  }

  getAppointmentPriceName(idAppointment, idAppointmentNumberPrice, booking){
    var servicePrice = this.getAppointmentServicePrice(idAppointment, idAppointmentNumberPrice, booking);
    if(servicePrice) return servicePrice.name;
    return '';
  }

  getAppointmentPrice(idAppointment, idAppointmentNumberPrice, booking){
    var servicePrice = this.getAppointmentServicePrice(idAppointment, idAppointmentNumberPrice, booking);
    if(servicePrice) return servicePrice.price;
    return 0;
  }

  getAppointmentReductionName(idAppointment, idAppointmentReduction, booking){
    if(booking.stringified.extra != null){
      for(var i = 0; i < booking.stringified.extra.length; i++){
        var extra = booking.stringified.extra[i];
        if(extra.type == 'service' && extra.idAppointment == idAppointment){
          for(var j = 0; j < extra.reductions.length; j++){
            if(extra.reductions[j].idAppointmentReduction == idAppointmentReduction){
              return extra.reductions[j].name;
            }
          }
        }
      }
    }
    return '';
  }

  getAppointmentReductionPrice(idAppointment, idServicePrice, booking){
    if(booking.stringified.extra != null){
      for(var i = 0; i < booking.stringified.extra.length; i++){
        var extra = booking.stringified.extra[i];
        if(extra.type == 'service' && extra.idAppointment == idAppointment){
          for(var j = 0; j < extra.prices.length; j++){
            if(extra.prices[j].id == idServicePrice){
              return extra.prices[j].price;
            }
          }
        }
      }
    }
    return 0;
  }

  getBookingReductionsName(idReduction, booking){
    if(booking.stringified.extra != null){
      for(var i = 0; i < booking.stringified.extra.length; i++){
        var extra = booking.stringified.extra[i];
        if(extra.type == 'reduction' && extra.id == idReduction){
          return extra.name;
        }
      }
    }
    return '';
  }

  getTicketsByIdBooking(booking){
    this.userService.getTicketsByIdBooking(booking.idBooking, function(tickets){
      var errorServicesOrServicesPrices = false;
      this.loadBookingLaunched = false;
      var isSame = false;
      booking.stringified.alreadyTotal = 0;
      var alreadyTotal = 0;
      for(var i = 0; i < tickets.length; i++){
        isSame = (this.bookingsSelected.idBooking == tickets[i].idBooking);
        var ticket = new TicketLite(tickets[i]);
        if(ticket.isSimpleTicket()){
          alreadyTotal += ticket.totalTTCPrice * 1;
        }
      }
      var products = this.getProducts();
      var productsToAdd = [];
      for(var i = 0; i < booking.stringified.appointments.length; i++){
        var appointment = booking.stringified.appointments[i];
        for(var j = 0; j < appointment.appointmentNumberPrices.length; j++){
          var appointmentNumberPrice = appointment.appointmentNumberPrices[j];
          var service = this.getAppointmentService(appointment.id, booking);
          if(service != null){
            var servicePrice = this.getAppointmentServicePrice(appointment.id, appointmentNumberPrice.id, booking);
            if(servicePrice != null){
              for(var indexProduct = 0; indexProduct < products.length; indexProduct++){
                var product = products[indexProduct];
                var number = this.getNumberOfProductBySlugs(product, service.slug, servicePrice.slug);
                if(number > 0){
                  var quantity = appointmentNumberPrice.number;
                  if(booking.stringified.status == 'cancelled' || booking.stringified.status == 'abandonned'){
                    quantity *= -1;
                  }
                  var hours = false;
                  if(product.thresholded){
                    var thresholdPrice = product.thresholdPrices.find(element => {
                      if(element.min <= quantity && quantity <= element.max){
                        return element;
                      }
                    });
                    if(thresholdPrice) hours = thresholdPrice.byHours;
                  }
                  if(hours){
                    var duration = ((new Date(appointment.endDate)).getTime() - (new Date(appointment.startDate)).getTime())/(3600*1000);
                    product = this.multiplicateByHours(product, duration);
                  }
                  var productToAdd = productsToAdd.find(element => {
                    if(product._id.toString() == element.product._id.toString() && element.internalIdLink == appointment.internalIdLink){
                      return element;
                    }
                  });
                  if(productToAdd != null){
                    productToAdd.days = productToAdd.days + 1;
                  }
                  else {
                    productsToAdd.push({
                      product:product,
                      quantity: quantity * number,
                      deactivated: appointmentNumberPrice.deactivated,
                      internalIdLink: appointment.internalIdLink,
                      days:1
                    });
                  }
                }
              }
            }
            else {
              errorServicesOrServicesPrices = true;
            }
          }
          else {
            errorServicesOrServicesPrices = true;
          }
        }
      }

      for(var i = 0; i < productsToAdd.length; i++){
        var productToAdd = productsToAdd[i];
        var shoppingLine = this.addNewShoppingLine(
          this.multiplicateByHours(productToAdd.product, productToAdd.days), productToAdd.quantity);
        if(productToAdd.deactivated && shoppingLine!=null){
          shoppingLine.price = 0;
          shoppingLine.priceTTC = 0;
          shoppingLine.thresholded = false;
          shoppingLine.freeWord = 'inclus';
        }
      }

      var bookingPurcentReductions = 0;
      for(var i = 0; i < booking.stringified.bookingReductions.length; i++){
        var bookingReduction = booking.stringified.bookingReductions[i];
        if(!isNaN(bookingReduction.value) && bookingReduction.type == 1){
          bookingPurcentReductions += (bookingReduction.value * 1);
        }
      }
      if(bookingPurcentReductions != 0){
        this.ticket.setGlobalReduction(bookingPurcentReductions, 'purcent');
      }


      var displayErrorCustomBooking = false;
      //Reduction with fix amount
      if(booking.stringified.bookingReductions.length > 0){
        for(var i = 0; i < booking.stringified.bookingReductions.length; i++){
          var bookingReduction = booking.stringified.bookingReductions[i];
          if(!isNaN(bookingReduction.value) && bookingReduction.type == 0){
            var idTVA = null;
            var tva = this.getTvaByValue(bookingReduction.vatAmount);
            if(tva != null){
              idTVA = tva._id;
            }
            if(idTVA != null){
              var value = bookingReduction.value * -1;
              this.addNewShoppingLine({
                _id:'reduction',
                name:this.getBookingReductionsName(bookingReduction.idReduction, booking),
                publicName:this.getBookingReductionsName(bookingReduction.idReduction, booking),
                reference:'',
                price:this.arround(value / (1 + tva.value / 100)),
                priceTTC:value,
                tva:idTVA
              }, bookingReduction.number);
            }
            else {
              displayErrorCustomBooking = true;
            }
          }
        }
      }

      //Custom reduction
      if(booking.stringified.bookingCustomReductions.length > 0){
        if(this.getTvaList().length > 0){
          for(var i = 0; i < booking.stringified.bookingCustomReductions.length; i++){
            var bookingCustomReduction = booking.stringified.bookingCustomReductions[i];
            var idTVA = null;
            var tva = this.getTvaByValue(bookingCustomReduction.vatValue);
            if(tva != null){
              idTVA = tva._id;
            }
            if(idTVA != null){
              this.addNewShoppingLine({
                _id:'customReduction',
                name:bookingCustomReduction.description,
                publicName:bookingCustomReduction.description,
                reference:'',
                price:this.arround(bookingCustomReduction.amount / (1 + tva.value / 100)),
                priceTTC:bookingCustomReduction.amount,
                tva:idTVA
              }, 1);
            }
            else {
              displayErrorCustomBooking = true;
            }
          }
        }
        else {
          displayErrorCustomBooking = true;
        }
        if(displayErrorCustomBooking){
          let snackBarRef = this.snackBar.open('Problème lors de la génération des réductions', 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }
      }

      if(isSame){
        booking.stringified.alreadyTotal = alreadyTotal;
      }

      if(errorServicesOrServicesPrices){
        let dialogRef = this.dialog.open(AlertDialog, {
          width: '350px',
          data: {
            title: 'Erreur chargement',
            text: 'Nous avons détecté que le chargement automatique des produits de la réservation à échouée<br />Ceci est peut-être normal si vous n\'avez pas synchroniser les produits avec RESA Online<br /><span class="text-danger">Essayez de renvoyer la reservation depuis RESA Online.</span>'
          }
        });
        dialogRef.afterClosed().subscribe(result => {

        });
      }
    }.bind(this), function(error){
      this.loadBookingLaunched = false;
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this));
  }

  arround(value){
    return Number((value * 1).toFixed(2));
  }

  getNumberOfProductBySlugs(product, serviceSlug, servicePriceSlug){
    for(var i = 0; i < product.slugs.length; i++){
      var slug = product.slugs[i];
      if(slug.service == serviceSlug && slug.servicePrice == servicePriceSlug){
        return slug.number;
      }
    }
    return 0;
  }

  calculateHTPriceProduct(priceTTC, tva){
    var tvaValue = 0;
    if(tva != null && tva.value != null){
      tvaValue = tva.value;
    }
    return Number((priceTTC / (1 + (tvaValue / 100))).toFixed(2));
  }

  calculateTTCPriceProduct(priceHT, tva){
    var tvaValue = 0;
    if(tva != null && tva.value != null){
      tvaValue = tva.value;
    }
    return Number((priceHT * (1 + tvaValue / 100)).toFixed(2));
  }

  multiplicateByHours(product, hours){
    product = JSON.parse(JSON.stringify(product));
    var tva = this.getTvaById(product.tva)
    for(var i = 0; i < product.thresholdPrices.length; i++){
      var thresholdPrice = product.thresholdPrices[i];
      thresholdPrice.priceTTC *= hours;
      thresholdPrice.price = this.calculateHTPriceProduct(thresholdPrice.priceTTC, tva);
      thresholdPrice.fixedPriceTTC *= hours;
      thresholdPrice.fixedPrice = this.calculateHTPriceProduct(thresholdPrice.fixedPriceTTC, tva);
      thresholdPrice.priceSupPersonTTC *= hours;
      thresholdPrice.priceSupPerson = this.calculateHTPriceProduct(thresholdPrice.priceSupPersonTTC, tva);
    }
    product.priceTTC *= hours;
    product.price = this.calculateHTPriceProduct(product.priceTTC, tva);
    return product;
  }

  isNaN(value){
    return isNaN(value);
  }

  deleteBooking(){
    if(!this.loadBookingLaunched){
      var title = 'Ne plus encaisser cette réservation ?';
      var text = 'Voulez-vous vraiment ne plus encaisser cette réservation ?';
      if(this.bookingsSelected.length > 1){
        title = 'Ne plus encaisser ces réservations ?';
        text = 'Voulez-vous vraiment ne plus encaisser ces réservations ?';
      }
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title: title,
          text: text
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result && this.bookingsSelected.length > 0){
          this.loadBookingLaunched = true;
          this.launchDeleteBookings(this.bookingsSelected, 0, function(){
            this.loadBookingLaunched = false;
            this.createNewTicket();
          }.bind(this));
        }
      });
    }
  }

  launchDeleteBookings(bookings, index, successCallback){
    this.userService.deleteBooking(bookings[index].idBooking, function(data){
      if(index < bookings.length - 1){
        this.launchDeleteBookings(bookings, index + 1, successCallback);
      }
      else if(successCallback != null){
        successCallback();
      }
    }.bind(this), function(error){
      if(index < bookings.length - 1){
        this.launchDeleteBookings(bookings, index + 1, successCallback);
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }
      else if(successCallback != null){
        successCallback();
      }
    }.bind(this));
  }

  getPseudo():String{
    var pseudo:string = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicense(){
    return this.userService.getLicense();
  }


  getCustomersSearch(){
    this.filteredCustomers = [];
    clearTimeout(this.searchTimer);
    if(!this.ticket.isCustomerValid()){
      this.searchTimer = setTimeout(function(){
        this.searchCustomerLaunched = true;
        this.userService.getCustomersSearch(this.ticket.customer, function(customers){
          this.searchCustomerLaunched = false;
          this.filteredCustomers = customers;
        }.bind(this), function(error){
          this.searchCustomerLaunched = false;
        }.bind(this));
      }.bind(this), 500);
    }
  }


  getCategories():any[]{
    var categories = [];
    if(this.userService.haveLicense()){
      categories = this.userService.getLicense().categories;
    }
    return categories;
  }

  getProducts():any[]{
    var products = [];
    if(this.userService.haveLicense()){
      products = this.userService.getProducts();
    }
    return products;
  }

  getGroups():any[]{
    var products = [];
    if(this.userService.haveLicense()){
      products = this.userService.getLicense().groups;
    }
    return products;
  }

  getDisplay(){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.displays.length; i++){
      var display = license.displays[i];
      if(this.ticket.cashRegister != null && this.ticket.cashRegister._id == display.idCashRegister){
        return display;
      }
    }
    return null;
  }

  getPaymentsTypes(){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(this.ticket.cashRegister != null && this.ticket.cashRegister._id == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }


  getCashRegisters(){
    var cashRegisters = [];
    for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
      var cashRegister = this.getLicense().modelCashRegisters[i];
      if(this.ticket.place!=null && cashRegister.test == this.isTestMode() && cashRegister.places.indexOf(this.ticket.place.id) > -1){
        cashRegisters.push(cashRegister);
      }
    }
    return cashRegisters;
  }

  getTvaList():any[]{
    var tvaList = [];
    if(this.userService.haveLicense()){
      tvaList = this.getLicense().tvaList;
    }
    return tvaList;
  }

  getTvaById(idTva){
    var tvaList = this.getTvaList();
    for(var i = 0; i < tvaList.length; i++){
      if(tvaList[i]._id == idTva){
        return tvaList[i];
      }
    }
    return null;
  }

  getTvaByValue(value){
    return this.getTvaList().find(element => {
      if(element.value == value) return element;
    });
  }

  getIdCashRegistersByIdUser(idUser){
    var cashRegisters = [];
    for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
      var cashRegister = this.getLicense().modelCashRegisters[i];
      if(cashRegister.test == this.isTestMode() && cashRegister.users.indexOf(idUser) > -1){
        cashRegisters.push(cashRegister._id);
      }
    }
    return cashRegisters;
  }

  getCashRegisterById(idCashRegisters){
    var cashRegisters = [];
    for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
      var cashRegister = this.getLicense().modelCashRegisters[i];
      if(cashRegister.test == this.isTestMode() && cashRegister._id == idCashRegisters){
        return cashRegister;
      }
    }
    return null;
  }

  getPlaceById(idPlace){
    var cashRegisters = [];
    for(var i = 0; i < this.getLicense().places.length; i++){
      var place = this.getLicense().places[i];
      if(place.id == idPlace){
        return place;
      }
    }
    return null;
  }

  getUsers(){
    var users = [];
    for(var i = 0; i < this.getLicense().modelUsers.length; i++){
      var user = this.getLicense().modelUsers[i];
      var idCashRegisters = this.getIdCashRegistersByIdUser(user._id);
      if(this.ticket.cashRegister!=null && idCashRegisters.indexOf(this.ticket.cashRegister._id) > -1){
        users.push(user);
      }
    }
    return users;
  }

  getUserById(idUser){
    for(var i = 0; i < this.getLicense().modelUsers.length; i++){
      var user = this.getLicense().modelUsers[i];
      if(user._id == idUser){
        return user;
      }
    }
    return null;
  }

  getProductById(idProduct){
    var products = this.getProducts();
    for(var i = 0; i < products.length; i++){
      var product = products[i];
      if(product._id == idProduct){
        return product;
      }
    }
    return null;
  }

  clearTime(date:Date){
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  isCashRegisterIsOpened(){
    return this.isCashRegisterIsOpenedByCashRegister(this.ticket.cashRegister);
  }

  isCashRegisterIsOpenedByCashRegister(cashRegister){
    if(cashRegister == null) return false;
    return cashRegister.openDate != null && (cashRegister.closeDate == null ||
      new Date(cashRegister.openDate).getTime() > new Date(cashRegister.closeDate).getTime());
  }

  isNeedClosingOutCashRegister(){
    return this.isCashRegisterIsOpened() && this.clearTime(new Date(this.ticket.date)).getTime() > this.clearTime(new Date(this.ticket.cashRegister.openDate)).getTime();
  }

  isAlreadyCloseDate(){
    return (this.ticket.cashRegister.closeDate == null || this.ticket.date.getTime() <= new Date(this.ticket.cashRegister.closeDate).getTime());
  }

  isAlreadyCloseDateWithOpenDate(){
    return (this.ticket.cashRegister.closeDate == null || this.ticket.date.getTime() < new Date(this.ticket.cashRegister.openDate).getTime());
  }


  todayIsAlreadyCloseDate(){
    return (this.ticket.cashRegister.closeDate == null || Date.now() <= new Date(this.ticket.cashRegister.closeDate).getTime());
  }

  isNeedClosingOutMonthCashRegister(){
    if(this.ticket.cashRegister == null) return false;
    var lastMonthCloseDate = new Date(this.ticket.cashRegister.lastMonthCloseDate);
    return lastMonthCloseDate.getMonth() != this.ticket.date.getMonth();
  }

  isNeedClosingOutYearCashRegister(){
    var license = this.getLicense();
    if(license){
      var endDate = new Date(license.fiscalYearDates.end);
      if(license.fiscalYearDates == null || license.fiscalYearDates.end == null){
        var actual = new Date();
        endDate = new Date(actual.getFullYear(), 11, 31, 23, 59, 59, 99);
      }
      return this.ticket.date.getTime() > endDate.getTime();
    }
    return false;
  }

  isSameDateAsOpenDate(){
    if(this.ticket.cashRegister != null){
      var openDate = new Date(this.ticket.cashRegister.openDate);
      return this.ticket.date.getFullYear() == openDate.getFullYear() &&
        this.ticket.date.getMonth()== openDate.getMonth() &&
        this.ticket.date.getDate()== openDate.getDate();
    }
    return false;
  }

  isPassedDate(){
    return this.ticket.date.getTime() < new Date().getTime();
  }

  isFutureDate(){
    return this.ticket.date.getTime() > new Date().getTime();
  }

  addCustomer(){
    let dialogRef = this.dialog.open(CustomerDialog, {
      width: '80vw',
      height: '90%',
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && !this.addEditCustomerLaunched){
        this.addEditCustomerLaunched = true;
        this.userService.addCustomer(result, function(result){
          this.addEditCustomerLaunched = false;
          this.setCustomer(result);
          this.saveTicketsInStorage();
        }.bind(this), function(error){
          this.addEditCustomerLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    });
  }

  editCustomer(){
    if(this.ticket.isCustomerValid()){
      let dialogRef = this.dialog.open(CustomerDialog, {
        width: '80vw',
        height: '90%',
        data: this.ticket.customer
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result && !this.addEditCustomerLaunched){
          this.addEditCustomerLaunched = true;
          this.userService.editCustomer(result, function(result){
            this.addEditCustomerLaunched = false;
            this.setCustomer(result);
            this.saveTicketsInStorage();
          }.bind(this), function(error){
            this.addEditCustomerLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  setCustomer(customer){
    this.ticket.setCustomer(customer);
    this.getPaymentsTypesCustomerWithReceipts();
  }

  goCustomerDetails(){
    if(this.ticket.isCustomerValid()){
      this.navService.goCustomerDetails(this.ticket.customer._id);
    }
  }

  selectCustomer(customer){
    if(customer != null && ((typeof customer) == 'object')){
      this.searchCustomerLaunched = true;
      this.userService.getCustomer(customer._id, function(customer){
        this.searchCustomerLaunched = false;
        this.setCustomer(customer);
      }.bind(this), function(error){
        this.searchCustomerLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  displayCustomer(customer): string {
    var display = '';
    if(customer != null && ((typeof customer) == 'object')){
      display = customer.lastName + ' ' + customer.firstName;
    }
    return display;
  }

  setInputApplyValue(type, index){
    this.inputApplyValue = {type:type, index:index};
    this.padNumber = this.getInputValue() + '';
  }

  isInputApplyValue(type, index){
    return this.inputApplyValue != null && this.inputApplyValue.type == type && this.inputApplyValue.index == index;
  }

  openPadDialog(){
    let dialogRef = this.dialog.open(PadDialog, {
      data:{
        number: this.getInputValue()
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.enterPadNumberPressed(result);
      }
      this.clearPadNumber();
    });
  }

  clearPadNumber(){
    this.padNumber = '';
    this.inputApplyValue.type = '';
  }

  updatePadNumber(number){
    this.padNumber = number;
  }

  enterPadNumberPressed(number){
    if(this.inputApplyValue.type != ''){
      this.updatePadNumber(number);
      if(this.inputApplyValue.type == 'shoppingLine'){
        this.ticket.shoppingList[this.inputApplyValue.index].setQuantity(Math.floor(number * 1));
      }
      if(this.inputApplyValue.type == 'shoppingLinePrice'){
        this.ticket.shoppingList[this.inputApplyValue.index].price = Math.floor(number * 1);
      }
      else if(this.inputApplyValue.type == 'shoppingLineReduction'){
        this.ticket.shoppingList[this.inputApplyValue.index].reduction.amount = Math.floor(number * 1);
      }
      else if(this.inputApplyValue.type == 'globalReduction'){
        this.ticket.reduction.amount = Math.floor(number * 1);
      }
      else if(this.inputApplyValue.type == 'payments'){
        this.ticket.payments[this.inputApplyValue.index].amount = number * 1;
      }
      this.clearPadNumber();
      this.inputApplyValue.type = '';
    }
  }

  getInputValue(){
    if(this.inputApplyValue.type == 'shoppingLine'){
      return this.ticket.shoppingList[this.inputApplyValue.index].quantity;
    }
    if(this.inputApplyValue.type == 'shoppingLinePrice'){
      return this.ticket.shoppingList[this.inputApplyValue.index].price;
    }
    else if(this.inputApplyValue.type == 'shoppingLineReduction'){
      return  this.ticket.shoppingList[this.inputApplyValue.index].reduction.amount;
    }
    else if(this.inputApplyValue.type == 'globalReduction'){
      return this.ticket.reduction.amount;
    }
    else if(this.inputApplyValue.type == 'payments'){
      return  this.ticket.payments[this.inputApplyValue.index].amount;
    }
    return 0;
  }

  addShoppingLineAction(product){
    if(product != null){
      this.addShoppingLine(product);
      this.clearPadNumber();
      this.saveTicketsInStorage();
    }
  }

  offerShoppingLineAction(shoppingLine, index){
    if(shoppingLine != null && index!=null && shoppingLine.quantity > 0){
      var product = this.getProductById(shoppingLine._id);
      var newShoppingLine = new ShoppingLine(product, this.getTvaById(product.tva));
      newShoppingLine.price = 0;
      newShoppingLine.priceTTC = 0;
      newShoppingLine.thresholded = false;
      /*if(this.padNumber.length > 0 && ){
        newShoppingLine.setQuantity(Math.floor(Number(this.padNumber)));
      }*/
      this.ticket.addShoppingLine(newShoppingLine);
      shoppingLine.setQuantity(shoppingLine.quantity - newShoppingLine.quantity);
      if(shoppingLine.quantity == 0){
        this.ticket.deleteShoppingLine(index);
      }
      this.clearPadNumber();
      this.saveTicketsInStorage();
    }
  }

  addGroupAction(group){
    for(var i = 0; i < group.products.length; i++){
      var product = this.getProductById(group.products[i]);
      if(product != null){
        this.addShoppingLine(product);
      }
    }
    this.clearPadNumber();
    this.saveTicketsInStorage();
  }


  addShoppingLine(product, number = false){
    var quantity = 1;
    if(number !== false){
      quantity = Math.floor(Number(number));
    }
    /*else if(this.padNumber.length > 0){
      quantity = Math.floor(Number(this.padNumber));
    }*/
    var shoppingLine = null;
    var tva = this.getTvaById(product.tva);
    if(tva == null) tva = this.getTvaByValue(0);
    if(tva != null){
      shoppingLine = new ShoppingLine(product, tva, quantity);
      this.ticket.addShoppingLine(shoppingLine);
    }
    return shoppingLine;
  }

  addNewShoppingLine(product, number = false){
    var quantity = 1;
    if(number !== false){
      quantity = Math.floor(Number(number));
    }
    else if(this.padNumber.length > 0){
      quantity = Math.floor(Number(this.padNumber));
    }
    var shoppingLine = null;
    var tva = this.getTvaById(product.tva);
    if(tva == null) tva = this.getTvaByValue(0);
    if(tva != null){
      shoppingLine = new ShoppingLine(product, tva, quantity);
      this.ticket.addNewShoppingLine(shoppingLine);
    }
    return shoppingLine;
  }

  newLineShoppingLine(shoppingLine){
    var product = this.getProductById(shoppingLine._id);
    if(product != null){
     var newShoppingLine =  this.addNewShoppingLine(product, shoppingLine.quantity);
     newShoppingLine.reduction = JSON.parse(JSON.stringify(shoppingLine.reduction));
     newShoppingLine.initReductionChecked()
    }
  }

  multiplicateShoppingLine(ticket, index){
    let dialogRef = this.dialog.open(PromptDialog, {
      width: '350px',
      data: {
        title:'Multiplier le tarif du produit ?',
        text: 'Indiquez le facteur de multiplication ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        var input = result.note;
        if(!isNaN(input)){
          var shoppingLine = ticket.shoppingList[index];
          ticket.shoppingList[index] = new ShoppingLine(this.multiplicateByHours(shoppingLine, input), shoppingLine.tva, shoppingLine.quantity);
        }
        else {
          let snackBarRef = this.snackBar.open('Ceci n\'est pas un nombre valide !', 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }
      }
    });
  }

  changeFreeWord(shoppingLine){
    let dialogRef = this.dialog.open(PromptDialog, {
      width: '350px',
      data: {
        title:'Changer la mention ?',
        text: 'Indiquer le nom de la nouvelle mention (ex: offert, cadeau, déjà payé) ?',
        default: shoppingLine.freeWord
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        shoppingLine.freeWord = result.note;
      }
    });
  }

  alreadyPaymentType(type){
    return this.ticket.getPaymentByType(type) != null;
  }

  addPaymentWithPrice(type, price){
    if(this.ticket.idBill != null){
      price -= this.alreadyPaidInBill();
      price = Math.round(price * 100) / 100;
    }
    this.ticket.payments.push(new Payment(type, price));
  }

  addPayment(type){
    if(true/*!this.alreadyPaymentType(type)*/){
      var price = this.ticket.getRemainingPayment();
      this.addPaymentWithPrice(type, price);
    }
  }

  getTypeCustomer(type){
    var result = 'customer';
    if(type != null && type.length > 0) result += '_' + type;
    return result;
  }

  getPaymentsTypesCustomerWithReceipts(){
    var typePayment = [];
    if(this.ticket != null && this.ticket.customer != null && this.ticket.customer.tickets != null){
      for(var i = 0; i < this.ticket.customer.tickets.length; i++){
        var ticket = this.ticket.customer.tickets[i];
        for(var j = 0; j < ticket.payments.length; j++){
          var payment = ticket.payments[j];
          var type = payment.type;
          if(type.indexOf('customer') != -1 || type.indexOf('credit') != -1 || ticket.type == 'receipt'){
            if(type.indexOf('customer') != -1){
              var tokens = type.split('_');
              if(tokens.length == 2){
                type = tokens[1];
              }
            }
            if(type.indexOf('credit') != -1){
              type = '';
            }
            var amount = payment.amount * 1;
            if(ticket.type != 'receipt') amount *= -1;
            if(!typePayment.find(element => element.type === type)){
              typePayment.push({type: type, amount:amount});
            }
            else {
              typePayment.map(function(element){
                if(element.type == type){
                  element.amount += amount;
                }
              });
            }
          }
        }
      }
      var result = [];
      typePayment.map(function(element){
        if(element.amount > 0){
          result.push(element);
        }
      });
      this.typePaymentsCustomer = result;
    }
    this.typePaymentsCustomer = typePayment;
  }

  formatTypePayment(typePayment){
    return Payment.globalFormatTypeCustomerPayment(this.getPaymentsTypes().anotherPayments, typePayment, 1);
  }

  addPaymentCustomer(typePayment = '', amount = 0){
    var typeCustomer = this.getTypeCustomer(typePayment);
    if(!this.alreadyPaymentType(typeCustomer)){
      var credits = this.ticket.customer.credits;
      if(this.isTestMode()) credits = this.ticket.customer.creditsTest;
      if(amount > 0) credits = amount;
      var price = this.ticket.getRemainingPayment();
      if(price == 0 && amount > 0) price = amount;
      if(price <= 0) price = 0;
      if(price > credits) price = credits;
      this.ticket.payments.push(new Payment(typeCustomer, price));
    }
  }

  toPaymentClass(payment){
    return new Payment(payment.type, payment.amount);
  }

  alreadyPaidInBill(){
    var total = 0;
    if(this.currentBill != null){
      for(var i = 0; i < this.currentBill.tickets.length; i++){
        var ticket = this.currentBill.tickets[i];
        for(var j = 0; j < ticket.payments.length; j++){
          var payment = this.toPaymentClass(ticket.payments[j]);
          if(payment.type != 'defer') total += payment.getAmount() * 1;
        }
      }
    }
    return total;
  }



  /**
   * calculate expiration date
   */
  calculateExpirationDate(){
    this.expirationDate = this.calculateDateWithDays(this.chooseOfExpirationDate);
  }

  /**
   * calculate the date, add days on actual date
   */
  calculateDateWithDays(days){
    var date = new Date();
    date.setDate(date.getDate() + days);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(99);
    return date;
  }

  getLastDayOfMonth(){
    var actualDate = new Date();
    return new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0, 23, 59, 59, 99);
  }

  getRemainingDays(){
    var actualDate = new Date();
    var lastDayOfMonth = this.getLastDayOfMonth();
    return lastDayOfMonth.getDate() - actualDate.getDate();
  }

  openCashRegister(){
    if(!this.closingOutLaunched){
      var date = new Date(this.ticket.getDate());
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Ouvrir la caisse ' + this.ticket.cashRegister.name + ' ?',
          text: 'Voulez-vous vraiment ouvrir la journée du ' + this.datePipe.transform(date, 'fullDate', '', 'fr') +' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.closingOutLaunched = true;
          this.userService.openCashRegister(this.ticket.cashRegister._id, date, function(data){
            this.closingOutLaunched = false;
            this.createNewTicket();
            this.ticket.date = new Date(data.cashRegister.openDate);
            let dialogRef = this.dialog.open(ConfirmDialog, {
              width: '350px',
              data: {
                title:'Vérifier fond de caisse ?',
                text: 'Voulez-vous faire une vérification du fond de caisse ?'
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result){
                this.goRapportToFloat();
              }
            });
          }.bind(this), function(error){
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
            this.closingOutLaunched = false;
          }.bind(this));
        }
      });
    }
  }

  closingOutCashRegister(){
    if(!this.closingOutLaunched){
      var date = this.datePipe.transform(new Date(this.ticket.cashRegister.openDate), 'fullDate', '', 'fr');
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Clôturer la caisse ' + this.ticket.cashRegister.name + ' ?',
          text: 'Voulez-vous vraiment clôturer la journée du ' + date +' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.closingOutLaunched = true;
          this.userService.closingOutCashRegister(this.ticket.cashRegister._id, false, function(data){
            this.createNewTicket();
            this.closingOutLaunched = false;
          }.bind(this), function(error){
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
            this.closingOutLaunched = false;
          }.bind(this));
        }
      });
    }
  }

  saveTicket(){
    if(this.ticket.ticketIsOK() && !this.saveTickedLaunched){
      this.saveTickedLaunched = true;
      if(this.chooseOfExpirationDate == -1) {
        this.expirationDate = -1;
      }
      var createBill = this.createBill;
      this.userService.createTicket(this.ticket, this.createBill, this.expirationDate, function(data){
        this.saveTickedLaunched = false;
        this.userService.updateCashRegisterInLicense(data.cashRegister);
        this.deleteTicket(this.ticket.idTicketGenerated);
        this.ticketCreated = new TicketLite(data.ticket);
        this.billCreated = createBill;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.currentBill = null;
        this.saveTickedLaunched = false;
      }.bind(this));
    }
  }

  printTicket(id){
    var content = document.getElementById(id).innerHTML;
		var newWindow = window.open ('','', "menubar=yes,scrollbars=yes,resizable=yes");
		newWindow.document.open();
		newWindow.document.write("<html><head><title></title></head><body>"+content+"</body></html>");
		var arrStyleSheets = document.getElementsByTagName("link");
    for (var i = 0; i < arrStyleSheets.length; i++){
      if(arrStyleSheets[i].href.indexOf('bootstrap.min.css') == -1){
        newWindow.document.head.appendChild(arrStyleSheets[i].cloneNode(true));
      }
    }
		var arrStyle = document.getElementsByTagName("style");
		for (var i = 0; i < arrStyle.length; i++){
			newWindow.document.head.appendChild(arrStyle[i].cloneNode(true));
		}
		newWindow.document.close();
		newWindow.focus();
		setTimeout(function(){
			newWindow.print();
			newWindow.close(); },
			1000);
  }

  downloadTicket(id){
    var date = new Date();
    const input = document.getElementById(id);
    input.style.transform = input.style.webkitTransform = 'scale(0.85)';
    html2pdf(input,{
      margin:       12,
      filename:     'ticket' + this.ticketCreated.id + '.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192 },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' }
    });
  }

  getPaymentsTypesByIdCashRegister(idCashRegister){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }

  calculateAllAnotherPayments(){
    var alreadyIds = [];
    var allAnotherPayments = [];
    var cashRegisters = this.getCashRegisters();
    for(var i = 0; i < cashRegisters.length; i++){
      var cashRegister = cashRegisters[i];
      var paymentsType = this.getPaymentsTypesByIdCashRegister(cashRegister._id);
      var anotherPayments = [];
      if(paymentsType != null){
        anotherPayments = paymentsType.anotherPayments;
      }
      for(var j = 0; j < anotherPayments.length; j++){
        var anotherPayment = anotherPayments[j];
        if(alreadyIds.indexOf(anotherPayment.id) == -1){
          allAnotherPayments.push(anotherPayment);
          alreadyIds.push(anotherPayment.id);
        }
      }
    }
    return allAnotherPayments;
  }

  openBillByTicket(id){
    if(!this.loadBillLaunched && this.ticketCreated != null){
      this.loadBillLaunched = true;
      this.userService.getBillByIdTicket(this.ticketCreated._id, function(bill){
        this.loadBillLaunched = false;
        if(bill){
          var anotherPayments = this.calculateAllAnotherPayments();
          let dialogRef = this.dialog.open(BillDialog, {
            width: '80vw',
            height: '90%',
            data: {
              bill:bill,
              anotherPayments:anotherPayments,
              displayPayButton:false,
              displayCancelButton:false
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result!=null && result.action != null && result.action == 'sendBill'){
              this.sendBill(bill, result.pdf);
            }
          });
        }
      }.bind(this), function(error){
        this.loadBillLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  generateBillWithTicket(){
    if(!this.loadBillLaunched && this.ticketCreated != null && this.ticketCreated.isSimpleTicket()){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Générer une facture ?',
          text: 'Voulez-vous générer une facture à partir de ce ticket ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.loadBillLaunched = true;
          this.userService.generateBillWithTicket(this.ticketCreated._id, function(bill){
            this.loadBillLaunched = false;
            this.billCreated = true;
            var anotherPayments = this.calculateAllAnotherPayments();
            let dialogRef = this.dialog.open(BillDialog, {
              width: '80vw',
              height: '90%',
              data: {
                bill:bill,
                anotherPayments:anotherPayments,
                displayPayButton:false,
                displayCancelButton:false
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result!=null && result.action != null && result.action == 'sendBill'){
                this.sendBill(bill, result.pdf);
              }
            });
          }.bind(this), function(error){
            this.loadBillLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  sendBill(bill, pdf){
    if(bill != null){
      this.sendBillsLaunched = true;
      this.userService.sendBillByEmail(bill._id, pdf, function(data){
        let snackBarRef = this.snackBar.open('Envoyé', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.sendBillsLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.sendBillsLaunched = false;
      }.bind(this));
    }
  }
}
