import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialog, MatTableDataSource, MatSort,MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { BillDialog } from '../../components/billDialog/billDialog';
import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

import { Payment } from '../../model/ticket'

@Component({
  selector: 'page-bill',
  templateUrl: 'bill.html',
  styleUrls:['./bill.css']
})

export class BillComponent implements OnInit {

  public loadBillsLaunched = false;
  public dataBills = null;
  public totalBills = 0;
  public currentPageIndex = 1;
  public currentPageSize = 1;
  public filteredBills = {state:'', startCreationDate:null, endCreationDate:null, customer:'', expirationDate:''};


  @ViewChild('paginator', { static: false }) paginator;

  constructor(public userService: UserService, public navService:NavService, public dialog: MatDialog, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      this.userService.initializeData(function(){
        this.loadBills();
      }.bind(this), function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  loadBills($event = null){
    var pageIndex = 0;
    var pageSize = 10;
    if($event != null){
      pageIndex = $event.pageIndex;
      pageSize = $event.pageSize;
    }
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    if(!this.loadBillsLaunched){
      this.loadBillsLaunched = true;
      this.userService.getBillsByPage(pageIndex, pageSize, this.filteredBills, function(data){
        this.createDataBills(data.bills);
        this.totalBills = data.total;
        this.loadBillsLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.loadBillsLaunched = false;
      }.bind(this));
    }
  }

  reloadFilters(){
    this.filteredBills = {state:'', startCreationDate:null, endCreationDate:null, customer:'', expirationDate:''};
  }

  clearTime(date:Date){
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  setAllDayForEndDate(){
    this.filteredBills.endCreationDate.setHours(23);
    this.filteredBills.endCreationDate.setMinutes(59);
    this.filteredBills.endCreationDate.setSeconds(59);
    this.filteredBills.endCreationDate.setMilliseconds(99);
  }

  setDateEndWithDate(){
    this.filteredBills.startCreationDate = this.clearTime(new Date(this.filteredBills.startCreationDate));
    if(this.clearTime(new Date(this.filteredBills.endCreationDate)).getTime() <= this.clearTime(new Date(this.filteredBills.startCreationDate)).getTime()){
      this.filteredBills.endCreationDate = new Date(this.filteredBills.startCreationDate);
      this.setAllDayForEndDate();
    }
  }

  createDataBills(bills){
    for(var i = 0; i < bills.length; i++){
      var bill = bills[i];
      bill.needToPaid = this.needToPaid(bill) > 0;
    }
    this.dataBills = new MatTableDataSource(bills);
    //setTimeout(()=>{this.dataBills.paginator = this.paginator},250);
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }

  goCashRegister(){
    this.navService.changeRoute('cash');
  }

  getPseudo():String{
    var pseudo:String = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicenseName():String{
    var name:String = '';
    if(this.userService.haveLicense()){
      name = this.userService.getLicense().name;
    }
    return name;
  }

  getLicense(){
    return this.userService.getLicense();
  }

  getColumnsTable(){
    return [ 'id', 'date', 'customer', 'amount', 'state', 'actions'];
  }

  goCash(bill){
    if(bill != null){
      this.navService.changeRoute('cash', {bill:bill._id});
    }
  }

  toPaymentClass(payment){
    return new Payment(payment.type, payment.amount);
  }

  alreadyPaid(bill){
    var total = 0;
    for(var i = 0; i < bill.tickets.length; i++){
      var ticket = bill.tickets[i];
      for(var j = 0; j < ticket.payments.length; j++){
        var payment = this.toPaymentClass(ticket.payments[j]);
        if(payment.type != 'defer') total += payment.getAmount() * 1;
      }
    }
    return total;
  }

  needToPaid(bill){
    return bill.ticket.totalTTCPrice * 1 - this.alreadyPaid(bill);
  }

  goCancel(bill){
    if(bill != null){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Annuler la facture n°' + bill.id + ' ?',
          text: 'Voulez-vous vraiment annuler la facture n°' + bill.id + ' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
            this.navService.changeRoute('cash', {bill:bill._id, type:'cancel'});
          }
      });
    }
  }

  sendBill(bill, pdf){
    if(bill != null){
      this.loadBillsLaunched = true;
      this.userService.sendBillByEmail(bill._id, pdf, function(data){
        let snackBarRef = this.snackBar.open('Envoyé', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.loadBillsLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.loadBillsLaunched = false;
      }.bind(this));
    }
  }

  goSettingsBill(){
    this.navService.goSettingsBill();
  }

  getPaymentsTypes(idCashRegister){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(idCashRegister != null && idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }

  getPaymentsTypesByIdCashRegister(idCashRegister){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }


  getCashRegisters(){
    var cashRegisters = [];
    if(this.userService.haveLicense()){
      for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
        var cashRegister = this.getLicense().modelCashRegisters[i];
        cashRegisters.push(cashRegister);
      }
    }
    return cashRegisters;
  }

  calculateAllAnotherPayments(){
    var alreadyIds = [];
    var allAnotherPayments = [];
    var cashRegisters = this.getCashRegisters();
    for(var i = 0; i < cashRegisters.length; i++){
      var cashRegister = cashRegisters[i];
      var paymentsType = this.getPaymentsTypesByIdCashRegister(cashRegister._id);
      var anotherPayments = [];
      if(paymentsType != null){
        anotherPayments = paymentsType.anotherPayments;
      }
      for(var j = 0; j < anotherPayments.length; j++){
        var anotherPayment = anotherPayments[j];
        if(alreadyIds.indexOf(anotherPayment.id) == -1){
          allAnotherPayments.push(anotherPayment);
          alreadyIds.push(anotherPayment.id);
        }
      }
    }
    return allAnotherPayments;
  }

  openBill(bill){
    if(bill){
      var anotherPayments = this.calculateAllAnotherPayments();
      let dialogRef = this.dialog.open(BillDialog, {
        width: '80vw',
        height: '90%',
        data: {
          bill:bill,
          anotherPayments:anotherPayments,
          displayCancelButton:this.canCancellableOnlyByBill(bill)
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'payBill'){
          this.goCash(bill);
        }
        else if(result == 'cancelBill'){
          this.goCancel(bill);
        }
        else if(result != null && result.action != null && result.action == 'sendBill'){
          this.sendBill(bill, result.pdf);
        }
      });
    }
  }

  canCancellableOnlyByBill(bill){
    var cashRegisters = this.userService.getCashRegisterById(bill.ticket.idCashRegister);
    if(cashRegisters == null) return true;
    return new Date(bill.ticket.date) <= new Date(cashRegisters.closeDate);
  }

}
