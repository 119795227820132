import { Component, OnInit } from '@angular/core';

import { NgModel } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { LicenseService } from '../../services/license.service';

import { Ticket, ShoppingLine, Payment } from '../../model/ticket'
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'page-settings',
  templateUrl: 'settings.html',
  styleUrls:['./settings.css']
})

export class SettingsComponent implements OnInit {

  private subPage:string = '';
  public actionLaunched:boolean = false;

  private currentEditTva = null;
  private tvaForm = {display:false, label:'', name:'', reference:'', value:0};

  private currentCashRegister = null;
  private duplicateCashRegister = null;
  private editionMode = true;
  private display = null;
  private displayAddCategory = null;
  private displayAddProduct = null;
  private displayAddGroup = null;
  private displayChanged = false;

  private ticketEditionMode:boolean = false;
  private ticket:any = null;
  private formTicket = {logo:'', header:'', footer:'', creditNote:''};

  private paymentsType = null;
  private formMeansOfPayment = {display:false, modifyName:false, type:'custom', id:'', name:'', reference:''};
  private paymentsChanged = false;

  private billEditionMode:boolean = false;
  private formBill = {logo:'', legalNotice:'', paymentInfos:''};

  public fileToUpload: File = null;

  constructor(public userService: UserService, public navService:NavService, public licenseService:LicenseService,
    public dialog: MatDialog, private route: ActivatedRoute, public snackBar: MatSnackBar, private sanitized: DomSanitizer) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      this.userService.initializeData(function(){
        var subPage = this.route.snapshot.paramMap.get('subPage');
        var idCashRegister = this.route.snapshot.paramMap.get('idCashRegister');
        if(subPage){
          this.subPage = subPage;
          if(this.isDisplaySubPage()){
            if(idCashRegister) {
              this.currentCashRegister = this.getCashRegisterById(idCashRegister);
              this.setDisplaySubPage();
            }
            else {
              this.setChooseCashRegister();
            }
          }
          else if(this.isMeansOfPaymentSubPage()){
            if(idCashRegister) {
              this.currentCashRegister = this.getCashRegisterById(idCashRegister);
              this.setMeansOfPaymentSubPage();
            }
            else {
              this.setChooseCashRegister();
            }
          }
        }
      }.bind(this), function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }

  goProduct(){
    this.navService.changeRoute('product');
  }

  getPseudo():String{
    var pseudo:string = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicense(){
    return this.userService.getLicense();
  }

  setTvaSubPage(){ this.subPage = 'tva'; }
  setChooseCashRegister(){
    this.subPage = 'chooseCashRegister';
    var cashRegisters = this.getCashRegisters();
    if(cashRegisters.length == 1){ this.setCurrentCashRegister(cashRegisters[0]); }
  }
  setBillSubPage(){ this.subPage = 'bill'; }
  setCurrentCashRegister(cashRegister){ this.currentCashRegister = cashRegister; this.setDisplaySubPage(); }
  clearCurrentCashRegister(){ this.currentCashRegister = null; }
  setDisplaySubPage(){
    this.subPage = 'display';
    this.getColumnByCashRegister();
  }
  setTicketSubPage(){
    this.subPage = 'ticket';
    this.getTicket();
  }
  setMeansOfPaymentSubPage(){
    this.subPage = 'meansOfPayment';
    this.getPaymentsTypes();
  }

  isMainSubPage(){ return this.subPage == ''; }
  isTvaSubPage(){ return this.subPage == 'tva'; }
  isChooseCashRegister(){ return this.subPage == 'chooseCashRegister'; }
  isBillSubPage(){ return this.subPage == 'bill'; }
  haveCurrentCashRegister(){ return this.currentCashRegister != null; }
  haveProducts(){ return this.getProducts().length > 0; }
  isDisplaySubPage(){ return this.subPage == 'display'; }
  isTicketSubPage(){ return this.subPage == 'ticket'; }
  isMeansOfPaymentSubPage(){ return this.subPage == 'meansOfPayment'; }

  synchronizeResaOnlineVatList(){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Synchroniser les TVA ?',
          text: 'Voulez-vous récupérer les TVA depuis RESA Online <br /><i>Cela créera les TVA manquantes et modifira les noms des TVA existantes</i> ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.synchronizeResaOnlineVatList(this.getLicense().idLicense, function(subLicense){
            this.actionLaunched = false;
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  getColumnsTvaTable(){
    return ['label', 'name', 'value', 'modify', 'delete'];
  }

  getTvaList(){
    var tvaList = [];
    if(this.userService.haveLicense()){
      tvaList = this.getLicense().tvaList;
    }
    return tvaList;
  }

  launchEditTva(tva){
    this.currentEditTva = tva;
    if(tva == null){
      this.tvaForm = {display:true, label:'', name:'', reference:'', value:0};
    }
    else {
      this.tvaForm = {display:true, label:tva.label, name:tva.name, reference:tva.reference,value:tva.value};
    }
  }

  closeEditTva(){
    this.tvaForm = {display:false, label:'', name:'', reference:'', value:0};
  }

  isTVAValid(){
    return this.tvaForm.label.length > 0 && this.tvaForm.name.length > 0 && this.tvaForm.value >= 0;
  }

  addTva(){
    if(this.isTVAValid() && !this.actionLaunched){
      this.actionLaunched = true;
      var tva = JSON.parse(JSON.stringify(this.tvaForm));
      this.licenseService.addTva(this.getLicense().idLicense, tva, function(){
        this.actionLaunched = false;
        this.closeEditTva();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }


  editTva(){
    if(this.isTVAValid() && !this.actionLaunched){
      this.actionLaunched = true;
      var tva = JSON.parse(JSON.stringify(this.currentEditTva));
      tva.label = this.tvaForm.label;
      tva.name = this.tvaForm.name;
      tva.value = this.tvaForm.value;
      tva.reference = this.tvaForm.reference;
      this.licenseService.editTva(this.getLicense().idLicense, tva, function(){
        this.actionLaunched = false;
        this.closeEditTva();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteTva(tva){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer la TVA '+tva.name+' ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.deleteTva(this.getLicense().idLicense, tva.label, function(subLicense){
            this.actionLaunched = false;
            this.closeEditTva();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  copySettingsDisplaysFromCashRegister(cashRegister){
    if(cashRegister != null){
      var displays = this.userService.getLicense().displays;
      for(var i = 0; i < displays.length; i++){
        var display = displays[i];
        if(display.idCashRegister == cashRegister._id) {
          this.display = JSON.parse(JSON.stringify(display));
        }
      }
      if(cashRegister._id == this.currentCashRegister._id){
        this.setDisplayChanged(false);
      }
      else {
        this.display.idCashRegister = this.currentCashRegister._id;
        this.setDisplayChanged(true);
      }
      this.duplicateCashRegister = null;
    }
  }

  setDisplayChanged(displayChanged){
    this.displayChanged = displayChanged;
  }


  getCategories():any[]{
    var categories = [];
    if(this.userService.haveLicense()){
      categories = this.userService.getLicense().categories;
    }
    return categories;
  }

  getProducts():any[]{
    var products = [];
    if(this.userService.haveLicense()){
      products = this.userService.getProducts();
    }
    return products;
  }

  getGroups():any[]{
    var products = [];
    if(this.userService.haveLicense()){
      products = this.userService.getLicense().groups;
    }
    return products;
  }

  getCategoryById(idCategory){
    if(idCategory != null){
      var allCategories = this.getCategories();
      for(var i = 0; i < allCategories.length; i++){
        if(allCategories[i]._id == idCategory){
          return allCategories[i];
        }
      }
    }
    return null;
  }

  getGroupById(idGroup){
    if(idGroup != null){
      var allGroups = this.getGroups();
      for(var i = 0; i < allGroups.length; i++){
        if(allGroups[i]._id == idGroup){
          return allGroups[i];
        }
      }
    }
    return null;
  }

  getCategoriesWithParent(idCategory){
    var categories = [];
    if(idCategory != null){
      var allCategories = this.getCategories();
      for(var i = 0; i < allCategories.length; i++){
        var category =  allCategories[i];
        if(category.parent == idCategory){
          categories.push(category);
        }
      }
    }
    return categories;
  }

  getProductsWithIdCategory(idCategory){
    var products = [];
    if(idCategory != null){
      var allProducts = this.getProducts();
      for(var i = 0; i < allProducts.length; i++){
        var product =  allProducts[i];
        if(product.categories.indexOf(idCategory) != -1){
          products.push(product);
        }
      }
    }
    return products;
  }

  getGroupsWithIdCategory(idCategory){
    var groups = [];
    if(idCategory != null){
      var allGroups = this.getGroups();
      for(var i = 0; i < allGroups.length; i++){
        var group =  allGroups[i];
        if(group.categories.indexOf(idCategory) != -1){
          groups.push(group);
        }
      }
    }
    return groups;
  }

  getParent(category){
    var parent = '';
    if(category != null && category.parent != null){
      var parentCategory = this.getCategoryById(category.parent);
      if(parentCategory != null) {
        if(parentCategory.parent != null){
          parent += ' > ';
        }
        parent = this.getParent(parentCategory) + parent + parentCategory.name;
      }
    }
    return parent;
  }

  getCompressParent(text){
    var compress = 15;
    if(text.length <= compress * 2) return text;
    return text.substring(0, compress) + ' ... ' + text.substring(text.length - compress, text.length);
  }

  editSubLicense(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var subLicense = this.userService.getLicense();
      for(var i = 0; i < subLicense.displays.length; i++){
        var display = subLicense.displays[i];
        if(display.idCashRegister == this.display.idCashRegister){
          subLicense.displays[i] = this.display;
        }
      }
      this.licenseService.editSubLicense(this.userService.getLicense().idLicense, subLicense, function(data){
        this.actionLaunched = false;
        this.setDisplayChanged(false);
        let snackBarRef = this.snackBar.open('Modifications sauvegardées', 'OK', {
          duration: 2000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  getCashRegisters(){
    return this.userService.getLicense().modelCashRegisters;
  }

  getCashRegistersWithoutCashRegister(cashRegister){
    var cashRegisters = [];
    if(cashRegister != null){
      for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
        var cashRegisterLocal = this.getLicense().modelCashRegisters[i];
        if(cashRegisterLocal._id != cashRegister._id){
          cashRegisters.push(cashRegisterLocal);
        }
      }
    }
    return cashRegisters;
  }

  getCashRegisterById(idCashRegisters){
    var cashRegisters = [];
    for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
      var cashRegister = this.getLicense().modelCashRegisters[i];
      if(cashRegister._id == idCashRegisters){
        return cashRegister;
      }
    }
    return null;
  }


  getColumnByCashRegister(){
    var subLicense = this.userService.getLicense();
    var displays = subLicense.displays;
    for(var i = 0; i < displays.length; i++){
      var display = displays[i];
      if(display.idCashRegister == this.currentCashRegister._id) {
        this.display = JSON.parse(JSON.stringify(display));
      }
    }
    if(this.display == null){
      this.display = {
        idCashRegister: this.currentCashRegister._id,
        columns:1,
        order:{
          categories :{pos:1, newPos:1, visible:true},
          products:{pos:2, newPos:2, visible:true},
          groups :{pos:3, newPos:3, visible:true}
        },
        categories:[],
        products:[],
        groups:[]
      };
      subLicense.displays.push(this.display);
    }
  }

  addCategoryToDisplay(){
    if(this.displayAddCategory != null){
      this.addCategoryInCategory(this.display.categories, this.displayAddCategory);
      this.displayAddCategory = null;
      this.setDisplayChanged(true);
    }
  }

  addCategoryInCategory(array, idCategory){
    var line = {pos: array.length + 1, category:idCategory, visible:true, order:{
      categories :{pos:1, newPos:1, visible:true}, products:{pos:2, newPos:2,visible:true}, groups :{pos:3, newPos:3, visible:true}
    }, categories:[], products:[], groups:[]};
    var products = this.getProductsWithIdCategory(idCategory);
    for(var i = 0; i < products.length; i++){
      var product = products[i];
      line.products.push({pos: line.products.length + 1, product:product._id, visible:true})
    }
    var groups = this.getGroupsWithIdCategory(idCategory);
    for(var i = 0; i < groups.length; i++){
      var group = groups[i];
      line.groups.push({pos: line.groups.length + 1, group:group._id, visible:true})
    }
    var categories = this.getCategoriesWithParent(idCategory);
    for(var i = 0; i < categories.length; i++){
      var category = categories[i];
      this.addCategoryInCategory(line.categories, category._id);
    }
    array.push(line);
  }

  addProductToDisplay(){
    if(this.displayAddProduct != null){
      this.display.products.push({pos: this.display.products.length + 1, product:this.displayAddProduct, visible:true})
      this.displayAddProduct = null;
      this.setDisplayChanged(true);
    }
  }

  addGroupToDisplay(){
    if(this.displayAddGroup != null){
      this.display.groups.push({pos: this.display.products.length + 1, group:this.displayAddGroup, visible:true})
      this.displayAddGroup = null;
      this.setDisplayChanged(true);
    }
  }

  getTicket(){
    if(this.userService.haveLicense()){
      var subLicense = this.getLicense();
      if(subLicense.tickets == null){ subLicense.tickets == []; }
      var tickets = subLicense.tickets;
      for(var i = 0; i < tickets.length; i++){
        var ticket = tickets[i];
        if(ticket.idCashRegister == this.currentCashRegister._id){
          this.ticket = ticket;
        }
      }
      if(this.ticket == null){
        this.ticket = {
          idCashRegister:this.currentCashRegister._id,
          header:'',
          footer:'',
          creditNote:'',
        }
        tickets.push(this.ticket);
      }
    }
  }

  handleTicketImageInput(event) {
   this.fileToUpload = event.target.files.item(0);
   if(!this.actionLaunched && this.fileToUpload != null){
     this.actionLaunched = true;
     const formData: FormData = new FormData();
     formData.append('idLicense', this.userService.getLicense().idLicense);
     formData.append('idCashRegister', this.currentCashRegister._id);
     formData.append('image', this.fileToUpload, this.fileToUpload.name);
     console.log(formData);
     this.licenseService.uploadTicketImage(formData, function(data){
       this.actionLaunched = false;
       console.log(this.fileToUpload.name.substring(0, this.fileToUpload.name.length -4));
       event.target.value = '';
       this.getTicket();
       let snackBarRef = this.snackBar.open('Modifications sauvegardées', 'OK', {
         duration: 8000,
       });
       snackBarRef.onAction().subscribe(() => {
         snackBarRef.dismiss();
       });
     }.bind(this), function(error){
       this.actionLaunched = false;
       event.target.value = '';
       let snackBarRef = this.snackBar.open(error, 'OK', {
         duration: 8000,
       });
       snackBarRef.onAction().subscribe(() => {
         snackBarRef.dismiss();
       });
     }.bind(this));
   }
 }

  modifyTicket(){
    this.ticketEditionMode = true;
    if(this.ticket != null){
      if(this.ticket.logo) this.formTicket.logo = this.ticket.logo;
      if(this.ticket.header) this.formTicket.header = this.ticket.header;
      if(this.ticket.footer) this.formTicket.footer = this.ticket.footer;
      if(this.ticket.creditNote) this.formTicket.creditNote = this.ticket.creditNote;
    }
  }
  cancelModifyTicket(){ this.ticketEditionMode = false; }

  copySettingsTicketsFromCashRegister(cashRegister){
    var subLicense = this.getLicense();
    if(subLicense.tickets != null && cashRegister!=null){
      var tickets = subLicense.tickets;
      for(var i = 0; i < tickets.length; i++){
        var ticket = tickets[i];
        if(ticket.idCashRegister == cashRegister._id){
          this.formTicket = JSON.parse(JSON.stringify(ticket));
        }
      }
      this.duplicateCashRegister = null;
    }
  }

  saveTicket(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var newTicket = JSON.parse(JSON.stringify(this.ticket));
      newTicket.logo = this.formTicket.logo;
      newTicket.header = this.formTicket.header;
      newTicket.footer = this.formTicket.footer;
      newTicket.creditNote = this.formTicket.creditNote;
      this.ticket = newTicket;
      var subLicense = this.userService.getLicense();
      for(var i = 0; i < subLicense.tickets.length; i++){
        if(subLicense.tickets[i].idCashRegister == this.currentCashRegister._id){
          subLicense.tickets[i] = newTicket;
        }
      }
      this.licenseService.editSubLicense(this.userService.getLicense().idLicense, subLicense, function(data){
        this.actionLaunched = false;
        this.currentCashRegister = this.getCashRegisterById(this.currentCashRegister._id);
        this.cancelModifyTicket();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  copySettingsPaymentsFromCashRegister(cashRegister){
    var subLicense = this.getLicense();
    if(subLicense.paymentsTypes != null && cashRegister != null){
      var paymentsTypes = subLicense.paymentsTypes;
      for(var i = 0; i < paymentsTypes.length; i++){
        var paymentsType = paymentsTypes[i];
        if(paymentsType.idCashRegister == cashRegister._id){
          this.paymentsType = JSON.parse(JSON.stringify(paymentsType));
        }
      }
      if(cashRegister._id == this.currentCashRegister._id){
        this.setPaymentChanged(false);
      }
      else {
        this.paymentsType.idCashRegister = this.currentCashRegister._id;
        this.setPaymentChanged(true);
      }
      this.duplicateCashRegister = null;
    }
  }

  getPaymentsTypes(){
    if(this.userService.haveLicense()){
      var subLicense = this.getLicense();
      if(subLicense.paymentsTypes == null){ subLicense.paymentsTypes == []; }
      var paymentsTypes = subLicense.paymentsTypes;
      for(var i = 0; i < paymentsTypes.length; i++){
        var paymentsType = paymentsTypes[i];
        if(paymentsType.idCashRegister == this.currentCashRegister._id){
          this.paymentsType = JSON.parse(JSON.stringify(paymentsType));
        }
      }
      if(this.paymentsType == null){
        this.paymentsType = {
          idCashRegister:this.currentCashRegister._id,
          anotherPayments:[],
          visibilities:{}
        }
        this.paymentsType.visibilities['card'] = true;
        this.paymentsType.visibilities['cash'] = true;
        this.paymentsType.visibilities['check'] = true;
        subLicense.paymentsTypes.push(this.paymentsType);
      }
    }
  }

  getAnotherPaymentById(id){
    for(var i = 0; i < this.paymentsType.anotherPayments.length; i++){
      var payment = this.paymentsType.anotherPayments[i];
      if(payment.id == id){
        return payment;
      }
    }
    return null;
  }

  getReferencePayment(id){
    var payment = this.paymentsType.payments.find(element => element.id == id);
    if(payment) return payment.reference;
    return '';
  }

  setPaymentChanged(paymentsChanged){
    this.paymentsChanged = paymentsChanged;
  }

  addMeansOfPayment(){
    this.formMeansOfPayment = {display:true, modifyName:true, type:'custom', id:'', name:'', reference:''};
  }

  editMeansOfPayment(payment, modifyName, type){
    this.formMeansOfPayment = {display:true, modifyName:modifyName, type:type, id:payment.id, name:payment.name, reference:payment.reference};
  }

  cancelMeansOfPayment(){
    this.formMeansOfPayment = {display:false, modifyName:false, type:'custom', id:'', name:'', reference:''};
  }

  validMeansOfPayment(){
    if(this.formMeansOfPayment.type == 'fix' || this.formMeansOfPayment.name.length > 0){
      var payments = this.paymentsType.anotherPayments;
      if(this.formMeansOfPayment.type == 'fix'){
        payments = this.paymentsType.payments;
      }
      if(this.formMeansOfPayment.id == ''){
        var index = 1;
        var id = 'payment_' + index;
        while(this.getAnotherPaymentById(id)!=null){
          index++;
          id = 'payment_' + index;
        }
        payments.push({
          id:id,
          name:this.formMeansOfPayment.name,
          reference:this.formMeansOfPayment.reference
        });
        this.paymentsType.visibilities[id] = true;
      }
      else {
        var found = false;
        payments.map(function(element){
          if(element.id == this.formMeansOfPayment.id){
            found = true;
            element.reference  = this.formMeansOfPayment.reference;
          }
        }.bind(this));
        if(!found){
          payments.push({
            id:this.formMeansOfPayment.id,
            reference:this.formMeansOfPayment.reference
          });
        }
      }
      console.log(payments);
      this.cancelMeansOfPayment();
      this.setPaymentChanged(true);
    }
  }


  canDeleteMeansOfPayment(index){
    var canDelete = false;
    if(this.currentCashRegister != null){
      canDelete = true;
      var typePayments = this.paymentsType.anotherPayments[index];
      for(var i = 0; i < this.currentCashRegister.payments.length; i++){
        var payments = this.currentCashRegister.payments[i];
        canDelete = canDelete && (payments.type != typePayments.id);
      }
    }
    return canDelete;
  }

  deleteMeansOfPayment(index){
    if(this.canDeleteMeansOfPayment(index)){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '400px',
        data: {
          title:'Supprimer le moyen de paiement ?',
          text: 'Voulez-vous vraiment supprimer le moyen de paiement ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.paymentsType.anotherPayments.splice(index, 1);
          this.setPaymentChanged(true);
        }
      });
    }
  }

  saveMeansOfPayment(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var subLicense = this.userService.getLicense();
      for(var i = 0; i < subLicense.paymentsTypes.length; i++){
        if(subLicense.paymentsTypes[i].idCashRegister == this.currentCashRegister._id){
          subLicense.paymentsTypes[i] = this.paymentsType;
        }
      }
      this.licenseService.editSubLicense(this.userService.getLicense().idLicense, subLicense, function(data){
        this.actionLaunched = false;
        this.setPaymentChanged(false);
        this.currentCashRegister = this.getCashRegisterById(this.currentCashRegister._id);
        let snackBarRef = this.snackBar.open('Modifications sauvegardées', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  /*******
    Bill
   *******/

   goAdministration(){
     this.navService.changeRoute('admin');
   }

   trustHtml(value){
     return this.sanitized.bypassSecurityTrustHtml(value);
   }

   handleFileInput(event) {
    console.log(event);
    this.fileToUpload = event.target.files.item(0);
    if(!this.actionLaunched && this.fileToUpload != null){
      this.actionLaunched = true;
      const formData: FormData = new FormData();
      formData.append('idLicense', this.userService.getLicense().idLicense);
      formData.append('image', this.fileToUpload, this.fileToUpload.name);
      this.licenseService.uploadBillImage(formData, function(data){
        this.actionLaunched = false;
        event.target.value = '';
        let snackBarRef = this.snackBar.open('Modifications sauvegardées', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this), function(error){
        this.actionLaunched = false;
        event.target.value = '';
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }


  modifyBillSettings(){
    this.billEditionMode = true;
    this.formBill.logo = this.getLicense().bill.logo;
    this.formBill.legalNotice = this.getLicense().bill.legalNotice;
    this.formBill.paymentInfos = this.getLicense().bill.paymentInfos;
  }
  cancelBillSettings(){ this.billEditionMode = false; }

  saveBillSettings(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var subLicense = this.userService.getLicense();
      subLicense.bill.logo = this.formBill.logo;
      subLicense.bill.legalNotice = this.formBill.legalNotice;
      subLicense.bill.paymentInfos = this.formBill.paymentInfos;
      this.licenseService.editSubLicense(this.userService.getLicense().idLicense, subLicense, function(data){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open('Modifications sauvegardées', 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.cancelBillSettings();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }



}
