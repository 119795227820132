import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'alert-dialog',
  templateUrl: 'alertDialog.html',
  styleUrls:['./alertDialog.css']
})


export class AlertDialog {

  /**
   *
   */
  constructor(public dialogRef: MatDialogRef<AlertDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  closeDialog(){
    this.dialogRef.close();
  }
}
