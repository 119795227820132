import { ViewChildren, QueryList, Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { DecimalPipe, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts'

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { LicenseService } from '../../services/license.service';

import { TicketLite } from '../../model/ticket'

@Component({
  selector: 'page-stats',
  templateUrl: 'stats.html',
  styleUrls:['./stats.css']
})

export class StatsComponent {

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  public loadStatistics:boolean = false;
  private startDate = new Date();
  private endDate = new Date();
  public byMonths:boolean = false;
  private barChartOptions:any = {
    responsive: true,
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }]
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      callbacks: {

      }
    },
  };

  private abscissa = [];
  public selects = {product:null, group: null, category: null, cashRegister:null};
  public filters = {products:[], groups:[], categories:[], cashRegisters:[]};
  private subPage = 'products';
  public nextPeriod = {startDate: new Date(), endDate:new Date()};


  private tickets:TicketLite[][] = [[]];
  private periods = [{startDate: new Date(), endDate:new Date()}];
  private barChartLabels:string[][] = [["01 mai 2018", "02 mai 2018", "03 mai 2018", "04 mai 2018"]];
  private barChartData:any[][] = [[{data: [0, 0, 0, 0], label: 'Series A'}]];
  private barChartColors:any[][] = [[{backgroundColor:'rgba(148,159,177,0.2)'}]];
  public statistics = [{nbSales:0, meanBasket:0, totalTTC:0, payments:[]}];


  constructor(public userService: UserService, public navService:NavService,
    public licenseService:LicenseService, public dialog: MatDialog,
    private route: ActivatedRoute, private datePipe: DatePipe, private decimalPipe: DecimalPipe, public snackBar: MatSnackBar) {
      this.barChartOptions.tooltips.displayColors = false;
      this.barChartOptions.tooltips.callbacks = {
        label: function(tooltipItems, data) {
          var localTotalTTC = data.datasets[tooltipItems.datasetIndex].localTotalTTC[tooltipItems.index];
          var purcentNbSales = data.datasets[tooltipItems.datasetIndex].purcentNbSales[tooltipItems.index];
          var purcentTTC = data.datasets[tooltipItems.datasetIndex].purcentTTC[tooltipItems.index];

          var totalQuantities = data.datasets[tooltipItems.datasetIndex].totalQuantities[tooltipItems.index];
          var nbSales = data.datasets[tooltipItems.datasetIndex].nbSales[tooltipItems.index];
          var meanBasket = data.datasets[tooltipItems.datasetIndex].meansBasket[tooltipItems.index];
          var totalTTC = data.datasets[tooltipItems.datasetIndex].totalsTTC[tooltipItems.index];

          var label = [
            data.datasets[tooltipItems.datasetIndex].label,
            'Quantité : ' + tooltipItems.yLabel,
            'CA : ' + this.decimalPipe.transform(localTotalTTC, '1.2-2','fr') + '€',
            '% des ventes : ' + purcentNbSales + '%',
            '% CA : ' + purcentTTC + '%',
            '',
            'Total : ',
            'Nb de produits : ' + totalQuantities,
            'Nb de ventes : ' + nbSales,
            'Panier moyen : ' + this.decimalPipe.transform(meanBasket, '1.2-2','fr') + '€',
            'CA : ' + this.decimalPipe.transform(totalTTC, '1.2-2','fr') + '€'
          ];
          return label;
        }.bind(this)
      }
    }

    ngOnInit(): void {
      if(!this.userService.isConnected()){
        this.navService.toLoginPage();
      }
      else {
        this.userService.initializeData(function(){

        }.bind(this), function(error){
          this.navService.toLoginPage();
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    }

    getPseudo():String{
      var pseudo:String = '';
      if(this.userService.isConnected()){
        pseudo = this.userService.getUser().pseudo;
      }
      return pseudo;
    }

    goMenu(){
      this.navService.changeRoute('menu');
    }

    getPaymentsTypesByIdCashRegister(idCashRegister){
      var license = this.userService.getLicense();
      for(var i = 0; i < license.paymentsTypes.length; i++){
        var paymentsType = license.paymentsTypes[i];
        if(idCashRegister == paymentsType.idCashRegister){
          return paymentsType;
        }
      }
      return null;
    }


    getCashRegisters(){
      var cashRegisters = [];
      if(this.userService.haveLicense()){
        for(var i = 0; i < this.userService.getLicense().modelCashRegisters.length; i++){
          var cashRegister = this.userService.getLicense().modelCashRegisters[i];
          cashRegisters.push(cashRegister);
        }
      }
      return cashRegisters;
    }

    calculateAllAnotherPayments(){
      var alreadyIds = [];
      var allAnotherPayments = [];
      var cashRegisters = this.getCashRegisters();
      for(var i = 0; i < cashRegisters.length; i++){
        var cashRegister = cashRegisters[i];
        var paymentsType = this.getPaymentsTypesByIdCashRegister(cashRegister._id);
        var anotherPayments = [];
        if(paymentsType != null){
          anotherPayments = paymentsType.anotherPayments;
        }
        for(var j = 0; j < anotherPayments.length; j++){
          var anotherPayment = anotherPayments[j];
          if(alreadyIds.indexOf(anotherPayment.id) == -1){
            allAnotherPayments.push(anotherPayment);
            alreadyIds.push(anotherPayment.id);
          }
        }
      }
      return allAnotherPayments;
    }

    reloadChart(index) {
      var chart = this.charts.toArray()[index];
      if (chart !== undefined) {
        chart.chart.destroy();
        //chart.chart = null;

        chart.datasets = this.barChartData[index];
        chart.labels = this.barChartLabels[index];
        chart.colors = this.barChartColors[index];
        chart.ngOnInit();
      }
    }


    getProductById(idProduct, products){
      for(var i = 0; i < products.length; i++){
        var localProduct = products[i];
        if(localProduct._id == idProduct){
          return localProduct;
        }
      }
      return null;
    }

    getGroupById(idGroup, groups){
      for(var i = 0; i < groups.length; i++){
        var local = groups[i];
        if(local._id == idGroup){
          return local;
        }
      }
      return null;
    }

    getCategoryById(idCategory, categories){
      for(var i = 0; i < categories.length; i++){
        var local = categories[i];
        if(local._id == idCategory){
          return local;
        }
      }
      return null;
    }

    getCashRegisterById(idCashRegister, cashRegisters){
      for(var i = 0; i < cashRegisters.length; i++){
        var local = cashRegisters[i];
        if(local._id == idCashRegister){
          return local;
        }
      }
      return null;
    }

    addProductInFilter(product){
      if(product != null){
        this.filters.products.push(product);
        this.selects.product = null;
      }
    }

    deleteProductInFilter(index){
      this.filters.products.splice(index, 1);
    }

    getProducts(){
      return this.userService.getProducts();
    }

    addGroupInFilter(group){
      if(group != null){
        this.filters.groups.push(group);
        this.selects.group = null;
      }
    }

    deleteGroupInFilter(index){
      this.filters.groups.splice(index, 1);
    }


    getGroups(){
      var groups = [];
      if(this.userService.haveLicense()){
        groups = this.userService.getLicense().groups;
      }
      return groups;
    }

    addCategoryInFilter(category){
      if(category != null){
        this.filters.categories.push(category);
        this.selects.category = null;
      }
    }

    deleteCategoryInFilter(index){
      this.filters.categories.splice(index, 1);
    }

    getCategories(){
      var categories = [];
      if(this.userService.haveLicense()){
        categories = this.userService.getLicense().categories;
      }
      return categories;
    }

    addCashRegisterInFilter(cashRegister){
      if(cashRegister != null){
        this.filters.cashRegisters.push(cashRegister);
        this.selects.cashRegister = null;
      }
    }

    deleteCashRegisterInFilter(index){
      this.filters.cashRegisters.splice(index, 1);
    }

    getPaymentByType(index, type){
      for(var i = 0; i < this.statistics[index].payments.length; i++){
        var payment = this.statistics[index].payments[i];
        if(payment.type == type){
          return payment;
        }
      }
      return null;
    }

    addStatisticsLine(){
      this.periods.push({startDate: new Date(), endDate:new Date()});
      this.barChartLabels.push(["01 mai 2018", "02 mai 2018", "03 mai 2018", "04 mai 2018"]);
      this.barChartData.push([{data: [0, 0, 0, 0], label: 'Series A'},{data: [0, 0, 0, 0], label: 'Series B'}]);
      this.statistics.push({nbSales:0, meanBasket:0, totalTTC:0, payments:[]});
      this.periods[this.periods.length - 1].startDate = new Date(this.nextPeriod.startDate);
      this.periods[this.periods.length - 1].endDate = new Date(this.nextPeriod.endDate);
      this.tickets.push([]);
      this.nextPeriod.startDate = new Date();
      this.nextPeriod.endDate = new Date();
      this.getStatistics(this.statistics.length - 1);
    }

    removeStatisticsLine(index){
      this.periods.splice(index, 1);
      this.barChartLabels.splice(index, 1);
      this.barChartData.splice(index, 1);
      this.statistics.splice(index, 1);
      this.tickets.splice(index, 1);
    }

    getStatistics(index){
      if(!this.loadStatistics){
        this.loadStatistics = true;
        this.periods[index].startDate.setHours(0);
        this.periods[index].startDate.setMinutes(0);
        this.periods[index].startDate.setSeconds(0);
        this.periods[index].startDate.setMilliseconds(0);
        this.periods[index].endDate.setHours(23);
        this.periods[index].endDate.setMinutes(59);
        this.periods[index].endDate.setSeconds(59);
        this.periods[index].endDate.setMilliseconds(99);
        this.userService.getStatistics(this.periods[index].startDate, this.periods[index].endDate, function(data){
          this.loadStatistics = false;
          this.tickets[index] = [];
          this.statistics[index] = {nbSales:0, meanBasket:0, totalTTC:0, payments:[]};
          var meanBasket = 0;
          for(var i = 0; i < data.length; i++){
            var ticket = new TicketLite(data[i]);
            this.tickets[index].push(ticket);
            this.statistics[index].nbSales += ticket.nbSales;
            this.statistics[index].totalTTC += ticket.totalTTCPrice;
            meanBasket += ticket.getMeanBasket();
            for(var j = 0; j < ticket.payments.length; j++){
              var localPayment = ticket.payments[j];
              var payment = this.getPaymentByType(index, localPayment.type);
              if(payment == null){
                payment = localPayment.clone();
                this.statistics[index].payments.push(payment);
              }
              else {
                payment.amount = payment.amount * 1 + localPayment.amount * 1;
              }
            }
          }
          this.statistics[index].meanBasket = meanBasket / this.tickets[index].length;
          this.generateData(index);
        }.bind(this), function(error){
          this.loadStatistics = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    }

    setSubPage(subPage){
      this.subPage = subPage;
      this.generateAllData();
    }

    generateAllData(){
      for(var i = 0; i < this.statistics.length; i++){
        this.generateData(i);
      }
    }

    generateData(index){
      if(this.periods[index].startDate <= this.periods[index].endDate){
        var chartData = [];
        var charColors = [];
        var chartLabels = [];
        var currentDate = new Date(this.periods[index].startDate);
        do{
          //generateData
          var tickets = this.getTicketsByDate(index, currentDate);
          if(this.subPage == 'products') this.generateDataProducts(chartData, charColors, tickets);
          else if(this.subPage == 'groups') this.generateDataGroups(chartData, charColors, tickets);
          else if(this.subPage == 'categories') this.generateDataCategories(chartData, charColors, tickets);
          else if(this.subPage == 'cashRegisters') this.generateDataCashRegisters(chartData, tickets);

          if(this.byMonths) {
            chartLabels.push(this.datePipe.transform(new Date(currentDate), 'MMMM yyyy','','fr'));
            currentDate.setMonth(currentDate.getMonth() + 1);
          }
          else {
            chartLabels.push(this.datePipe.transform(new Date(currentDate), 'dd MMMM yyyy','','fr'));
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
        while(currentDate < this.periods[index].endDate);
        //console.log(chartData);
        if(chartData.length > 0){
          this.barChartLabels[index] = chartLabels;
          this.barChartData[index] = chartData;
          this.barChartColors[index] = charColors;
          this.reloadChart(index);
        }
      }
    }


    generateDataProducts(chartData, chartColors, tickets){
      var products = this.filters.products;
      if(products.length == 0){
        products = this.getProducts();
      }
      for(var i = 0; i < products.length; i++){
        var product = products[i];
        if(chartData[i] == null){
          chartData[i] = {
            data:[],
            localTotalTTC:[],
            purcentNbSales:[],
            purcentTTC:[],
            totalQuantities:[],
            nbSales:[],
            meansBasket:[],
            totalsTTC:[],
            label:product.name,
						borderWidth: 1
          }
          chartColors[i] = {backgroundColor:product.color}
        }
        var informations = this.getInformationsOfProduct(product, tickets);
        chartData[i].data.push(informations.quantity);
        chartData[i].localTotalTTC.push(informations.localTotalTTC);
        chartData[i].purcentNbSales.push(informations.purcentNbSales);
        chartData[i].purcentTTC.push(informations.purcentTTC);
        chartData[i].totalQuantities.push(informations.totalQuantities);
        chartData[i].nbSales.push(informations.nbSales);
        chartData[i].meansBasket.push(informations.meanBasket);
        chartData[i].totalsTTC.push(informations.totalTTC);
      }
    }

    generateDataGroups(chartData, chartColors, tickets){
      var groups = this.filters.groups;
      if(groups.length == 0){
        groups = this.getGroups();
      }
      for(var i = 0; i < groups.length; i++){
        var group = groups[i];
        if(chartData[i] == null){
          chartData[i] = {
            data:[],
            localTotalTTC:[],
            purcentNbSales:[],
            purcentTTC:[],
            totalQuantities:[],
            nbSales:[],
            meansBasket:[],
            totalsTTC:[],
            label:group.name,
            backgroundColor:group.color
          }
          chartColors[i] = {backgroundColor:group.color}
        }
        var informations = this.getInformationsOfGroup(group, tickets);
        chartData[i].data.push(informations.quantity);
        chartData[i].localTotalTTC.push(informations.localTotalTTC);
        chartData[i].purcentNbSales.push(informations.purcentNbSales);
        chartData[i].purcentTTC.push(informations.purcentTTC);
        chartData[i].totalQuantities.push(informations.totalQuantities);
        chartData[i].nbSales.push(informations.nbSales);
        chartData[i].meansBasket.push(informations.meanBasket);
        chartData[i].totalsTTC.push(informations.totalTTC);
      }
    }

    generateDataCategories(chartData, chartColors, tickets){
      var categories = this.filters.categories;
      if(categories.length == 0){
        categories = this.getCategories();
      }
      for(var i = 0; i < categories.length; i++){
        var category = categories[i];
        if(chartData[i] == null){
          chartData[i] = {
            data:[],
            localTotalTTC:[],
            purcentNbSales:[],
            purcentTTC:[],
            totalQuantities:[],
            nbSales:[],
            meansBasket:[],
            totalsTTC:[],
            label:category.name,
            backgroundColor:category.color
          }
          chartColors[i] = {backgroundColor:category.color}
        }
        var informations = this.getInformationsOfCategory(category, tickets);
        chartData[i].data.push(informations.quantity);
        chartData[i].localTotalTTC.push(informations.localTotalTTC);
        chartData[i].purcentNbSales.push(informations.purcentNbSales);
        chartData[i].purcentTTC.push(informations.purcentTTC);
        chartData[i].totalQuantities.push(informations.totalQuantities);
        chartData[i].nbSales.push(informations.nbSales);
        chartData[i].meansBasket.push(informations.meanBasket);
        chartData[i].totalsTTC.push(informations.totalTTC);
      }
    }

    generateDataCashRegisters(chartData, tickets){
      var cashRegisters = this.filters.cashRegisters;
      if(cashRegisters.length == 0){
        cashRegisters = this.getCashRegisters();
      }
      for(var i = 0; i < cashRegisters.length; i++){
        var cashRegister = cashRegisters[i];
        if(chartData[i] == null){
          chartData[i] = {
            data:[],
            localTotalTTC:[],
            purcentNbSales:[],
            purcentTTC:[],
            nbSales:[],
            meansBasket:[],
            totalsTTC:[],
            label:cashRegister.name
          }
        }
        var informations = this.getInformationsOfCashRegister(cashRegister, tickets);
        chartData[i].data.push(informations.quantity);
        chartData[i].localTotalTTC.push(informations.localTotalTTC);
        chartData[i].purcentNbSales.push(informations.purcentNbSales);
        chartData[i].purcentTTC.push(informations.purcentTTC);
        chartData[i].nbSales.push(informations.nbSales);
        chartData[i].meansBasket.push(informations.meanBasket);
        chartData[i].totalsTTC.push(informations.totalTTC);
      }
    }


    getTicketsByDate(index, date):TicketLite[]{
      var tickets = [];
      for(var i = 0; i < this.tickets[index].length; i++){
        var ticket = this.tickets[index][i];
        var ticketDate = new Date(ticket.date);
        if(!this.byMonths && ticketDate.getFullYear() == date.getFullYear() &&
        ticketDate.getMonth() == date.getMonth() &&
        ticketDate.getDate() == date.getDate()){
          tickets.push(ticket);
        }
        else if(this.byMonths && ticketDate.getFullYear() == date.getFullYear() &&
        ticketDate.getMonth() == date.getMonth()){
          tickets.push(ticket);
        }
      }
      return tickets;
    }

    getInformationsOfProduct(product, tickets):any{
      var informations = {quantity:0, localTotalTTC:0, purcentNbSales:0, purcentTTC:0, totalQuantities:0, nbSales:0, meanBasket:0, totalTTC:0};
      if(tickets != null){
        for(var j = 0; j < tickets.length; j++){
          var ticket = tickets[j];
          var shoppingLine = ticket.getShoppingLineByIdProduct(product._id);
          if(shoppingLine != null){
            informations.quantity += shoppingLine.quantity * 1;
            informations.localTotalTTC += shoppingLine.totalTTCPriceWithReduction * 1;
          }
          informations.nbSales += ticket.nbSales;
          informations.meanBasket += ticket.getMeanBasket();
          informations.totalTTC += ticket.totalTTCPrice * 1;
          informations.purcentNbSales += ticket.getQuantities() * 1;
          informations.totalQuantities += ticket.getQuantities() * 1;
        }
        if(informations.purcentNbSales > 0){
          informations.purcentNbSales = Math.round((informations.quantity / informations.purcentNbSales) * 100);
        }
        else {
          informations.purcentNbSales = 0;
        }
        if(informations.totalTTC > 0){
          informations.purcentTTC = Math.round((informations.localTotalTTC / informations.totalTTC) * 100);
        }
        if(tickets.length > 0){
          informations.meanBasket = Math.round((informations.meanBasket/tickets.length) * 100) / 100;
        }
      }
      return informations;
    }


    getInformationsOfGroup(group, tickets, excludesIdProducts = []):any{
      var informations = {quantity:0, localTotalTTC:0, purcentNbSales:0, purcentTTC:0, totalQuantities:0, nbSales:0, meanBasket:0, totalTTC:0};
      for(var i = 0; i < group.products.length; i++){
        var product = this.getProductById(group.products[i], this.getProducts());
        if(product != null && excludesIdProducts.indexOf(product._id) == -1){
          var local = this.getInformationsOfProduct(product, tickets);
          informations.quantity += local.quantity * 1;
          informations.localTotalTTC += local.localTotalTTC * 1;
          excludesIdProducts.push(product._id);
        }
      }
      for(var j = 0; j < tickets.length; j++){
        var ticket = tickets[j];
        informations.nbSales += ticket.nbSales;
        informations.meanBasket += ticket.getMeanBasket();
        informations.totalTTC += ticket.totalTTCPrice;
        informations.purcentNbSales += ticket.getQuantities() * 1;
        informations.totalQuantities += ticket.getQuantities() * 1;
      }
      if(informations.purcentNbSales > 0){
        informations.purcentNbSales = Math.round((informations.quantity / informations.purcentNbSales) * 100);
      }
      else {
        informations.purcentNbSales = 0;
      }
      if(informations.totalTTC > 0){
        informations.purcentTTC = Math.round((informations.localTotalTTC / informations.totalTTC) * 100);
      }
      if(tickets.length > 0){
        informations.meanBasket = Math.round((informations.meanBasket/tickets.length) * 100) / 100;
      }
      return informations;
    }

    getInformationsOfCategory(category, tickets, excludesIdGroups = [], excludesIdProducts = [], excludesIdCategories = []):any{
      var informations = {quantity:0, localTotalTTC:0, purcentNbSales:0, purcentTTC:0, totalQuantities:0, nbSales:0, meanBasket:0, totalTTC:0};
      var products = this.getProducts();
      for(var i = 0; i < products.length; i++){
        var product = products[i];
        if(product.categories.indexOf(category._id) != -1 && excludesIdProducts.indexOf(product._id) == -1){
          var local = this.getInformationsOfProduct(product, tickets);
          informations.quantity += local.quantity * 1;
          informations.localTotalTTC += local.localTotalTTC * 1;
          excludesIdProducts.push(product._id);
        }
      }
      var groups = this.getGroups();
      for(var i = 0; i < groups.length; i++){
        var group = groups[i]
        if(group.categories.indexOf(category._id) != -1 && excludesIdGroups.indexOf(product._id) == -1){
          var local = this.getInformationsOfGroup(group, tickets, excludesIdProducts);
          informations.quantity += local.quantity * 1;
          informations.localTotalTTC += local.localTotalTTC * 1;
          excludesIdGroups.push(group._id);
          for(var j = 0; j < group.products.length; j++){
            excludesIdProducts.push(group.products[j]._id);
          }
        }
      }
      var categories = this.getCategories();
      for(var i = 0; i < categories.length; i++){
        var anotherCategory = categories[i];
        if(anotherCategory.parent == category._id && excludesIdCategories.indexOf(anotherCategory._id) == -1){
          var local = this.getInformationsOfCategory(anotherCategory, tickets, excludesIdGroups, excludesIdProducts, excludesIdCategories);
          informations.quantity += local.quantity * 1;
          informations.localTotalTTC += local.localTotalTTC * 1;
          excludesIdCategories.push(anotherCategory._id);
          //exclude groups and products
          /*var groups = this.getGroups();
          for(var k = 0; k < groups.length; k++){
            var group = groups[k];
            if(group.categories.indexOf(category._id) != -1) {
              excludesIdGroups.push(group._id);
              for(var j = 0; j < group.products.length; j++){
                excludesIdProducts.push(group.products[j]._id);
              }
            }
          }*/
        }
      }
      for(var j = 0; j < tickets.length; j++){
        var ticket = tickets[j];
        informations.nbSales += ticket.nbSales;
        informations.meanBasket += ticket.getMeanBasket();
        informations.totalTTC += ticket.totalTTCPrice;
        informations.purcentNbSales += ticket.getQuantities() * 1;
        informations.totalQuantities += ticket.getQuantities() * 1;
      }
      if(informations.purcentNbSales > 0){
        informations.purcentNbSales = Math.round((informations.quantity / informations.purcentNbSales) * 100);
      }
      else {
        informations.purcentNbSales = 0;
      }
      if(informations.totalTTC > 0){
        informations.purcentTTC = Math.round((informations.localTotalTTC / informations.totalTTC) * 100);
      }
      if(tickets.length > 0){
        informations.meanBasket = Math.round((informations.meanBasket/tickets.length) * 100) / 100;
      }
      return informations;
    }

    getInformationsOfCashRegister(cashRegister, tickets):any{
      var informations = {quantity:0, localTotalTTC:0, purcentNbSales:0, purcentTTC:0, totalQuantities:0, nbSales:0, meanBasket:0, totalTTC:0};
      for(var j = 0; j < tickets.length; j++){
        var ticket = tickets[j];
        if(cashRegister._id == ticket.idCashRegister){
          informations.quantity += ticket.getQuantities() * 1;
          informations.localTotalTTC += ticket.totalTTCPrice * 1;
        }
        informations.nbSales += ticket.nbSales;
        informations.meanBasket += ticket.getMeanBasket();
        informations.totalTTC += ticket.totalTTCPrice;
        informations.purcentNbSales += ticket.getQuantities() * 1;
        informations.totalQuantities += ticket.getQuantities() * 1;
      }
      if(informations.purcentNbSales > 0){
        informations.purcentNbSales = Math.round((informations.quantity / informations.purcentNbSales) * 100);
      }
      else {
        informations.purcentNbSales = 0;
      }
      if(informations.totalTTC > 0){
        informations.purcentTTC = Math.round((informations.localTotalTTC / informations.totalTTC) * 100);
      }
      if(tickets.length > 0){
        informations.meanBasket = Math.round((informations.meanBasket/tickets.length) * 100) / 100;
      }
      return informations;
    }




  }
