import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { MatTableDataSource, MatSort, MatSnackBar } from '@angular/material';


@Component({
  selector: 'floatTab-dialog',
  templateUrl: 'floatTabDialog.html',
  styleUrls:['./floatTabDialog.css']
})


export class FloatTabDialog {

  public dataFloat = null;
  constructor(public dialogRef: MatDialogRef<FloatTabDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataFloat = new MatTableDataSource(data.allFloats);
  }

  getColumns(){
    return ['date', 'user', 'type', 'amount', 'balance', 'note'];
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
