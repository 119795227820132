import {Component, Inject} from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';
import { TicketLite, Payment } from '../../model/ticket';


declare let html2pdf;

@Component({
  selector: 'ticket-dialog',
  templateUrl: 'ticketDialog.html',
  styleUrls:['./ticketDialog.css']
})


export class TicketDialog {

  public ticket:TicketLite = null;
  public onlinePayments:Payment[] = [];
  public anotherPayments = null;
  public loadOnlinePayments:boolean = false;
  public onlinePaymentsLoaded:boolean = false;
  public lastBigBigZTicket = null;
  public loadLastBigBigZTicket:boolean = false;

  constructor(public userService: UserService, public snackBar: MatSnackBar, public dialog: MatDialog, public dialogRef: MatDialogRef<TicketDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.data != null){
      this.ticket = this.data.ticket;
      this.anotherPayments = this.data.anotherPayments;
      this.loadOnlinePayments = false;
      if(this.ticket.isZTicket()){
        this.getTicketsOnline();
      }
      if(this.ticket.isXTicket() || this.ticket.isZTicket() || this.ticket.isBigZTicket() || this.ticket.isBigBigZTicket()){
        this.getLastBigBigZTicket();
      }
    }
  }

  isExportActivated(){
    var exportActivated:boolean = false;
    if(this.userService.haveLicense()){
      exportActivated = this.userService.getLicense().exportActivated;
    }
    return exportActivated;
  }

  printTicket(){
    var content = document.getElementById('printTicket').innerHTML;
		var newWindow = window.open ('','', "menubar=yes,scrollbars=yes,resizable=yes");
		newWindow.document.open();
		newWindow.document.write("<html><head><title></title></head><body class=\"m-0\">"+content+"</body></html>");
		var arrStyleSheets = document.getElementsByTagName("link");
		for (var i = 0; i < arrStyleSheets.length; i++){
      if(arrStyleSheets[i].href.indexOf('bootstrap.min.css') == -1){
			     newWindow.document.head.appendChild(arrStyleSheets[i].cloneNode(true));
      }
		}
		var arrStyle = document.getElementsByTagName("style");
		for (var i = 0; i < arrStyle.length; i++){
			newWindow.document.head.appendChild(arrStyle[i].cloneNode(true));
		}
		newWindow.document.close();
		newWindow.focus();
		setTimeout(function(){
			newWindow.print();
      newWindow.close(); },
			1000);
  }

  getTicketsOnline(){
    if(!this.loadOnlinePayments){
      this.loadOnlinePayments = true;
      this.userService.getTicketsOnline(this.ticket.idCashRegister, this.ticket.id, this.ticket.type, function(json){
        this.onlinePaymentsLoaded = true;
        this.loadOnlinePayments = false;
        this.onlinePayments = [];
        for(var i = 0; i < json.paymentsReceipt.length; i++){
          var jsonPayment = json.paymentsReceipt[i];
          var payment = new Payment(jsonPayment.type, jsonPayment.amount * 1);
          payment.number = jsonPayment.number * 1;
          this.onlinePayments.push(payment);
        }
      }.bind(this), function(error){
        this.loadOnlinePayments = false;
        console.log(error);
      }.bind(this));
    }
  }

  getLastBigBigZTicket(){
    if(!this.loadLastBigBigZTicket){
      this.loadLastBigBigZTicket = true;
      this.userService.getLastBigBigZTicket(this.ticket.idCashRegister, this.ticket.id, function(json){
        this.loadLastBigBigZTicket = false;
        this.lastBigBigZTicket = new TicketLite(json);
        if(json!=null && this.lastBigBigZTicket.id != null && this.lastBigBigZTicket.globalHT > 0){
          this.ticket.globalHTYear = this.ticket.globalHT - this.lastBigBigZTicket.globalHT;
          this.ticket.globalTTCYear = this.ticket.globalTTC - this.lastBigBigZTicket.globalTTC;
        }

      }.bind(this), function(error){
        this.loadLastBigBigZTicket = false;
        console.log(error);
      }.bind(this));
    }
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  openBill(){
    this.dialogRef.close('openBill');
  }

  downloadTicket(){
    var date = new Date();
    const input = document.getElementById("printTicket");
    input.style.transform = input.style.webkitTransform = 'scale(0.85)';
    html2pdf(input,{
      margin:       12,
      filename:     'ticket' + this.ticket.id + '.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192 },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' }
    });
  }

  generateBill(){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '350px',
      data: {
        title:'Générer une facture ?',
        text: 'Voulez-vous générer une facture à partir de ce ticket ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.dialogRef.close('generateBill');
      }
    });
  }

  launchExport(){
    var license = this.userService.getLicense();
    if(!this.loadLastBigBigZTicket && license != null){
      this.loadLastBigBigZTicket = true;
      var filters = {type:{products:true, vat:true, payments:true}, groupByReferences:false, display:{ht:true, ttc:true}};
      this.userService.post('api/v1/export/:token/' + this.ticket.id, {idLicense:license.idLicense, filters:filters}, (data) => {
        console.log(data);
        this.loadLastBigBigZTicket = false;
        var fileName = 'export_ticketGrandZ_' + this.ticket.id;
        this.dowloadCSV(data.result, fileName);
      }, (error) => {
        this.loadLastBigBigZTicket = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
    }
  }

  dowloadCSV(csv, file){
    var downloadLink = document.createElement('a');
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = file + '.csv';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

}
