import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'page-register-test',
  templateUrl: 'registerTest.html',
  styleUrls:['./registerTest.css']
})

export class RegisterTestComponent implements OnInit {
  public error = false;
  public maintenance:any = null;
  public pseudo:string = '';
  public email:string = '';
  public name:string = '';
  public url:string = '';

  constructor(public userService: UserService, public navService:NavService,
  private route: ActivatedRoute, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.userService.getWithoutConnection('api/v1/maintenance/', (maintenance) => {
      this.maintenance = maintenance;
      console.log(maintenance);
    }, (error) => {
      let snackBarRef = this.snackBar.open(error.message, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    });
  }

  isValidEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValidURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  canRegister(){
    return this.pseudo.length > 0 && this.isValidEmail(this.email) && this.name.length > 0 && this.isValidURL(this.url);
  }

  register(){
    if(this.canRegister()){
      this.userService.registerNewLicense(this.pseudo, this.email, this.name, this.url, this.successCallback.bind(this), this.errorCallback.bind(this));
    }
  }

  successCallback(data){
    this.navService.changeRouteAfterLogin(this.userService);
  }

  errorCallback(error){
    let snackBarRef = this.snackBar.open(error, 'OK', {
      duration: 8000,
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }
}
