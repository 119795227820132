import { ViewChildren, QueryList, Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { DecimalPipe, DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { AlertDialog } from '../../components/alertDialog/alertDialog';
import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { LicenseService } from '../../services/license.service';

import { TicketLite } from '../../model/ticket'

@Component({
  selector: 'page-exports',
  templateUrl: 'export.html',
  styleUrls:['./export.css']
})

export class ExportComponent {

  public startDate:NgbDateStruct;
  public endDate:NgbDateStruct;
  public loadExportData:boolean = false;
  public filters:any = {type:{products:true, vat:true, payments:true}, groupByReferences:false, display:{ht:true, ttc:true}};

  constructor(public userService: UserService, public navService:NavService,
    public licenseService:LicenseService, public dialog: MatDialog,
    private route: ActivatedRoute, private datePipe: DatePipe, private decimalPipe: DecimalPipe, public snackBar: MatSnackBar) {


    }

    ngOnInit(): void {
      if(!this.userService.isConnected()){
        this.navService.toLoginPage();
      }
      else {
        this.loadExportData = true;
        this.userService.initializeData(function(){
          this.loadExportData = false;
          if(!this.userService.haveLicense() || !this.userService.getLicense().exportActivated){
            this.goMenu();
          }
        }.bind(this), function(error){
          this.loadExportData = false;
          this.navService.toLoginPage();
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    }

    getPseudo():String{
      var pseudo:String = '';
      if(this.userService.isConnected()){
        pseudo = this.userService.getUser().pseudo;
      }
      return pseudo;
    }

    goMenu(){
      this.navService.changeRoute('menu');
    }

    /**
     *
     */
    getDaysBetweenTwoDates(startDate, endDate){
      var diffSeconds = endDate.getTime() - startDate.getTime();
      return diffSeconds / (1000 * 60 * 60 * 24);
    }

    getPaymentsTypesByIdCashRegister(idCashRegister){
      var license = this.userService.getLicense();
      for(var i = 0; i < license.paymentsTypes.length; i++){
        var paymentsType = license.paymentsTypes[i];
        if(idCashRegister == paymentsType.idCashRegister){
          return paymentsType;
        }
      }
      return null;
    }


    getCashRegisters(){
      var cashRegisters = [];
      if(this.userService.haveLicense()){
        for(var i = 0; i < this.userService.getLicense().modelCashRegisters.length; i++){
          var cashRegister = this.userService.getLicense().modelCashRegisters[i];
          cashRegisters.push(cashRegister);
        }
      }
      return cashRegisters;
    }


    toJSDate(ngbDate:NgbDateStruct){
      return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    }

    canLaunchExport(){
      if(this.startDate == null || this.endDate == null) return false;
      var startDate = this.toJSDate(this.startDate);
      var endDate = this.toJSDate(this.endDate);
      return !this.loadExportData && startDate.getTime() <= endDate.getTime();
    }

    launchExport(){
      var license = this.userService.getLicense();
      if(this.canLaunchExport() && license != null){
        var startDate = this.toJSDate(this.startDate);
        var endDate = this.toJSDate(this.endDate);
        var days = this.getDaysBetweenTwoDates(startDate, endDate);
        if(days <= 35){
          this.loadExportData = true;
          this.userService.post('api/v1/export/:token', {idLicense:license.idLicense, startDate:startDate, endDate:endDate, filters:this.filters}, (data) => {
            console.log(data);
            this.loadExportData = false;
            var fileName = 'export_' + formatDate(startDate,'ddMMMMyy','fr') + '_'  + formatDate(endDate,'ddMMMMyy','fr');
            this.dowloadCSV(data.result, fileName);
          }, (error) => {
            this.loadExportData = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          });
        }
        else {
          this.dialog.open(AlertDialog, {
            width: '350px',
            data: {
              title:'Période trop longue',
              text: 'Veuillez sélectionner une période <span class="s_danger s_bold">de moins de 35 jours</span>.'
            }
          });
        }
      }
    }

    dowloadCSV(csv, file){
      var downloadLink = document.createElement('a');
      var blob = new Blob(["\ufeff", csv]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = file + '.csv';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }


  }
