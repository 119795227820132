import {Input, Output, Component, Inject, EventEmitter } from '@angular/core';
import { TicketLite, Payment } from '../../model/ticket'
import { DomSanitizer } from '@angular/platform-browser'


@Component({
  selector: 'bill',
  templateUrl: 'bill.html',
  styleUrls:['./bill.css']
})

export class BillDisplay {

  _bill:any = null;
  @Input() customer:any;
  @Input() anotherPayments:any[] = [];
  @Input() extras = {bookings:[], services:[]};

  constructor( private sanitized: DomSanitizer) {
  }

  @Input()
  set bill(bill: any) {
    this._bill = bill;
  }

  trustHtml(value){
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  toPaymentClass(payment){
    return new Payment(payment.type, payment.amount);
  }

  alreadyPaid(){
    var total = 0;
    for(var i = 0; i < this._bill.tickets.length; i++){
      var ticket = this._bill.tickets[i];
      for(var j = 0; j < ticket.payments.length; j++){
        var payment = this.toPaymentClass(ticket.payments[j]);
        if(payment.type != 'defer') total += payment.amount * 1;
      }
    }
    return total;
  }

  haveAPayment(){
    for(var i = 0; i < this._bill.tickets.length; i++){
      var ticket = this._bill.tickets[i];
      for(var j = 0; j < ticket.payments.length; j++){
        var payment = this.toPaymentClass(ticket.payments[j]);
        if(payment.type != 'defer') return true;
      }
    }
    return false;
  }

  needToPaid(){
    return this._bill.ticket.totalTTCPrice * 1 - this.alreadyPaid();
  }

  haveReduction(totalHTPriceWithoutReduction, totalHTPriceWithReduction){
    return Math.round(totalHTPriceWithoutReduction * 100) - Math.round(totalHTPriceWithReduction * 100)
  }

  abs(value){ return Math.abs(value); }

  getThresholdPriceOfShoppingLine(shoppingLine){
    if(shoppingLine.thresholdPrices.length > 0 && shoppingLine.thresholded == 'true'){
      var element = shoppingLine.thresholdPrices.find(element => {
        if(element.min <= this.abs(shoppingLine.quantity) && this.abs(shoppingLine.quantity) <= element.max){
          return element;
        }
      });
      if(element == null){
        return shoppingLine.thresholdPrices[shoppingLine.thresholdPrices.length - 1];
      }
      return element;
    }
    return null;
  }


  getServiceName(appointment){
    var service = this.extras.services.find(element => {
      if(element.type == 'service' && element.idAppointment == appointment.id){
        return element;
      }
    });
    if(service) return service.name;
    return '';
  }

}
