import {Input, Output, Component, Inject, EventEmitter } from '@angular/core';
	import { OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pad',
  templateUrl: 'pad.html',
  styleUrls:['./pad.css']
})

export class PadComponent implements OnChanges {

  public operator:string = '';
  public valueSaved = '';
  public decimalActived = false;
  @Input() value: string = '0';
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() enterPressed: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.value.currentValue != changes.value.previousValue){
      this.value = changes.value.currentValue;
      if(this.value == '') {
        this.operator = '';
        this.decimalActived = false;
        this.operator = '';
        this.valueSaved = '';
      }
    }
  }

  pushNumber(number){
    if(this.operator != '' && this.valueSaved == ''){
      this.valueSaved = this.value;
      this.value = '0';
    }
    else {
      if(this.decimalActived){
        this.value += '.';
        this.decimalActived = false;
      }
    }
		if(this.value == '0') this.value = number;
    else this.value += number;
    this.valueChanged.emit(this.value);
  }

	sendValueChanged(){
    this.valueChanged.emit(this.value  + '');
	}

  isDecimal(){
    return Math.floor(Number(this.value).valueOf()) != Number(this.value).valueOf();
  }

  decimal(){
    if(!this.isDecimal()){
      this.decimalActived = true;
    }
  }

  setOperator(operator){
    this.operator = operator;
    this.decimalActived = false;
  }

	isOperatorActive(operator){
		return this.operator == operator;
	}

  enterButton(){
    if(this.operator != ''){
      if(this.operator == '+') this.value = (Number(this.valueSaved) + Number(this.value)) + '';
      else if(this.operator == '-') this.value = (Number(this.valueSaved) - Number(this.value)) + '';
      else if(this.operator == 'x') this.value = (Number(this.valueSaved) * Number(this.value)) + '';
      else if(this.operator == '/') this.value = (Number(this.valueSaved) / Number(this.value)) + '';
      this.operator = '';
      this.valueSaved = '';
      this.valueChanged.emit(this.value);
    }
    else {
      this.enterPressed.emit(this.value);
    }
  }

  clearNumber(){
    this.value = '0';
    this.valueChanged.emit(this.value);
  }
}
