import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MatDialog, MatTableDataSource,MatSnackBar } from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';
import { AlertDialog } from '../../components/alertDialog/alertDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { AdminService } from '../../services/admin.service';
import { LicenseService } from '../../services/license.service';
import { server } from '../../app/global';

@Component({
  selector: 'page-admin',
  templateUrl: 'admin.html',
  styleUrls:['./admin.css']
})

export class AdminComponent implements OnInit {

  public actionLaunched:boolean = false;
  public license = null;

  public maintenances = [];
  public formMaintenance = {
    display:false,
    _id:null,
    startNgbDate:null,
    endNgbDate:null,
    startTime:null,
    endTime:null,
    text:'',
    activated:true
  }

  private licenses = [];
  private newLicense = {name:'', email:'', description:''};
  private newGenerateLicense = {email:''};
  private editLicenseView:boolean = false;
  private editLicenseFiscalYearDates:boolean = false;
  private formFiscalYearDates = {start:null, end:null};

  private dataPlaces = null;
  private formPlace = {name:''};
  private idEditPlace = '';

  private dataCashRegisters = null;
  private formCashRegister = {name:'', places: [], test:false};
  private cashRegister = null;

  private dataUserElements = null;
  private formUser = {lastName:'', firstName:'', pseudo:'', email:'', role:'', cashRegisters:[]};
  private currentEditUser = null;
  private roles = [{id:'manager', title:'Manageur'}, {id:'vendor', title:'Vendeur'}];

  private dataPaymentsMethods = null;
  private paymentsMethods = [{id:'systempay', title: 'SystemPay'},{id:'paypal', title: 'Paypal'},{id:'monetico', title: 'Monetico paiement'},{id:'stripe', title: 'Stripe'},{id:'paybox', title: 'Paybox'},{id:'monext', title: 'MONEXT PAYLINE'}];
  private currentPaymentMethod = null;
  private formPaymentsMethods={paymentMethod:'', idShop:'', certificate:''};

  private subpage:String = '';
  private months = [];
  private monthChosen = null;

  private bigSubPage:string = 'licenses';

  constructor(public userService: UserService, public navService:NavService,
    public adminService:AdminService, private datePipe: DatePipe, public licenseService:LicenseService, public dialog: MatDialog, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      if(this.userService.isAdmin()){
        this.actionLaunched = true;
        this.userService.get('api/v1/maintenances/:token/0/15', (maintenances) => {
          this.maintenances = maintenances;
        }, (error) => {
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        });
        this.userService.get('api/v1/licenses/:token', function(licenses){
          this.actionLaunched = false;
          this.licenses = licenses;
        }.bind(this), function(error){
          this.actionLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
      else {
        this.actionLaunched = true;
        this.adminService.getLicenseById(this.userService.getUser().idLicense, function(license){
          this.actionLaunched = false;
          this.setLicense(license);
        }.bind(this), function(error){
          this.actionLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    }
    this.calculateMonths();
  }

  calculateMonths(){
    var months = [];
    var actualDate = new Date();
    var date = new Date();
    for(var i = 0; i < 12; i++){
      date = new Date();
      date.setMonth(i);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      if(date.getMonth() > actualDate.getMonth()){
        date.setFullYear(date.getFullYear() - 1);
      }
      months.push(date);
    }
    months.sort(function(dateA, dateB){
      return dateB.getTime() - dateA.getTime();
    });
    this.months = months;
  }

  getPseudo():String{
    var pseudo:string = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  disconnect(){
    this.userService.disconnect(function(){
      this.navService.toLoginPage();
    }.bind(this), function(error){
      this.navService.toLoginPage();
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this));
  }

  isAdmin(){
    return this.userService.isAdmin();
  }

  isPageLicenses(){ return this.bigSubPage == 'licenses'; }
  isPageMaintenance(){ return this.bigSubPage == 'maintenance'; }
  pageLicences(){ this.bigSubPage = 'licenses'; }
  pageMaintenance(){ this.bigSubPage = 'maintenance'; }

  getBackgroundColors(license){
    if(license.name == null || license.name.length == 0) return '#cc5656';
    if(!license.activated) return '#d5d5d5';
    return '#37b294';
  }

  setMainSubPage(){ this.subpage = ''; this.editLicenseView = false; }
  setLicensePlacesSubPlage(){ this.subpage = 'licensePlaces'; this.createDataPlacesElements(); }
  setLicenseCashRegistersSubPlage(){ this.subpage = 'licenseCashRegisters'; this.launchEditCashRegister(null); this.createDataCashRegistersElements(); }
  setLicenseUsersSubPage(){
    this.subpage = 'licenseUsers';
    this.launchEditUser(null);
    this.createDataUserElements();
  }
  setLicensePaymentsMethodsSettingsSubPage(){
    this.subpage = 'licensePaymentsMethodsSettings';
    this.launchEditPaymentMethod(null);
    this.createDataPaymentsMethods();
  }
  isMainSubPage(){ return this.subpage == ''; }
  isLicensePlacesSubPage(){ return this.subpage == 'licensePlaces'; }
  isLicenseCashRegistersSubPage(){ return this.subpage == 'licenseCashRegisters'; }
  isLicenseUsersSubPage(){ return this.subpage == 'licenseUsers'; }
  isLicensePaymentsMethodsSettingsSubPage(){ return this.subpage == 'licensePaymentsMethodsSettings'; }

  havePlaces(){ return this.license!=null && this.license.subLicense.places.length > 0; }
  haveCashRegisters(){ return this.license!=null && this.license.subLicense.modelCashRegisters.length > 0; }

  getLinkDowloadRESAOnline(){
    return server + 'api/v1/dl_resa_caisse_online/' + this.license._id;
  }

  getRegisterLicenseURL(license){
    var url = '';
    if(license != null && license.token != null){
      url = this.navService.getRegisterURL(license.token);
    }
    return url;
  }

  modifyFiscalDateLicense(license){
    this.editLicenseFiscalYearDates = true;
    if(license.subLicense.fiscalYearDates == null || license.subLicense.fiscalYearDates.start == null){
      this.formFiscalYearDates = {start:this.getDefaultYearStartDates(), end:this.getDefaultYearEndDates()};
    }
    else {
      this.formFiscalYearDates = {start:new Date(license.subLicense.fiscalYearDates.start), end:new Date(license.subLicense.fiscalYearDates.end)};
    }
  }

  getDefaultYearStartDates(){
    var actualDate = new Date();
    var date = new Date(actualDate.getFullYear(), 0, 1, 0, 0, 0, 0);
    return date;
  }

  getDefaultYearEndDates(){
    var actualDate = new Date();
    var date = new Date(actualDate.getFullYear(), 11, 31, 23, 59, 59, 99);
    return date;
  }

  isFiscalDateDurationYear(fiscalYearDates){
    var startMore1Year = new Date(fiscalYearDates.start.getFullYear() + 1, fiscalYearDates.start.getMonth(), fiscalYearDates.start.getDate(), 0, 0, 0, 0);
    var endTomorrow = new Date(fiscalYearDates.end.getFullYear(), fiscalYearDates.end.getMonth(), fiscalYearDates.end.getDate() + 1, 0, 0, 0, 0);
    return startMore1Year.getTime() == endTomorrow.getTime();
  }

  createDataPlacesElements(){
    if(this.license != null){
      this.dataPlaces = new MatTableDataSource(this.license.subLicense.places);
    }
    else {
      this.dataPlaces = new MatTableDataSource([]);
    }
  }

  getColumnsPlacesTable(){
    return ['name', 'modify', 'delete'];
  }

  createDataUserElements(){
    if(this.license != null){
      this.dataUserElements = new MatTableDataSource(this.license.subLicense.modelUsers);
    }
    else {
      this.dataUserElements = new MatTableDataSource([]);
    }
  }

  getColumnsUsersTable(){
    var columns = ['lastName', 'firstName','pseudo','email','password', 'role', 'modify', 'delete'];
    for(var i = 0; i < this.license.subLicense.modelCashRegisters.length; i++){
      columns.push(this.license.subLicense.modelCashRegisters[i].name);
    }
    return columns;
  }

  createDataCashRegistersElements(){
    if(this.license != null){
      this.dataCashRegisters = new MatTableDataSource(this.license.subLicense.modelCashRegisters);
    }
    else {
      this.dataCashRegisters = new MatTableDataSource([]);
    }
  }

  getColumnsCashRegistersTable(){
    var columns = ['name', 'modify', 'delete'];
    for(var i = 0; i < this.license.subLicense.places.length; i++){
      columns.push(this.license.subLicense.places[i].name);
    }
    return columns;
  }

  createDataPaymentsMethods(){
    if(this.license != null){
      this.dataPaymentsMethods = new MatTableDataSource(this.license.subLicense.paymentsMethods);
    }
    else {
      this.dataPaymentsMethods = new MatTableDataSource([]);
    }
  }

  getColumnsPaymentsMethodsTable(){
    var columns = ['type', 'param1','param2', 'modify', 'delete'];
    return columns;
  }


  setLicense(license){
    this.license = license;
    this.setMainSubPage();
  }

  createLicense(){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: {
          title:'Créer une licence ?',
          text: 'Voulez-vous vraiment créer la licence ' + this.newLicense.name + ' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.adminService.createLicense(this.newLicense, function(license){
            this.actionLaunched = false;
            this.licenses.push(license);
            this.newLicense = {name:'', email:'', description:''};
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  generateLicense(){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: {
          title:'Générer une licence ?',
          text: 'Générer une licence et envoyer le mail de finalisation de la licence à l\'adressse : ' + this.newGenerateLicense.email + ' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.adminService.generateLicense(this.newGenerateLicense.email, function(license){
            this.actionLaunched = false;
            this.licenses.push(license);
            this.newGenerateLicense = {email:''};
            let snackBarRef = this.snackBar.open('Généré et envoyé', 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  editLicense(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      this.adminService.editLicense(this.license, function(license){
        this.actionLaunched = false;
        this.updateLicenseById(license);
        this.editLicenseView = false;
        this.createDataPaymentsMethods();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  validateFiscalDateLicense(){
    if(this.formFiscalYearDates.start.getTime() < new Date(this.license.subLicense.lastYearCloseDate).getTime() || !this.isFiscalDateDurationYear(this.formFiscalYearDates)){
      var dialog = '';
      if(this.formFiscalYearDates.start.getTime() < new Date(this.license.subLicense.lastYearCloseDate).getTime()){
        dialog += 'Nous avons détecté que vous changiez les dates de l\'années fiscale par une date déjà clôturer';
      }
      if(!this.isFiscalDateDurationYear(this.formFiscalYearDates)){
        if(dialog != '') dialog += '<br />';
        dialog += 'Attention ! La durée n\'est pas d\'une année complète !'
      }
      dialog += '?';
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: {
          title:'Definir ces dates pour l\'année fiscale ?',
          text: dialog
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.editLicenseFiscalYearDatesAction();
        }
      });
    }
    else {
      this.editLicenseFiscalYearDatesAction();
    }
  }

  editLicenseFiscalYearDatesAction(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      this.adminService.editLicenseFiscalYearDates(this.license._id, this.formFiscalYearDates, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.editLicenseFiscalYearDates = false;
        this.createDataPaymentsMethods();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteLicenceLaunch(){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer cette license ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.adminService.deleteLicense(this.license._id, function(license){
            this.actionLaunched = false;
            this.removeLicense(this.license._id);
            this.setLicense(null);
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  synchronizeResaOnlineVariables(){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Synchroniser les variables définie dans RESA Online ?',
          text: 'Voulez-vous récupérer les différents éléments de RESA Online afin de les ajouter à la caisse<br /><i>Nécessite que RESA Online soit connecté à la caisse.</i> ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.synchronizeResaOnlineVariables(this.license._id, function(subLicense){
            this.actionLaunched = false;
            this.license.subLicense = subLicense;
            this.updateLicenseById(this.license);
            let snackBarRef = this.snackBar.open('La synchronisation s\'est bien passée !', 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  getNumberTicketsInMonth(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      this.adminService.getNumberTicketsInMonth(this.monthChosen, this.license._id, data => {
        this.actionLaunched = false;
        var displayDate = this.datePipe.transform(data.date, 'MMMM yyyy', '', 'fr');
        var text = 'La license ' + this.license.name + ' a encaissée <b>' + data.number + '</b> ticket(s) durant le mois de ' + displayDate;
        if(data.number == 0){
          text = 'La license ' + this.license.name + ' a encaissée <b>AUCUN</b> ticket durant le mois de ' + displayDate;
        }
        let dialogRef = this.dialog.open(AlertDialog, {
          width: '250px',
          data: { title:'Hors saison ?', text: text}
        });
        dialogRef.afterClosed().subscribe(result => {
          this.license.billings.unshift({
            month:this.monthChosen,
            tickets:data.number,
            bill:'',
            sent:false,
            paid:false
          });
          this.license.billings.sort((b1, b2) => {
            var month1 = new Date(b1.month);
            var month2 = new Date(b2.month);
            return month2.getTime() - month1.getTime()
          });
          this.editLicense();
        });
      }, function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteBilling(index){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous supprimer cette facturation ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.license.billings.splice(index, 1);
      }
    });
  }

  updateBilling(billing){
    if(billing.edit){
      this.editLicense();
    }
    else {
      billing.edit = !billing.edit;
    }
  }

  accessLicense(idLicense){
    this.userService.clearLicense();
    this.userService.getUser().idLicense = idLicense;
    this.userService.saveUserInStorage();
    this.navService.changeRoute('menu');
  }

  editSubLicense(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      this.licenseService.editSubLicense(this.license._id, this.license.subLicense, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.editLicenseView = false;
        this.updateLicenseById(this.license);
        this.createDataPaymentsMethods();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  isValidPlace(){
    for(var i = 0; i < this.license.subLicense.places.length; i++){
      var place = this.license.subLicense.places[i];
      if(place.name == this.formPlace.name && (this.idEditPlace=='' || this.idEditPlace != place.id)){
        return false;
      }
    }
    return true;
  }

  addPlace(){
    if(!this.actionLaunched && this.isValidPlace()){
      this.actionLaunched = true;
      this.licenseService.addPlace(this.license._id, this.formPlace.name, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.formPlace = {name:''};
        this.createDataPlacesElements();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  launchEditPlace(place){
    if(place == null){
      this.idEditPlace = '';
      this.formPlace = {name:''};
    }
    else {
      this.idEditPlace = place.id;
      this.formPlace = {name:place.name};
    }
  }

  editPlace(){
    if(!this.actionLaunched && this.isValidPlace()){
      this.actionLaunched = true;
      this.licenseService.editPlace(this.license._id, this.idEditPlace, this.formPlace.name, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.launchEditPlace();
        this.createDataPlacesElements();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }


  deletePlace(idPlace){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer cette place ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.deletePlace(this.license._id, idPlace, function(subLicense){
            this.actionLaunched = false;
            this.license.subLicense = subLicense;
            this.updateLicenseById(this.license);
            this.createDataPlacesElements();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  launchEditCashRegister(cashRegister){
    if(cashRegister == null){
      this.cashRegister = null;
      this.formCashRegister = {name:'', places:[], test:this.license.subLicense.mode == 'test'};
    }
    else {
      this.cashRegister = cashRegister;
      this.formCashRegister = {name:cashRegister.name, places:JSON.parse(JSON.stringify(cashRegister.places)), test:cashRegister.test};
    }
  }

  isValidCashRegister(){
    for(var i = 0; i < this.license.subLicense.modelCashRegisters.length; i++){
      var cashRegister = this.license.subLicense.modelCashRegisters[i];
      if(cashRegister.name == this.formCashRegister.name && (this.cashRegister==null || this.cashRegister._id != cashRegister._id)){
        return false;
      }
    }
    return true;
  }

  addCashRegister(){
    if(!this.actionLaunched && this.isValidCashRegister()){
      this.actionLaunched = true;
      var cashRegister = {name:this.formCashRegister.name, places:this.formCashRegister.places, test:this.formCashRegister.test};
      this.licenseService.addCashRegister(this.license._id, cashRegister, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.launchEditCashRegister(null);
        this.createDataCashRegistersElements();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  editCashRegister(){
    if(!this.actionLaunched && this.isValidCashRegister()){
      this.actionLaunched = true;
      var cashRegister = this.cashRegister;
      cashRegister.name = this.formCashRegister.name;
      cashRegister.places = this.formCashRegister.places;
      cashRegister.test = this.formCashRegister.test;
      this.licenseService.editCashRegister(this.license._id, cashRegister, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.launchEditCashRegister(null);
        this.createDataCashRegistersElements();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteCashRegister(idCashRegister){
      if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer cette caisse ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.deleteCashRegister(this.license._id, idCashRegister, function(subLicense){
            this.actionLaunched = false;
            this.license.subLicense = subLicense;
            this.updateLicenseById(this.license);
            this.createDataCashRegistersElements();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  getRoleById(idRole){
    for(var i = 0; i < this.roles.length; i++){
      var role = this.roles[i];
      if(role.id == idRole){
        return role;
      }
    }
    return {id:idRole, title:'Inconnue'};
  }

  launchEditUser(user){
    if(user == null){
      this.currentEditUser = null;
      this.formUser = {lastName:'', firstName: '', pseudo:'', email:'', role:'', cashRegisters:[]};
    }
    else {
      this.currentEditUser = user;
      this.formUser = {lastName:user.lastName, firstName:user.firstName, pseudo:user.pseudo, email: user.email, role:user.role[0], cashRegisters:[]};
    }
    if(this.license != null){
      for(var i = 0; i < this.license.subLicense.modelCashRegisters.length; i++){
        var cashRegister = this.license.subLicense.modelCashRegisters[i];
        if(this.currentEditUser == null){
          this.formUser.cashRegisters.push({id:cashRegister._id, checked:false});
        }
        else {
          this.formUser.cashRegisters.push({id:cashRegister._id, checked:cashRegister.users.indexOf(this.currentEditUser._id)>-1});
        }
      }
    }
  }


  addUser(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var user = {
        lastName:this.formUser.lastName,
        firstName:this.formUser.firstName,
        pseudo:this.formUser.pseudo,
        email:this.formUser.email,
        role: this.formUser.role};
      var idCashRegisters = [];
      for(var i = 0; i < this.formUser.cashRegisters.length; i++){
        var cashRegister = this.formUser.cashRegisters[i];
        if(cashRegister.checked){
          idCashRegisters.push(cashRegister.id);
        }
      }
      this.licenseService.addUser(this.license._id, user, idCashRegisters, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.launchEditUser(null);
        this.createDataUserElements();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  editUser(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var user = {
        _id:this.currentEditUser._id,
        lastName:this.formUser.lastName,
        firstName:this.formUser.firstName,
        pseudo:this.formUser.pseudo,
        email:this.formUser.email,
        role: this.formUser.role
      };
      var idCashRegisters = [];
      for(var i = 0; i < this.formUser.cashRegisters.length; i++){
        var cashRegister = this.formUser.cashRegisters[i];
        if(cashRegister.checked){
          idCashRegisters.push(cashRegister.id);
        }
      }
      this.licenseService.editUser(this.license._id, user, idCashRegisters, function(subLicense){
        this.actionLaunched = false;
        this.license.subLicense = subLicense;
        this.updateLicenseById(this.license);
        this.launchEditUser(null);
        this.createDataUserElements();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteUser(idUser){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer cet utilisateur ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.deleteUser(this.license._id, idUser, function(subLicense){
            this.actionLaunched = false;
            this.license.subLicense = subLicense;
            this.updateLicenseById(this.license);
            this.createDataUserElements();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  sendNewPassword(idUser){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Réinitialiser ?', text: 'Voulez-vous réinitialiser et envoyer le mot de passe ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.userService.generationPassword(idUser, this.license._id, function(json){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open('Mot de passe envoyé !', 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  launchEditPaymentMethod(paymentMethod){
    if(paymentMethod == null){
      this.currentPaymentMethod = null;
      this.formPaymentsMethods = {paymentMethod:'', idShop:'', certificate:''};
    }
    else {
      this.currentPaymentMethod = paymentMethod;
      this.formPaymentsMethods = {paymentMethod:paymentMethod.paymentMethod, idShop:paymentMethod.idShop, certificate:paymentMethod.certificate};
    }
  }

  deletePaymentMethod(paymentMethodParams){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer cette méthode de paiement ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          var newPaymentMethods = [];
          for(var i = 0; i < this.license.subLicense.paymentsMethods.length; i++){
            var localPaymentMethodParams = this.license.subLicense.paymentsMethods[i];
            if(localPaymentMethodParams.paymentMethod != paymentMethodParams.paymentMethod){
              newPaymentMethods.push(localPaymentMethodParams);
            }
          }
          this.license.subLicense.paymentsMethods = newPaymentMethods;
          this.editLicense();
        }
      });
    }
  }

  addPaymentMethod(){
    if(!this.actionLaunched){
      var paymentMethodParams = null;
      if(this.formPaymentsMethods.paymentMethod == 'systempay'){
        paymentMethodParams = {
          paymentMethod:'systempay',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }else if(this.formPaymentsMethods.paymentMethod == 'monext'){
        paymentMethodParams = {
          paymentMethod:'monext',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'paypal'){
        paymentMethodParams = {
          paymentMethod:'paypal',
          idShop:this.formPaymentsMethods.idShop
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'monetico'){
        paymentMethodParams = {
          paymentMethod:'monetico',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'stripe'){
        paymentMethodParams = {
          paymentMethod:'stripe',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'paybox'){
        paymentMethodParams = {
          paymentMethod:'paybox',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      var found = false;
      var index = 0;
      while(!found && index < this.license.subLicense.paymentsMethods.length){
        var localPaymentMethodParams = this.license.subLicense.paymentsMethods[index];
        if(localPaymentMethodParams.paymentMethod == paymentMethodParams.paymentMethod){
          found = true;
          localPaymentMethodParams.idShop = paymentMethodParams.idShop;
          localPaymentMethodParams.certificate = paymentMethodParams.certificate;
        }
        index++;
      }
      if(!found && paymentMethodParams != null){
        this.license.subLicense.paymentsMethods.push(paymentMethodParams);
      }
      this.launchEditPaymentMethod(null);
      this.editLicense();
    }
  }

  editPaymentMethod(){
    if(!this.actionLaunched){
      var paymentMethodParams = null;
      if(this.formPaymentsMethods.paymentMethod == 'systempay'){
        paymentMethodParams = {
          paymentMethod:'systempay',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }else if(this.formPaymentsMethods.paymentMethod == 'monext'){
        paymentMethodParams = {
          paymentMethod:'monext',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'paypal'){
        paymentMethodParams = {
          paymentMethod:'paypal',
          idShop:this.formPaymentsMethods.idShop
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'monetico'){
        paymentMethodParams = {
          paymentMethod:'monetico',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'stripe'){
        paymentMethodParams = {
          paymentMethod:'stripe',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      else if(this.formPaymentsMethods.paymentMethod == 'paybox'){
        paymentMethodParams = {
          paymentMethod:'paybox',
          idShop:this.formPaymentsMethods.idShop,
          certificate:this.formPaymentsMethods.certificate
        }
      }
      var found = false;
      var index = 0;
      while(!found && index < this.license.subLicense.paymentsMethods.length){
        var localPaymentMethodParams = this.license.subLicense.paymentsMethods[index];
        if(localPaymentMethodParams.paymentMethod == paymentMethodParams.paymentMethod){
          found = true;
          localPaymentMethodParams.idShop = paymentMethodParams.idShop;
          localPaymentMethodParams.certificate = paymentMethodParams.certificate;
        }
        index++;
      }
      if(!found){
        this.license.subLicense.paymentsMethods.push(paymentMethodParams);
      }
      this.launchEditPaymentMethod(null);
      this.editLicense();
    }
  }

  updateLicenseById(license){
    for(var i = 0; i < this.licenses.length; i++){
      var localLicense = this.licenses[i];
      if(localLicense._id == license._id){
        this.licenses[i] = license;
      }
    }
    if(this.license._id == license._id){
      this.license = license;
    }
  }

  removeLicense(idLicense){
    var licenses = [];
    for(var i = 0; i < this.licenses.length; i++){
      var localLicense = this.licenses[i];
      if(localLicense._id != idLicense){
        licenses.push(localLicense);
      }
    }
    this.licenses = licenses;
  }

  copyIdLicense(idLicense){
    this.copyInClipboard(idLicense, function(){
      let snackBarRef = this.snackBar.open('Copié', 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this));
  }

  copyInClipboard(text, successCallback = null){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if(successCallback != null){
        successCallback();
    }
  }

  /**********************************************************************************
   ***************************** MAINTENANCE ****************************************
   ************************************************************************************/
  clearFormMaintenance(){
    this.formMaintenance = {
      _id:null,
      display:false,
      startNgbDate:null,
      endNgbDate:null,
      startTime:null,
      endTime:null,
      text:'',
      activated:true
    }
  }

  startDateChanged(date){
    if(this.formMaintenance.endNgbDate == null || date.after(this.formMaintenance.endNgbDate)){
      this.formMaintenance.endNgbDate = date;
    }
  }

  openFormMaintenance(){
    this.formMaintenance.display = true;
  }

  updateFormMaintenance(maintenance){
    this.formMaintenance._id = maintenance._id;
    var startDate = new Date(maintenance.startDate);
    var endDate = new Date(maintenance.endDate);
    this.formMaintenance.startNgbDate = { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() };
    this.formMaintenance.endNgbDate = { year: endDate.getFullYear(), month: endDate.getMonth() + 1, day: endDate.getDate() };
    this.formMaintenance.startTime = { hour: startDate.getHours(), minute: startDate.getMinutes(), second:0};
    this.formMaintenance.endTime = { hour: endDate.getHours(), minute: endDate.getMinutes(), second:0};
    this.formMaintenance.text = maintenance.text;
    this.formMaintenance.activated = maintenance.activated;
    this.openFormMaintenance();
  }

  createMaintenance(){
    var maintenance:any = {
      startDate:new Date(this.formMaintenance.startNgbDate.year, this.formMaintenance.startNgbDate.month -1, this.formMaintenance.startNgbDate.day, this.formMaintenance.startTime.hour, this.formMaintenance.startTime.minute, 0, 0),
      endDate:new Date(this.formMaintenance.endNgbDate.year, this.formMaintenance.endNgbDate.month -1, this.formMaintenance.endNgbDate.day, this.formMaintenance.endTime.hour, this.formMaintenance.endTime.minute, 0, 0),
      text:this.formMaintenance.text,
      activated:this.formMaintenance.activated
    }
    if(this.formMaintenance._id != null){
      maintenance._id = this.formMaintenance._id;
    }
    if(maintenance.startDate < maintenance.endDate){
      if(this.formMaintenance._id == null){
        this.actionLaunched = true;
        this.userService.put('api/v1/maintenance/:token', {maintenance:maintenance}, (maintenance) => {
          this.actionLaunched = false;
          this.maintenances.unshift(maintenance);
          this.clearFormMaintenance();
        }, (error) => {
          console.log(error);
          this.actionLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        });
      }
      else {
        this.actionLaunched = true;
        this.userService.post('api/v1/maintenance/:token', {maintenance:maintenance}, (maintenance) => {
          this.actionLaunched = false;
          for(var i = 0; i < this.maintenances.length; i++){
            if(this.maintenances[i]._id == maintenance._id){
              this.maintenances[i] = maintenance;
            }
          }
          this.clearFormMaintenance();
        }, (error) => {
          console.log(error);
          this.actionLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        });
      }
    }
    else {
      let snackBarRef = this.snackBar.open('Mauvaises dates', 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }
  }

  deleteMaintenance(maintenance){
    this.actionLaunched = true;
    this.userService.delete('api/v1/maintenance/:token/' + maintenance._id, (maintenance) => {
      this.actionLaunched = false;
      if(maintenance != null){
        this.maintenances = this.maintenances.filter((element) => { return element._id != maintenance._id });
      }
      this.clearFormMaintenance();
    }, (error) => {
      this.actionLaunched = false;
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    });
  }



}
