import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { server } from '../app/global';


import { ErrorsService } from './errors.service';
import { UserService } from './user.service';

/******
 * // OPTIMIZE: Use get and post
 *******/

@Injectable()
export class AdminService{
  private headers = new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8' });
  constructor(private userService:UserService, private http: HttpClient, private errorsService:ErrorsService) {

  }


  getLicenseById(idLicense, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.get(server + 'api/v1/licenses/' + this.userService.getToken() + '/' + idLicense)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback({message:'Error'});
    }
  }


  createLicense(newLicense, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.put(server + 'api/v1/license/create/' + this.userService.getToken(), newLicense, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback({message:'Error'});
    }
  }

  generateLicense(email, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.put(server + 'api/v1/license/generate/' + this.userService.getToken(), {email:email}, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback({message:'Error'});
    }
  }

  editLicense(license, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.post(server + 'api/v1/license/edit/' + this.userService.getToken(), {license:license}, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  editLicenseFiscalYearDates(idLicense, fiscalYearDates, successCallback = null, errorCallback = null){
    if(this.userService.isConnected() && fiscalYearDates != null && fiscalYearDates.start != null){
      this.http.post(server + 'api/v1/license/editDate/' + this.userService.getToken(), {idLicense:idLicense, fiscalYearDates:fiscalYearDates}, {
        headers: this.headers
      })
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  deleteLicense(idLicense, successCallback = null, errorCallback = null){
    if(this.userService.isConnected()){
      this.http.delete(server + 'api/v1/license/' + this.userService.getToken()+'/'+idLicense)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else if(errorCallback != null){
      errorCallback('Error');
    }
  }

  /**
   * get ticket by date
   */
  getNumberTicketsInMonth(date, idLicense, successCallback, errorCallback){
    if(idLicense != null &&  date != null){
      this.http.get(server + 'api/v1/ticketsInMonth/' + this.userService.getToken()+ '/' + idLicense + '/' + date)
      .subscribe(data => {
        if(successCallback != null){
          successCallback(data);
        }
      }, error => {
        if(errorCallback != null && error.status != 0){
          errorCallback(this.errorsService.getErrorText(error.error));
        }
        else {
          errorCallback(this.errorsService.getErrorTextByType('server_inaccessible'));
        }
      });
    }
    else errorCallback(this.errorsService.getErrorTextByType('unknown_params'));
  }

}
