import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'page-changelog',
  templateUrl: 'changelog.html',
  styleUrls:['./changelog.css']
})

export class ChangelogComponent implements OnInit {

  constructor(public userService: UserService, public navService:NavService, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {

    }
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }
}
