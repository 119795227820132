import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'page-menu',
  templateUrl: 'menu.html',
  styleUrls:['./menu.css']
})

export class MenuComponent implements OnInit {

  constructor(public userService: UserService, public navService:NavService, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      this.userService.initializeData(function(){ }, function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  getPseudo():string{
    var pseudo:string = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicenseName():string{
    var name:string = '';
    if(this.userService.haveLicense()){
      name = this.userService.getLicense().name;
    }
    return name;
  }

  isTestMode():boolean{
    return this.userService.isTestMode();
  }

  isExportActivated():boolean{
    var exportActivated:boolean = false;
    if(this.userService.haveLicense()){
      exportActivated = this.userService.getLicense().exportActivated;
    }
    return exportActivated;
  }

  haveCashRegisters():boolean{
    if(this.userService.getLicense() == null) return false;
    var cashRegisters = this.userService.getLicense().modelCashRegisters;
    for(var i = 0; i < cashRegisters.length; i++){
      var cashRegister = cashRegisters[i];
      if(cashRegister.test == this.isTestMode()) return true;
    }
    return false;
  }

  needChangeLogoBill():boolean{
    var hostname = window.location.hostname;
    return this.userService.getLicense() != null &&
      this.userService.getLicense().bill.logo.indexOf(hostname) == -1;
  }

  needCreateProducts():boolean{
    return this.userService.getProducts().length == 0;
  }

  disconnect(){
    this.userService.disconnect(function(){
      this.navService.toLoginPage();
    }.bind(this), function(error){
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this));
  }

  goAdministration(){
    this.navService.changeRoute('admin');
  }

  goLicense(){
    this.navService.changeRoute('license');
  }

  goCustomers(){
    this.navService.changeRoute('customers');
  }

  goBills(){
    this.navService.changeRoute('bills');
  }

  goCash(){
    if(this.haveCashRegisters()){
      this.navService.changeRoute('cash');
    }
  }

  cashButtonTooltip(){
    if(!this.haveCashRegisters()){
      return 'Veuillez créer une caisse dans l\'onglet administation pour accéder à l\'interface de caisse';
    }
    return '';
  }

  rapportsButtonTooltip(){
    if(!this.haveCashRegisters()){
      return 'Veuillez créer une caisse dans l\'onglet administation pour accéder aux rapports';
    }
    return '';
  }

  goProduct(){
    this.navService.changeRoute('product');
  }

  goRapports(){
    if(this.haveCashRegisters()){
      this.navService.changeRoute('rapports');
    }
  }

  goSettings(){
    this.navService.changeRoute('settings');
  }

  goExport(){
    this.navService.changeRoute('export');
  }

  goStats(){
    this.navService.changeRoute('stats');
  }

  goChangelog(){
    this.navService.changeRoute('changelog');
  }

  isAdmin(){
    return this.userService.isAdmin();
  }

  isVendor(){
    return this.userService.isVendor();
  }

  calculateBalance(customer){
    var balance = 0;
    if(customer.payments != null){
      for(var i = 0; i < customer.payments.length; i++){
        var payment = customer.payments[i];
        if(payment.state == 'ok'){
          balance += payment.value * 1;
        }
      }
    }
    customer.balance = balance;
  }
}
