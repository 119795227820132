import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls:['./login.css']
})

export class LoginComponent implements OnInit {
  public maintenance:any = null;
  public idLicense:string = '';
  public pseudo:string = '';
  public password:string = '';

  constructor(public userService: UserService, public navService:NavService,
  private route: ActivatedRoute, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    var idLicense = this.route.snapshot.paramMap.get('idLicense');
    if(idLicense){
      this.idLicense = idLicense;
    }
    this.userService.getWithoutConnection('api/v1/maintenance/', (maintenance) => {
      this.maintenance = maintenance;
    }, (error) => {
      let snackBarRef = this.snackBar.open(error.message, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    });
  }

  connection(){
    this.userService.authentification(this.pseudo, this.password, this.idLicense, this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(data){
    this.navService.changeRouteAfterLogin(this.userService);
  }

  errorCallback(error){
    let snackBarRef = this.snackBar.open(error, 'OK', {
      duration: 8000,
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }
}
