import {Input, Output, Component, Inject, EventEmitter } from '@angular/core';
	import { OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: 'loader.html',
  styleUrls:['./loader.css']
})

export class LoaderComponent {

	public display:boolean = false;

	constructor() {
		setTimeout(function(){
			this.display = true;
		}.bind(this), 250);
	}
}
