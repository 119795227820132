import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { DecimalPipe, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { AlertDialog } from '../../components/alertDialog/alertDialog';
import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';
import { PromptDialog } from '../../components/promptDialog/promptDialog';
import { TicketDialog } from '../../components/ticketDialog/ticketDialog';
import { FloatTabDialog } from '../../components/floatTabDialog/floatTabDialog';
import { BillDialog } from '../../components/billDialog/billDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { LicenseService } from '../../services/license.service';

import { TicketLite } from '../../model/ticket'

@Component({
  selector: 'page-rapports',
  templateUrl: 'rapports.html',
  styleUrls:['./rapports.css']
})

export class RapportsComponent implements OnInit {

  private subPage = 'current';
  private idCashRegister: null;
  private date = new Date();
  private dateEnd = new Date();
  private ticketsOpened:TicketLite[] = [];
  private ticketsOpenedFiltered:TicketLite[] = [];
  private filtersOpened = {type:'', status: '', payment:'', customer:''};
  private tickets:TicketLite[] = [];
  private ticketsFiltered:TicketLite[] = [];
  private zTickets:TicketLite[] = [];
  private filters = {type:'', status:'', payment:'', customer:''};
  private allAnotherPayments = [];
  private months = [];
  private selectedMonth = null;
  private forceFiscalClose = false;

  private floatTotal = null;
  private floatSum = null;
  private formContribution = {type:'contribution', amount:0, note:''};
  private formDebit = {type:'debit', amount:0, note:''};
  private formCorrection = {type:'correction', amount:0, note:''};
  private formFloatMovements = {startDate:new Date(), endDate:new Date()};

  private currencies = [{
    image:'assets/images/euro/1ct.jpg',
    title:'Pièces de 0.01€',
    number:0,
    amount:0.01
  },{
    image:'assets/images/euro/2ct.jpg',
    title:'Pièces de 0.02€',
    number:0,
    amount:0.02
  },{
    image:'assets/images/euro/5ct.jpg',
    title:'Pièces de 0.05€',
    number:0,
    amount:0.05
  },{
    image:'assets/images/euro/10ct.jpg',
    title:'Pièces de 0.10€',
    number:0,
    amount:0.10
  },{
    image:'assets/images/euro/20ct.jpg',
    title:'Pièces de 0.20€',
    number:0,
    amount:0.20
  },{
    image:'assets/images/euro/50ct.jpg',
    title:'Pièces de 0.50€',
    number:0,
    amount:0.50
  },{
    image:'assets/images/euro/1e.jpg',
    title:'Pièces de 1€',
    number:0,
    amount:1
  },{
    image:'assets/images/euro/2e.jpg',
    title:'Pièces de 2€',
    number:0,
    amount:2
  },{
    image:'assets/images/euro/5e.jpg',
    title:'Billets de 5€',
    number:0,
    amount:5
  },{
    image:'assets/images/euro/10e.jpg',
    title:'Billets de 10€',
    number:0,
    amount:10
  },{
    image:'assets/images/euro/20e.jpg',
    title:'Billets de 20€',
    number:0,
    amount:20
  },{
    image:'assets/images/euro/50e.jpg',
    title:'Billets de 50€',
    number:0,
    amount:50
  },{
    image:'assets/images/euro/100e.jpg',
    title:'Billets de 100€',
    number:0,
    amount:100
  },{
    image:'assets/images/euro/200e.jpg',
    title:'Billets de 200€',
    number:0,
    amount:200
  },{
    image:'assets/images/euro/500e.jpg',
    title:'Billets de 500€',
    number:0,
    amount:500
  }];

  private displayCountFloat = false;

  public getTicketsByDatesLaunched = false;
  public getZTicketsByDatesLaunched = false;
  public getXTicketLaunched = false;
  public closingOutLaunched = false;
  public getCurrentFloatLaunched = false;
  public floatOperationLanched = false;
  public loadBillLaunched = false;
  //private currentTicket:TicketLite = null;

  constructor(public userService: UserService, public navService:NavService,
    public licenseService:LicenseService, public dialog: MatDialog,
    private route: ActivatedRoute, private datePipe: DatePipe, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected()){
      this.navService.toLoginPage();
    }
    else {
      this.setDate(new Date());
      this.userService.initializeData(function(){
        this.allAnotherPayments = this.calculateAllAnotherPayments();
        var idCashRegister = this.route.snapshot.paramMap.get('idCashRegister');
        var date = this.route.snapshot.paramMap.get('date') * 1;
        var subPage = this.route.snapshot.paramMap.get('subPage');
        if(idCashRegister && date){
          this.idCashRegister = idCashRegister;
          this.changeCashRegister();
          if(subPage != 'float' && this.cashRegisterIsOpened(this.getCashRegisterById(idCashRegister))) {
            this.getTicketsOpened();
          }
          else {
            this.getCurrentFloat();
          }
        }
        if(subPage){
          this.subPage = subPage;
        }
        this.setStartDateFloatMovement();
      }.bind(this), function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }

  goCashRegister(){
    this.navService.changeRoute('cash');
  }

  goAdministration(){
    this.navService.changeRoute('admin');
  }

  isAdmin(){
    return this.userService.isAdmin();
  }

  isVendor(){
    return this.userService.isVendor();
  }

  isTestMode(){
    return this.userService.isTestMode();
  }

  isCurrentStateSubPage(){ return this.subPage == 'current'; }
  isClosingOutSubPage(){ return this.subPage == 'closingOut'; }
  isJournalSubPage(){ return this.subPage == 'journal'; }
  isFloatSubPage(){ return this.subPage == 'float'; }
  setCurrentStateSubPage(){ this.subPage = 'current'; }
  setClosingOutSubPage(){ this.subPage = 'closingOut'; }
  setJournalSubPage(){ this.subPage = 'journal'; }
  setFloatSubPage(){ this.subPage = 'float'; }

  setDateWithOpenDate(){
    if(this.idCashRegister != null){
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      if(cashRegister != null){
        this.setDate(new Date(cashRegister.openDate));
      }
    }
  }

  cashRegisterIsOpened(cashRegister){
    if(cashRegister == null) return false;
    return cashRegister.openDate != null && (cashRegister.closeDate == null ||
      new Date(cashRegister.openDate).getTime() > new Date(cashRegister.closeDate).getTime());
  }

  changeCashRegister(){
    this.setDateWithOpenDate();
    this.calculateMonths();
    this.clearFloats();
  }

  setDate(date){
    this.date = new Date(date);
    this.setDateEndWithDate();
  }

  setDateEndWithDate(){
    if(this.clearTime(new Date(this.dateEnd)).getTime() <= this.clearTime(new Date(this.date)).getTime()){
      this.dateEnd = new Date(this.date);
      this.dateEnd.setDate(this.dateEnd.getDate() + 1);
    }
  }

  licenseHaveVatErrorReductionEurosV1_6_1(){
    var license = this.getLicense();
    if(license == null) return false;
    var date = new Date(2018, 6, 29);
    var actuelDate = new Date(license.registerDate);
    return actuelDate.getTime() <= date.getTime();
  }

  calculateErrorVatReductionEurosV1_6_1(){
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '350px',
      data: {
        title:'Calculer l\'erreur de TVA ?',
        text: 'Ce calcul peut prendre un certain temps (environ 1min), voulez-vous le réaliser ?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getTicketsByDatesLaunched = true;
        this.userService.calculateErrorVatReductionEurosV1_6_1(function(data){
          this.getTicketsByDatesLaunched = false;
          var text = '';
          for(var i = 0; i < data.length; i++){
            text += data[i].name + '(' + data[i].value +'%) - écart : ' +  data[i].total + '€<br />';
          }
          if(text == '') text = 'Aucun ticket';
          this.dialog.open(AlertDialog, {
            width: '350px',
            data: {
              title:'Résultat de l\'erreur par TVA : ',
              text: text
            }
          });

        }.bind(this), function(error){
          this.getTicketsByDatesLaunched = false;
          let snackBarRef = this.snackBar.open(error, 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }.bind(this));
      }
    });
  }

  getLicense(){
    return this.userService.getLicense();
  }

  getPseudo():String{
    var pseudo:String = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicenseName():String{
    var name:String = '';
    if(this.userService.haveLicense()){
      name = this.userService.getLicense().name;
    }
    return name;
  }

  getCashRegisters(){
    var cashRegisters = [];
    if(this.userService.haveLicense()){
      for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
        var cashRegister = this.getLicense().modelCashRegisters[i];
        if(cashRegister.test == this.isTestMode()){
          cashRegisters.push(cashRegister);
        }
      }
    }
    return cashRegisters;
  }

  getCashRegisterById(idCashRegisters){
    var cashRegisters = [];
    if(this.userService.haveLicense()){
      for(var i = 0; i < this.getLicense().modelCashRegisters.length; i++){
        var cashRegister = this.getLicense().modelCashRegisters[i];
        if(cashRegister._id == idCashRegisters){
          return cashRegister;
        }
      }
    }
    return null;
  }


  getPaymentsTypes(){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(this.idCashRegister != null && this.idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }

  getPaymentsTypesByIdCashRegister(idCashRegister){
    var license = this.userService.getLicense();
    for(var i = 0; i < license.paymentsTypes.length; i++){
      var paymentsType = license.paymentsTypes[i];
      if(idCashRegister == paymentsType.idCashRegister){
        return paymentsType;
      }
    }
    return null;
  }

  isTicketCancelled(idTicket, tickets){
    for(var i = 0; i < tickets.length; i++){
      var ticket = tickets[i];
      if(ticket.idTickets.indexOf(idTicket) != -1){
        return ticket;
      }
    }
    return null;
  }

  getTicketsOpened(){
    if(this.idCashRegister != null && !this.getTicketsByDatesLaunched){
      //this.currentTicket = null;
      this.getTicketsByDatesLaunched = true;
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      var openDate = new Date(cashRegister.openDate);
      this.userService.getTicketsByDate(this.idCashRegister, openDate, new Date(), function(tickets){
        if(tickets.length == 0){
          let snackBarRef = this.snackBar.open('Aucun ticket', 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }
        this.ticketsOpened = [];
        for(var i = 0; i < tickets.length; i++){
          var ticket = this.formatTicket(tickets[i], this.ticketsOpened);
          this.ticketsOpened.push(ticket);
        }
        this.calculateTicketsOpenedFiltered();
        this.getTicketsByDatesLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.getTicketsByDatesLaunched = false;
      }.bind(this));
    }
  }

  /**
   * format ticket
   */
  formatTicket(ticket, tickets){
    ticket = new TicketLite(ticket);
    var ticketCancelled = this.isTicketCancelled(ticket._id, tickets);
    if(ticketCancelled != null){
      ticket.setOkButCancelled(ticketCancelled.cause);
    }
    return ticket;
  }

  getTicketById(idTicket, tickets){
    for(var i = 0; i < tickets.length; i++){
      var ticket = tickets[i];
      if(ticket._id == idTicket){
        return ticket;
      }
    }
    return null;
  }

  /**
   *
   */
  getDaysBetweenTwoDates(startDate, endDate){
    var diffSeconds = endDate.getTime() - startDate.getTime();
    return diffSeconds / (1000 * 60 * 60 * 24);
  }

  getTicketsByDates(){
    //this.currentTicket = null;
    var startDate = this.clearTime(new Date(this.date));
    var endDate = this.clearTime(new Date(this.dateEnd));
    var days = this.getDaysBetweenTwoDates(startDate, endDate);
    if(!this.getTicketsByDatesLaunched && days <= 35){
      this.getTicketsByDatesLaunched = true;
      var idCashRegister = -1;
      if(this.idCashRegister != null) { idCashRegister = this.idCashRegister; }
      this.userService.getTicketsByDate(idCashRegister, startDate, endDate, function(tickets){
        if(tickets.length == 0){
          let snackBarRef = this.snackBar.open('Aucun ticket', 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }
        this.tickets = [];
        for(var i = 0; i < tickets.length; i++){
          var ticket = this.formatTicket(tickets[i], this.tickets);
          this.tickets.push(ticket);
        }
        this.calculateTicketsFiltered();
        this.getTicketsByDatesLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.getTicketsByDatesLaunched = false;
      }.bind(this));
    }
    else if(days > 35){
      this.dialog.open(AlertDialog, {
        width: '350px',
        data: {
          title:'Période trop longue',
          text: 'Veuillez sélectionner une période <span class="s_danger s_bold">de moins de 35 jours</span>.'
        }
      });
    }
  }

  getZTicketsByDates(){
    //this.currentTicket = null;
    var startDate = this.clearTime(new Date(this.date));
    var endDate = this.clearTime(new Date(this.dateEnd));
    var days = this.getDaysBetweenTwoDates(startDate, endDate);
    if(!this.getZTicketsByDatesLaunched && days <= 35){
      this.getZTicketsByDatesLaunched = true;
      var idCashRegister = -1;
      if(this.idCashRegister != null) { idCashRegister = this.idCashRegister; }
      this.userService.getZTicketsByDate(idCashRegister, startDate, endDate, function(tickets){
        if(tickets.length == 0){
          let snackBarRef = this.snackBar.open('Aucun ticket', 'OK', {
            duration: 8000,
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        }
        this.zTickets = [];
        for(var i = 0; i < tickets.length; i++){
          var ticket = new TicketLite(tickets[i]);
          this.zTickets.push(ticket);
        }
        this.getZTicketsByDatesLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.getZTicketsByDatesLaunched = false;
      }.bind(this));
    }
    else if(days > 35){
      this.dialog.open(AlertDialog, {
        width: '350px',
        data: {
          title:'Période trop longue',
          text: 'Veuillez sélectionner une période <span class="s_danger s_bold">de moins de 35 jours</span>.'
        }
      });
    }
  }

  calculateCA(tickets){
    var total = 0;
    for(var i = 0; i < tickets.length; i++){
      var ticket = tickets[i];
      if(ticket.isSimpleTicket()){
        total += (ticket.totalTTCPrice * 1);
      }
    }
    return total;
  }

  cancelTicket(ticket){
    if(ticket != null){
      let dialogRef = this.dialog.open(PromptDialog, {
        width: '350px',
        data: {
          title:'Annuler le ticket n°' + ticket.id + ' ?',
          text: 'Pour pouvoir annuler le ticket n°' + ticket.id +' veuillez entrer une cause  ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.userService.cancelTicket(ticket._id, result.note, function(data){
            this.ticketsOpened.unshift(new TicketLite(data.ticket));
            for(var i = 0; i < data.ticket.idTickets.length; i++){
              var ticketCancelled = this.getTicketById(data.ticket.idTickets[i], this.ticketsOpened);
              if(ticketCancelled != null){
                ticketCancelled.setOkButCancelled(data.ticket.cause);
              }
            }
            this.calculateTicketsOpenedFiltered();
            this.userService.updateCashRegisterInLicense(data.cashRegister);
            this.clearFloats();
          }.bind(this), function(error){
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  roundPrice(price){
    return (price*1).toFixed(2);
  }

  clearTime(date:Date){
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  canClosingOutCashRegister(){
    var isOpen = false;
    if(this.idCashRegister != null &&  this.date != null){
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      if(cashRegister != null){
        var openDate = new Date(cashRegister.openDate);
        isOpen = cashRegister.openDate != null && (cashRegister.closeDate == null ||
          new Date(cashRegister.openDate).getTime() > new Date(cashRegister.closeDate).getTime());
      }
    }
    return isOpen;
  }

  isAlreadyClosedDate(date){
    var alreadyClosedDate = false;
    if(this.idCashRegister != null &&  this.date != null){
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      if(cashRegister != null){
        alreadyClosedDate = (cashRegister.closeDate == null || date.getTime() < new Date(cashRegister.closeDate).getTime());
      }
    }
    return alreadyClosedDate;
  }

  closingOutCashRegister(){
    if(this.idCashRegister != null &&  this.date != null && !this.closingOutLaunched){
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      if(cashRegister != null){
        var date = new Date(cashRegister.openDate);
        var actualDate = new Date();
        var dateCopy = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        var actualDateCopy = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), 0, 0, 0, 0);
        var dateString = this.datePipe.transform(new Date(cashRegister.openDate), 'fullDate', '', 'fr');
        var alert = '';
        if(dateCopy.getTime() < actualDateCopy.getTime()){
          alert = '<br /><span class="s_danger s_bold">Attention ceci est un cloture définitive de la journée du ' + dateString + '</span>';
        }
        let dialogRef = this.dialog.open(ConfirmDialog, {
          width: '350px',
          data: {
            title:'Clôturer la caisse ' + cashRegister.name + ' ?',
            text: 'Voulez-vous vraiment clôturer la journée du ' + dateString +' ? ' + alert +''
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.closingOutLaunched = true;
            this.userService.closingOutCashRegister(this.idCashRegister, false, function(data){
              //this.ticket.push(new TicketLite(data.ticketZ));
              this.openTicket(this.formatTicket(data.ticketZ, this.tickets));
              this.clearFloats();
              this.closingOutLaunched = false;
            }.bind(this), function(error){
              let snackBarRef = this.snackBar.open(error, 'OK', {
                duration: 8000,
              });
              snackBarRef.onAction().subscribe(() => {
                snackBarRef.dismiss();
              });
              this.closingOutLaunched = false;
            }.bind(this));
          }
        });
      }
    }
  }

  closingOutDateCashRegister(){
    if(this.idCashRegister != null &&  this.date != null && !this.closingOutLaunched){
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      if(cashRegister != null){
        var openDate = new Date(cashRegister.openDate);
        var displayDate = this.datePipe.transform(openDate, 'fullDate', '', 'fr');
        let dialogRef = this.dialog.open(ConfirmDialog, {
          width: '350px',
          data: {
            title:'Clôturer la caisse ' + cashRegister.name + ' ?',
            text: 'Voulez-vous vraiment clôturer définitivement la journée du ' + displayDate +' ?'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.closingOutLaunched = true;
            this.userService.closingOutCashRegister(this.idCashRegister, true, function(data){
              //this.ticket.push(new TicketLite(data.ticketZ));
              this.openTicket(this.formatTicket(data.ticketZ, this.tickets));
              this.clearFloats();
              this.closingOutLaunched = false;
            }.bind(this), function(error){
              let snackBarRef = this.snackBar.open(error, 'OK', {
                duration: 8000,
              });
              snackBarRef.onAction().subscribe(() => {
                snackBarRef.dismiss();
              });
              this.closingOutLaunched = false;
            }.bind(this));
          }
        });
      }
    }
  }

  closingOutMonthCashRegister(){
    if(this.idCashRegister != null &&  this.date != null && !this.closingOutLaunched){
      var cashRegister = this.getCashRegisterById(this.idCashRegister);
      if(cashRegister != null && this.isGoodMonthSelected()){
        var month = this.datePipe.transform(new Date(cashRegister.lastMonthCloseDate), 'MMMM', '', 'fr');
        var actualDate = new Date();
        var alert = '';
        if(new Date(cashRegister.lastMonthCloseDate).getMonth() == actualDate.getMonth()){
          alert = '<br /><span class="s_danger s_bold">Attention ceci est le mois en cours, êtes-vous sur de vouloir le clôturer ?</span>';
        }


        let dialogRef = this.dialog.open(ConfirmDialog, {
          width: '350px',
          data: {
            title:'Clôturer la caisse ' + cashRegister.name + ' ?',
            text: 'Voulez-vous vraiment clôturer le mois de ' + month +' ?' + alert
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.closingOutLaunched = true;
            this.userService.closingOutMonthCashRegister(this.idCashRegister, function(data){
              this.openTicket(this.formatTicket(data.bigZTicket, this.tickets));
              this.closingOutLaunched = false;
            }.bind(this), function(error){
              let snackBarRef = this.snackBar.open(error, 'OK', {
                duration: 8000,
              });
              snackBarRef.onAction().subscribe(() => {
                snackBarRef.dismiss();
              });
              this.closingOutLaunched = false;
            }.bind(this));
          }
        });
      }
    }
  }

  calculateMonths(){
    var actualDate = this.clearTime(new Date());
    var months = [];
    var cashRegister = this.getCashRegisterById(this.idCashRegister);
    if(cashRegister != null){
      var actualDate = new Date();
      var date = this.clearTime(new Date(cashRegister.lastMonthCloseDate));
      date.setDate(1);
      while(date <= actualDate){
        months.push(new Date(date));
        date.setMonth(date.getMonth() + 1);
      }
    }
    this.months = months;
  }

  isGoodMonthSelected(){
    var cashRegister = this.getCashRegisterById(this.idCashRegister);
    if(cashRegister == null || this.selectedMonth == null) return false;
    return this.selectedMonth.getMonth() == new Date(cashRegister.lastMonthCloseDate).getMonth();
  }

  closingOutYearCashRegister(){
    if(!this.closingOutLaunched && (!this.needToForceFiscalClose() || this.forceFiscalClose)){
      var yearDates = this.getFiscalYearDates();
      if(yearDates.end == null){
        yearDates = {start:this.getDefaultYearStartDates(), end: this.getDefaultYearEndDates()};
      }
      var startDate = this.datePipe.transform(new Date(yearDates.start), 'dd MMMM yyyy', '', 'fr');
      var endDate = this.datePipe.transform(new Date(yearDates.end), 'dd MMMM yyyy', '', 'fr');

      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: {
          title:'Clôturer l\'année fiscale ?',
          text: 'Voulez-vous vraiment clôturer l\'année fiscale du ' + startDate + ' au ' + endDate + ' ? ' +
          '<br /><span class="s_danger s_bold">Vous ne pourrez plus ouvrir la caisse avant la date du ' + endDate +' !</span>'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.closingOutLaunched = true;
          this.userService.closingOutYearCashRegister(this.idCashRegister, this.forceFiscalClose, function(data){
            this.openTicket(this.formatTicket(data.bigBigZTicket, this.tickets));
            this.closingOutLaunched = false;
            this.forceFiscalClose = false;
          }.bind(this), function(error){
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
            this.forceFiscalClose = false;
            this.closingOutLaunched = false;
          }.bind(this));
        }
      });
    }
  }

  getFiscalYearDates(){
    var yearDates = {start:null, end:null};
    var license = this.getLicense();
    if(license != null && license.fiscalYearDates != null){
      yearDates = license.fiscalYearDates;
    }
    return yearDates;
  }

  getDefaultYearStartDates(){
    var actualDate = new Date();
    var date = new Date(actualDate.getFullYear(), 0, 1, 0, 0, 0, 0);
    return date;
  }

  getDefaultYearEndDates(){
    var actualDate = new Date();
    var date = new Date(actualDate.getFullYear(), 11, 31, 23, 59, 59, 99);
    return date;
  }

  noDisplayFiscalClose(){
    var yearDates = this.getFiscalYearDates();
    var endDate = this.getDefaultYearEndDates()
    if(yearDates != null && yearDates.end != null){
      endDate = new Date(yearDates.end);
    }
    var actualDate = new Date();
    return endDate.getFullYear() > actualDate.getFullYear();
  }

  needToForceFiscalClose(){
    var yearDates = this.getFiscalYearDates();
    var endDate = this.getDefaultYearEndDates()
    if(yearDates != null && yearDates.end != null){
      endDate = new Date(yearDates.end);
    }
    var actualDate = new Date();
    var diff = endDate.getTime() - actualDate.getTime();
    return diff > 24 * 3600 * 30 * 1000;
  }


  getXTicket(){
    if(this.idCashRegister != null && !this.getXTicketLaunched){
      this.getXTicketLaunched = true;
      this.userService.getXTicket(this.idCashRegister, function(ticket){
        this.openTicket(this.formatTicket(ticket, this.ticketsOpened));
        this.getXTicketLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.getXTicketLaunched = false;
      }.bind(this));
    }
  }

  openTicket(ticket){
    if(ticket){
      var paymentsType = this.getPaymentsTypesByIdCashRegister(ticket.idCashRegister);
      var anotherPayments = []
      if(paymentsType != null){
        anotherPayments = paymentsType.anotherPayments;
      }
      let dialogRef = this.dialog.open(TicketDialog, {
        width: '80vw',
        height: '90%',
        data: {
          ticket:ticket,
          anotherPayments:anotherPayments
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'openBill'){
          this.openBill(ticket.idBill);
        }
        else if(result == 'generateBill'){
          this.generateBillWithTicket(ticket);
        }
      });
    }
  }


  generateBillWithTicket(ticket){
    if(!this.loadBillLaunched && ticket != null){
      this.loadBillLaunched = true;
      this.userService.generateBillWithTicket(ticket._id, function(bill){
        this.loadBillLaunched = false;
        ticket.idBill = bill._id;
        this.openBillDialog(bill);
      }.bind(this), function(error){
        this.loadBillLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  canCancellableOnlyByBill(bill){
    var cashRegisters = this.userService.getCashRegisterById(bill.ticket.idCashRegister);
    return new Date(bill.ticket.date) <= new Date(cashRegisters.closeDate);
  }

  openBill(idBill){
    if(!this.loadBillLaunched && idBill){
      this.loadBillLaunched = true;
      this.userService.getBill(idBill, function(bill){
        this.loadBillLaunched = false;
        this.openBillDialog(bill);
      }.bind(this), function(error){
        this.loadBillLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  openBillDialog(bill){
    if(bill != null){
      var anotherPayments = this.allAnotherPayments;
      var displayCancelButton = this.canCancellableOnlyByBill(bill);
      let dialogRef = this.dialog.open(BillDialog, {
        width: '80vw',
        height: '90%',
        data: {
          bill:bill,
          anotherPayments:anotherPayments,
          displayCancelButton:displayCancelButton
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'payBill'){
          this.goCash(bill);
        }
        else if(result == 'cancelBill'){
          this.goCancel(bill);
        }
      });
    }
  }

  goCash(bill){
    if(bill != null){
      this.navService.changeRoute('cash', {bill:bill._id});
    }
  }

  goCancel(bill){
    if(bill != null){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Annuler la facture n°' + bill.id + ' ?',
          text: 'Voulez-vous vraiment annuler la facture n°' + bill.id + ' ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
            this.navService.changeRoute('cash', {bill:bill._id, type:'cancel'});
          }
      });
    }
  }


  calculateAllAnotherPayments(){
    var alreadyIds = [];
    var allAnotherPayments = [];
    var cashRegisters = this.getCashRegisters();
    for(var i = 0; i < cashRegisters.length; i++){
      var cashRegister = cashRegisters[i];
      var paymentsType = this.getPaymentsTypesByIdCashRegister(cashRegister._id);
      var anotherPayments = [];
      if(paymentsType != null){
        anotherPayments = paymentsType.anotherPayments;
      }
      for(var j = 0; j < anotherPayments.length; j++){
        var anotherPayment = anotherPayments[j];
        if(alreadyIds.indexOf(anotherPayment.id) == -1){
          allAnotherPayments.push(anotherPayment);
          alreadyIds.push(anotherPayment.id);
        }
      }
    }
    return allAnotherPayments;
  }

  calculateCACashRegister(){
    var total = 0;
    var cashRegister = this.getCashRegisterById(this.idCashRegister);
    if(cashRegister != null){
      for(var i = 0; i < cashRegister.payments.length; i++){
        var payment = cashRegister.payments[i];
        total += (payment.amount * 1);
      }
    }
    return total;
  }

  calculateTicketsOpenedFiltered(){
    this.ticketsOpenedFiltered = [];
    for(var i = 0; i < this.ticketsOpened.length; i++){
      var ticket = this.ticketsOpened[i];
      if(this.isOkFilter(ticket, this.filtersOpened)){
        this.ticketsOpenedFiltered.push(ticket);
      }
    }
  }

  calculateTicketsFiltered(){
    this.ticketsFiltered = [];
    for(var i = 0; i < this.tickets.length; i++){
      var ticket = this.tickets[i];
      if(this.isOkFilter(ticket, this.filters)){
        this.ticketsFiltered.push(ticket);
      }
    }
  }

  sumTotalTicketsReceipts(){
    var total = 0;
    for(var i = 0; i < this.ticketsFiltered.length; i++){
      var ticket = this.ticketsFiltered[i];
      total += ticket.displayTotalPrice();
    }
    return total;
  }

  private isOkFilter(ticket, filters){
    var ok = filters.type == '' || filters.type == ticket.type || ticket.isZTicket() || ticket.isBigZTicket() || ticket.isBigBigZTicket();
    if(ok){
      ok = filters.status == '' || (filters.status == 'ok' && ticket.isOk()) || filters.status == 'cancelled' && (ticket.isCancelled() || ticket.isOkButCancelled());
    }
    if(ok){
      ok = filters.payment == '' || ticket.havePaymentType(filters.payment);
    }
    if(ok){
      ok = ticket.settings.customer.toLowerCase().trim().indexOf(filters.customer.toLowerCase().trim()) != -1;
    }
    return ok;
  }

  clearFloats(){
    this.floatTotal = null;
    this.floatSum = null;
  }

  isFloatCalculated(){
    return this.floatTotal != null
  }

  getCurrentFloat(){
    if(this.idCashRegister != null && !this.getCurrentFloatLaunched){
      this.getCurrentFloatLaunched = true;
      this.userService.getCurrentFloat(this.idCashRegister, function(data){
        this.floatTotal = data.floatTotal;
        this.floatSum = data.floatSum;
        this.getCurrentFloatLaunched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.getCurrentFloatLaunched = false;
      }.bind(this));
    }
  }

  makeAnOperation(form){
    if(this.idCashRegister != null && !this.floatOperationLanched){
      var title = 'Faire un apport ?';
      if(form.type == 'debit') title = 'Faire un prélèvement ?';
      if(form.type == 'correction') title = 'Faire une correction ?';
      var message = 'Voulez-vous vraiment faire un apport ?';
      if(form.type == 'debit') message = 'Voulez-vous vraiment faire un prélèvement ?';
      if(form.type == 'correction') message = 'Voulez-vous vraiment faire une correction ?';
      message += '<em>(Note facultative)</em>';
      let dialogRef = this.dialog.open(PromptDialog, {
        width: '350px',
        data: {
          title:title,
          text: message,
          optional:true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.floatOperationLanched = true;
          form.note = result.note;
          this.userService.makeAnOperation(this.idCashRegister, form, function(data){
            form.amount = 0;
            this.floatTotal = data.floatTotal;
            this.floatSum = data.floatSum;
            this.floatOperationLanched = false;
          }.bind(this), function(error){
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
            this.floatOperationLanched = false;
          }.bind(this));
        }
      });
    }
  }

  setStartDateFloatMovement(){
    if(this.clearTime(new Date(this.formFloatMovements.endDate)).getTime() <= this.clearTime(new Date(this.formFloatMovements.startDate)).getTime()){
      this.formFloatMovements.endDate = new Date(this.formFloatMovements.startDate);
      this.formFloatMovements.endDate.setDate(this.formFloatMovements.endDate.getDate() + 1);
    }
  }


  getFloatMovements(){
    if(this.idCashRegister != null && !this.floatOperationLanched){
      this.floatOperationLanched = true;
      this.userService.getFloatMovements(this.idCashRegister, this.clearTime(this.formFloatMovements.startDate), this.clearTime(this.formFloatMovements.endDate), function(allFloats){
        let dialogRef = this.dialog.open(FloatTabDialog, {
          width: '80vw',
          height: '90%',
          data: {
            allFloats:allFloats
          }
        });
        this.floatOperationLanched = false;
      }.bind(this), function(error){
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
        this.floatOperationLanched = false;
      }.bind(this));
    }
  }

  saveCorrection(){
    if(this.idCashRegister != null && !this.floatOperationLanched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '350px',
        data: {
          title:'Faire une correction ?',
          text: 'Voulez-vous vraiment faire cette correction ?'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.floatOperationLanched = true;
          var form = {
            type:'correction',
            amount:this.diffCurrencies(),
            note:this.formCorrection.note
          }
          this.userService.makeAnOperation(this.idCashRegister, form, function(data){
            this.floatTotal = data.floatTotal;
            this.floatSum = data.floatSum;
            this.floatOperationLanched = false;
            this.formCorrection.note = '';
            this.closeCountFloat();
          }.bind(this), function(error){
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
            this.floatOperationLanched = false;
          }.bind(this));

        }
      });
    }
  }

  openCountFloat(){
    this.displayCountFloat = true;
    for(var i = 0; i < this.currencies.length; i++){
      var money = this.currencies[i];
      money.number = 0;
    }
  }

  closeCountFloat(){
    this.displayCountFloat = false;
  }

  sumCurrencies(){
    var sum = 0;
    for(var i = 0; i < this.currencies.length; i++){
      var money = this.currencies[i];
      sum += Number((money.number * money.amount * 1).toFixed(2));
    }
    return sum;
  }

  diffCurrencies(){
    return this.sumCurrencies() - this.floatTotal;
  }
}
