import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { DecimalPipe } from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';

import { ConfirmDialog } from '../../components/confirmDialog/confirmDialog';

import { UserService } from '../../services/user.service';
import { NavService } from '../../services/nav.service';
import { LicenseService } from '../../services/license.service';

@Component({
  selector: 'page-product',
  templateUrl: 'product.html',
  styleUrls:['./product.css']
})

export class ProductComponent implements OnInit {

  private subPage = 'products';
  private filterProducts = {search:'', category:null, synchronized:'all'};
  private displayProducts = 'grid';
  private displayProductsRESA = 'grid';
  private displayNameServices = true;
  private filterRESAService = {place:''};

  private editPositionProducts = false;
  private productsToSaved = [];

  private formProduct = {name:'', color:'', publicName:'', reference:'', price:0, priceTTC:0, tva:0, outlay:false, categories:[], slugs: [], thresholded:false, thresholdType:0, thresholdPrices:[], hours:false, synchronized:false, generated:false};
  private formProductDisplayTTC = true;
  private formProductAddCategory = null;
  private formProductSlugs = {service:'', servicePrice:'', number:1};
  private currentEditProduct = null;
  private services:any[]  = [];
  private places:any[] = [];
  public actionLaunched = false;
  private resaProducts = [];

  private dataCategories = null;
  private currentEditCategory = null;
  private formCategory = {name:'', color:'', parent:null};
  private filtersCategories = {name:''};
  private editPositionCategories = false;

  private dataGroups = null;
  private currentEditGroup = null;
  private formGroup = {name:'', color:'', publicName:'', categories:[], products:[]};
  private filtersGroups = {name:''};
  private editPositionGroups = false;

  private formGroupAddCategory = null;
  private formGroupAddProduct = null;

  constructor(public userService: UserService, public navService:NavService, public licenseService:LicenseService, public dialog: MatDialog, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    if(!this.userService.isConnected() || this.userService.isVendor()){
      this.navService.toLoginPage();
    }
    else {
      this.userService.initializeData(function(){
        this.loadRESAServices();
      }.bind(this), function(error){
        this.navService.toLoginPage();
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  goMenu(){
    this.navService.changeRoute('menu');
  }

  getLicense(){
    return this.userService.getLicense();
  }


  getPseudo():String{
    var pseudo:String = '';
    if(this.userService.isConnected()){
      pseudo = this.userService.getUser().pseudo;
    }
    return pseudo;
  }

  getLicenseName():String{
    var name:String = '';
    if(this.userService.haveLicense()){
      name = this.userService.getLicense().name;
    }
    return name;
  }

  getProducts(){
    return this.userService.getProducts();
  }

  getProductsFiltered(){
    var products = this.userService.getProducts();
    var productsFiltered = [];
    for(var i = 0; i < products.length; i++){
      var product = products[i];
      if(this.applyFiltersProduct(product)){
        productsFiltered.push(product);
      }
    }
    return productsFiltered;
  }

  /**
   * return true if product is in filters
   */
  applyFiltersProduct(product):boolean{
    var ok = true;
    if(this.filterProducts.category != null){
      ok = product.categories.indexOf(this.filterProducts.category._id) != -1;
    }
    if(this.filterProducts.synchronized != 'all'){
      ok = (product.slugs.length > 0 && this.filterProducts.synchronized == 'synchronized') ||
        (product.slugs.length == 0 && this.filterProducts.synchronized == 'notSynchronized')
    }
    if(ok && this.filterProducts.search.length > 0){
      ok = ok && product.name.toUpperCase().indexOf(this.filterProducts.search.toUpperCase())!=-1 ||
          product.publicName.toUpperCase().indexOf(this.filterProducts.search.toUpperCase())!=-1 ||
          product.reference.toUpperCase().indexOf(this.filterProducts.search.toUpperCase())!=-1;
    }
    return ok;
  }

  getProductById(idProduct){
    var products = this.getProducts();
    for(var i = 0; i < products.length; i++){
      var product = products[i];
      if(product._id == idProduct){
        return product;
      }
    }
    return null;
  }

  setProductsSubPage(){ this.subPage = 'products'; }
  setProductSubPage(){ this.subPage = 'product'; }
  setCategoriesSubPage(){ this.subPage = 'categories'; this.createDataCategories(); }
  setCategorySubPage(){ this.subPage = 'category'; }
  setGroupsSubPage(){ this.subPage = 'groups';  this.createDataGroups(); }
  setGroupSubPage(){ this.subPage = 'group'; }

  isProductsSubPage(){ return this.subPage == 'products' || this.subPage == ''; }
  isProductSubPage(){ return this.subPage == 'product'; }
  isCategoriesSubPage(){ return this.subPage == 'categories'; }
  isCategorySubPage(){ return this.subPage == 'category'; }
  isGroupsSubPage(){ return this.subPage == 'groups'; }
  isGroupSubPage(){ return this.subPage == 'group'; }

  isDisplayProductsGrid(){ return this.displayProducts == 'grid'; }
  isDisplayProductsList(){ return this.displayProducts == 'list'; }
  setDisplayProductsGrid(){ this.displayProducts = 'grid'; }
  setDisplayProductsList(){ this.displayProducts = 'list'; }

  isDisplayProductsRESAGrid(){ return this.displayProductsRESA == 'grid'; }
  isDisplayProductsRESAList(){ return this.displayProductsRESA == 'list'; }
  setDisplayProductsRESAGrid(){ this.displayProductsRESA = 'grid'; }
  setDisplayProductsRESAList(){ this.displayProductsRESA = 'list'; }

  isEditPositionProducts(){ return this.editPositionProducts }
  launchEditPositionProducts(){ this.editPositionProducts = true; }
  stopEditPositionProducts(){ this.editPositionProducts = false; this.productsToSaved = []; }

  isEditPositionCategories(){ return this.editPositionCategories }
  launchEditPositionCategories(){ this.editPositionCategories = true; }
  stopEditPositionCategories(){ this.editPositionCategories = false; }

  isEditPositionGroups(){ return this.editPositionGroups }
  launchEditPositionGroups(){ this.editPositionGroups = true; }
  stopEditPositionGroups(){ this.editPositionGroups = false; }

  loadRESAServices(){
    this.userService.loadRESAServices(function(services, places){
      this.services = services;
      if(places != null){
        this.places = places;
      }
      this.services.sort(function(serviceA, serviceB){
        return serviceA.position - serviceB.position;
      });
    }.bind(this), function(error){
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this))
  }

  getRESAProducts(){
    this.actionLaunched = true;
    this.userService.getRESAProducts(function(data){
      this.actionLaunched = false;
      this.resaProducts = data;
      for(var i = 0; i < this.resaProducts.length; i++){
        var product = this.resaProducts[i];
        product.toSave = true;
      }
    }.bind(this), function(error){
      this.actionLaunched = false;
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this))
  }


  goSettingsTva(){
    this.navService.goSettingsTva();
  }

  getTvaList():any[]{
    var tvaList = [];
    if(this.userService.haveLicense()){
      tvaList = this.getLicense().tvaList;
    }
    return tvaList;
  }

  getTvaById(idTva){
    var tvaList = this.getTvaList();
    for(var i = 0; i < tvaList.length; i++){
      if(tvaList[i]._id == idTva){
        return tvaList[i];
      }
    }
    return null;
  }

  getServicesFiltered(){
    if(this.filterRESAService.place.length == 0) return this.services;
    var servicesFiltered = [];
    for(var i = 0; i < this.services.length; i++){
      var service = this.services[i];
      if(service.places.indexOf(this.filterRESAService.place) != -1){
        servicesFiltered.push(service);
      }
    }
    return servicesFiltered;
  }


  getServiceBySlug(slug){
    for(var i = 0; i < this.services.length; i++){
      var service = this.services[i];
      if(service.slug == slug){
        return service;
      }
    }
    return {name:'', servicePrices:[]};
  }

  getServicePriceBySlug(service, slug){
    for(var i = 0; i < service.servicePrices.length; i++){
      var servicePrice = service.servicePrices[i];
      if(servicePrice.slug == slug){
        return servicePrice;
      }
    }
    return {name:''};
  }

  getPlace(idPlace){
    for(var i = 0; i < this.places.length; i++){
      var place = this.places[i];
      if(place.id == idPlace){
        return place;
      }
    }
    return {name:''};
  }

  getNameOfService(nameObject){
    var result = '';
    var key = 'fr_FR';
    result = nameObject[key];
    if(!result){
      var keys = Object.keys(nameObject);
      if(keys.length > 0){
        result = nameObject[keys[0]];
      }
    }
    return result;
  }

  stopPropagation(event){
    event.stopPropagation();
  }

  changePosition(product){
    var products = this.getProducts();
    var index = products.findIndex(function(element){ return element._id == product._id });
    if(index != -1 && index != product.position){
      products.splice(index, 1);
      products.splice(product.position, 0, product);
      this.updateProductsToSave(product);
      for(var i = 0; i < products.length; i++){
        if(products[i].position != i){
          products[i].position = i;
          this.updateProductsToSave(products[i]);
        }
      }
    }
  }

  updateProductsToSave(product){
    var index = this.productsToSaved.findIndex(function(element){ return element._id == product._id });
    if(index > -1){
      this.productsToSaved[index] = product;
    }
    else {
      this.productsToSaved.push(product);
    }
  }

  saveProductsPositions(){
    console.log(this.productsToSaved);
    this.updateManyProducts(this.productsToSaved, () => {
      this.stopEditPositionProducts();
    });
  }


  launchEditProduct(product){
    if(!this.isEditPositionProducts()){
      this.setProductSubPage();
      this.currentEditProduct = product;
      if(product == null){
        this.formProduct = {name:'', color: '#37b294', publicName:'', reference:'', price:0, priceTTC:0, tva:0, outlay:false, categories:[], slugs:[], thresholded:false, thresholdType:0, thresholdPrices:[], hours: false, synchronized:false, generated:false};
      }
      else {
        this.formProduct = {name:product.name, color:product.color, publicName:product.publicName, reference:product.reference, price:product.price, priceTTC:product.priceTTC, tva:product.tva, outlay:product.outlay, categories:product.categories, slugs:product.slugs, thresholded:product.thresholded, thresholdType:product.thresholdType, thresholdPrices:JSON.parse(JSON.stringify(product.thresholdPrices)), hours:product.hours, synchronized:product.synchronized, generated:product.generated};
      }
      this.recalculateThresholdPrices();
    }
  }

  launchDuplicateProduct(product){
    if(product != null){
      this.setProductSubPage();
      this.currentEditProduct = null;
      if(product == null){
        this.formProduct = {name:'', color:'', publicName:'', reference:'', price:0, priceTTC:0, tva:0, outlay:false, categories:[], slugs:[], thresholded:false, thresholdType:0, thresholdPrices:[], hours: false, synchronized:false, generated:false};
      }
      else {
        this.formProduct = {name:product.name, color:product.color, publicName:product.publicName, reference:product.reference, price:product.price, priceTTC:product.priceTTC, tva:product.tva, outlay:product.outlay, categories:product.categories, slugs:product.slugs, thresholded:product.thresholded, thresholdType:product.thresholdType, thresholdPrices:JSON.parse(JSON.stringify(product.thresholdPrices)), hours:product.hours, synchronized:false, generated:false};
      }
      this.recalculateThresholdPrices();
    }
  }

  addThresholdPricesIfNone(){
    if(this.formProduct.thresholded && this.formProduct.thresholdPrices.length == 0){
      this.addThresholdPrices();
    }
  }

  addThresholdPrices(){
    this.formProduct.thresholdPrices.push({
      min:0,
      max:0,
      price:0,
      priceTTC:0,
      fixedPrice:0,
      fixedPriceTTC:0,
      priceSupPerson:0,
      priceSupPersonTTC:0,
    })
  }

  getTitlePrice(thresholdType){
    if(thresholdType == 0) return 'total';
    return 'unitaire';
  }

  deleteThresholdPrices(index){
    this.formProduct.thresholdPrices.splice(index, 1);
  }

  recalculateThresholdPrices(){
    for(var i = 0; i < this.formProduct.thresholdPrices.length; i++){
      var thresholdPrice = this.formProduct.thresholdPrices[i];
      this.calculateThresholdHTPriceProduct(this.formProduct, thresholdPrice, 'price');
      this.calculateThresholdHTPriceProduct(this.formProduct, thresholdPrice, 'fixed');
      this.calculateThresholdHTPriceProduct(this.formProduct, thresholdPrice, 'supPerson');
    }
    this.calculateThresholdHTPriceProduct(this.formProduct, this.formProduct);
  }

  calculateThresholdHTPriceProduct(product, thresholdPrice, type = 'price'){
    var tvaValue = 0
    if(product.tva != null){
      var tva = this.getTvaById(product.tva);
      if(tva != null && tva.value != null){
        tvaValue = tva.value;
      }
    }
    if(type == 'price') thresholdPrice.price = Number((thresholdPrice.priceTTC / (1 + (tvaValue / 100))).toFixed(2));
    if(type == 'fixed') thresholdPrice.fixedPrice = Number((thresholdPrice.fixedPriceTTC / (1 + (tvaValue / 100))).toFixed(2));
    if(type == 'supPerson') thresholdPrice.priceSupPerson = Number((thresholdPrice.priceSupPersonTTC / (1 + (tvaValue / 100))).toFixed(2));
  }

  calculateThresholdTTCPriceProduct(product, thresholdPrice, type = 'price'){
    var tvaValue = 0
    if(product.tva != null){
      var tva = this.getTvaById(product.tva);
      if(tva != null && tva.value != null){
        tvaValue = tva.value;
      }
    }
    if(type == 'price') thresholdPrice.priceTTC = Number((thresholdPrice.price * (1 + tvaValue / 100)).toFixed(2));
    if(type == 'fixed') thresholdPrice.fixedPriceTTC = Number((thresholdPrice.fixedPrice * (1 + tvaValue / 100)).toFixed(2));
    if(type == 'supPerson') thresholdPrice.priceSupPersonTTC = Number((thresholdPrice.priceSupPerson * (1 + tvaValue / 100)).toFixed(2));
  }

  addSlugs(){
    if(this.formProductSlugs.service.length > 0 && this.formProductSlugs.servicePrice.length && this.formProductSlugs.number > 0){
      this.formProduct.slugs.push(this.formProductSlugs);
      this.formProductSlugs = {service:'', servicePrice:'', number:1};
    }
  }

  removeSlugs(index){
    this.formProduct.slugs.splice(index, 1);
  }

  getHTPriceProduct(product){
    if(!product.thresholded || product.thresholdPrices.length == 0) return product.price;
    else {
      return product.thresholdPrices[0].price + product.thresholdPrices[0].fixedPrice;
    }
  }

  getTTCPriceProduct(product){
    if(!product.thresholded || product.thresholdPrices.length == 0) return product.priceTTC;
    else {
      return product.thresholdPrices[0].priceTTC + product.thresholdPrices[0].fixedPriceTTC;
    }
  }

  addCategoryToProduct(){
    if(this.formProductAddCategory != null){
      this.formProduct.categories.push(this.formProductAddCategory);
      this.formProductAddCategory = null;
    }
  }

  deleteCategoryToProduct(index){
    var category = this.getCategoryById(this.formProduct.categories[index]);
    var categoryName =  '';
    if(category){
      categoryName = category.name;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous retirer le produit de la catégorie ' + categoryName+ ' ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.formProduct.categories.splice(index, 1);
      }
    });
  }

  isAllPricesNotNull(){
    var allPricesNotNull = this.formProduct.thresholdPrices.length > 0;
    for(var i = 0; i < this.formProduct.thresholdPrices.length; i++){
      var thresholdPrice = this.formProduct.thresholdPrices[i];
      allPricesNotNull = allPricesNotNull && (thresholdPrice.price > 0 || thresholdPrice.fixedPrice > 0);
    }
    return allPricesNotNull;
  }

  isValidProduct(){
    var allPricesNotNull = this.isAllPricesNotNull();
    var ok = this.formProduct.name.length > 0 &&
            this.formProduct.publicName.length > 0 &&
            ((!this.formProduct.thresholded && this.formProduct.price > 0) ||
            (this.formProduct.thresholded && allPricesNotNull)) &&
            (this.formProduct.tva != null && this.formProduct.tva!=0);
    if(this.formProduct.synchronized && this.currentEditProduct != null){
      ok = ok &&
        ((this.formProduct.name == this.currentEditProduct.name) &&
        (this.formProduct.publicName == this.currentEditProduct.publicName) &&
        (this.formProduct.reference == this.currentEditProduct.reference) &&
         (this.formProduct.tva == this.currentEditProduct.tva) &&
          (this.formProduct.price == this.currentEditProduct.price) &&
          (this.formProduct.priceTTC == this.currentEditProduct.priceTTC) &&
        JSON.stringify(this.formProduct.thresholdPrices) == JSON.stringify(this.currentEditProduct.thresholdPrices));
    }
    return ok;
  }

  addRESAProducts(products){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '500px',
        data: { title:'Synchroniser ?', text: 'Voulez-vous créer et synchroniser les produits sélectionnés ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          var products = [];
          for(var i = 0; i < this.resaProducts.length; i++){
            var product = this.resaProducts[i];
            if(product.toSave && product.idAlreadyGenerated == null){
              products.push(product);
            }
          }
          this.addProducts(products, () =>{
            this.resaProducts = [];
          });
        }
      });
    }
  }

  addProducts(products, callback){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      this.userService.addProducts(products, function(){
        this.actionLaunched = false;
        this.launchEditProduct();
        this.setProductsSubPage();
        if(callback) callback();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  addProduct(){
    if(this.isValidProduct() && !this.actionLaunched){
      this.actionLaunched = true;
      var product = JSON.parse(JSON.stringify(this.formProduct));
      this.userService.addProduct(product, function(){
        this.actionLaunched = false;
        this.launchEditProduct();
        this.setProductsSubPage();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  editProduct(){
    if(this.isValidProduct() && !this.actionLaunched){
      this.actionLaunched = true;
      var product = JSON.parse(JSON.stringify(this.currentEditProduct));
      product.name = this.formProduct.name;
      product.color = this.formProduct.color;
      product.publicName = this.formProduct.publicName;
      product.reference = this.formProduct.reference;
      product.price = this.formProduct.price;
      product.priceTTC = this.formProduct.priceTTC;
      product.tva = this.formProduct.tva;
      product.outlay = this.formProduct.outlay;
      product.slugs = this.formProduct.slugs;
      product.thresholded = this.formProduct.thresholded;
      product.thresholdType = this.formProduct.thresholdType;
      product.thresholdPrices = this.formProduct.thresholdPrices;
      product.hours = this.formProduct.hours;
      product.synchronized = this.formProduct.synchronized;
      product.generated = this.formProduct.generated;
      product.categories = [];
      for(var i = 0; i < this.formProduct.categories.length; i++){
        if(this.getCategoryById(this.formProduct.categories[i])){
          product.categories.push(this.formProduct.categories[i]);
        }
      }
      this.updateManyProducts([product], () => {
        this.launchEditProduct(null);
        this.setProductsSubPage();
      });
    }
  }

  updateManyProducts(products, successCallback){
    if(products.length == 0) return ;
    this.actionLaunched = true;
    this.userService.editProduct(products[0], function(){
      if(products.length == 1) {
        this.actionLaunched = false;
        successCallback();
      }
      else {
        products.shift();
        this.updateManyProducts(products, successCallback);
      }
    }.bind(this), function(error){
      this.actionLaunched = false;
      let snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 8000,
      });
      snackBarRef.onAction().subscribe(() => {
        snackBarRef.dismiss();
      });
    }.bind(this));
  }

  resynchonizationRESAProduct(){
    if(this.isValidProduct() && !this.actionLaunched){
      this.actionLaunched = true;
      var product = JSON.parse(JSON.stringify(this.currentEditProduct));
      this.userService.resynchonizationRESAProduct(product, function(){
        this.actionLaunched = false;
        this.launchEditProduct();
        this.setProductsSubPage();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteProduct(){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer le produit ' + this.currentEditProduct.name + ' ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.userService.deleteProduct(this.currentEditProduct._id, function(){
            this.actionLaunched = false;
            this.setProductsSubPage();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  createDataCategories(){
    this.dataCategories = this.getCategories();
    for(var i = 0; i < this.dataCategories.length; i++){
      var category = this.dataCategories[i];
      category.position = i;
    }
  }

  getCategoriesFiltered():any[]{
    var categories = this.dataCategories;
    var filtered = [];
    for(var i = 0; i < categories.length; i++){
      var category = categories[i];
      var filtersName = this.filtersCategories.name.split(' ');
      var find = true;
      for(var j = 0; j < filtersName.length; j++){
        find = find && category.name.toLowerCase().indexOf(filtersName[j].toLowerCase()) > -1;
      }
      if(find){
        filtered.push(category);
      }
    }
    return filtered;
  }

  getCategories():any[]{
    var categories = [];
    if(this.userService.haveLicense()){
      categories = this.userService.getLicense().categories;
    }
    return categories;
  }

  changePositionCategory(category){
    var license = this.getLicense()
    var index = license.categories.findIndex(function(element){ return element._id == category._id });
    if(index != -1 && index != category.position){
      license.categories.splice(index, 1);
      license.categories.splice(category.position, 0, category);
    }
    this.createDataCategories();
  }

  saveCategoriesPositions(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var subLicense = this.userService.getLicense();
      this.userService.post('api/v1/subLicense/:token', {idLicense: subLicense.idLicense, subLicense:subLicense}, data => {
        console.log(data);
        this.actionLaunched = false;
        this.stopEditPositionCategories();
      }, error => {
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error.message, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
    }
  }

  getAnotherCategoriesInArray(array){
    var categories = [];
    if(array == null){
     categories = this.getCategories();
    }
    else {
      var allCategories = this.getCategories();
      for(var i = 0; i < allCategories.length; i++){
        if(array.indexOf(allCategories[i]._id) == -1){
          categories.push(allCategories[i]);
        }
      }
    }
    return categories;
  }

  getAnotherCategories(){
    var categories = [];
    if(this.currentEditCategory == null){
     categories = this.getCategories();
    }
    else {
      var allCategories = this.getCategories();
      for(var i = 0; i < allCategories.length; i++){
        if(allCategories[i]._id != this.currentEditCategory._id){
          categories.push(allCategories[i]);
        }
      }
    }
    return categories;
  }

  getCategoryById(idCategory){
    if(idCategory != null){
      var allCategories = this.getCategories();
      for(var i = 0; i < allCategories.length; i++){
        if(allCategories[i]._id == idCategory){
          return allCategories[i];
        }
      }
    }
    return null;
  }

  getNumberProducts(idCategory){
    var products = this.getProducts();
    var number = 0;
    for(var i = 0; i < products.length; i++){
      var product = products[i];
      if(product != null && product.categories != null && product.categories.indexOf(idCategory) != -1){
        number++;
      }
    }
    return number;
  }

  getNumberGroups(idCategory){
    var groups = this.getGroups();
    var number = 0;
    for(var i = 0; i < groups.length; i++){
      var group = groups[i];
      if(group != null && group.categories != null && group.categories.indexOf(idCategory) != -1){
        number++;
      }
    }
    return number;
  }


  getParent(category){
    var parent = '';
    if(category != null && category.parent != null){
      var parentCategory = this.getCategoryById(category.parent);
      if(parentCategory != null) {
        if(parentCategory.parent != null){
          parent += ' > ';
        }
        parent = this.getParent(parentCategory) + parent + parentCategory.name;
      }
    }
    return parent;
  }

  getCompressParent(text){
    var compress = 15;
    if(text.length <= compress * 2) return text;
    return text.substring(0, compress) + ' ... ' + text.substring(text.length - compress, text.length);
  }

  launchEditCategory(category){
    this.setCategorySubPage();
    this.currentEditCategory = category;
    if(category == null){
      this.formCategory = {name:'', color:'', parent:null};
    }
    else {
      this.formCategory = {name:category.name, color:category.color, parent:category.parent};
    }
  }

  addCategory(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var category = JSON.parse(JSON.stringify(this.formCategory));
      this.licenseService.addCategory(this.getLicense().idLicense, category, function(){
        this.actionLaunched = false;
        this.launchEditCategory();
        this.setCategoriesSubPage();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  editCategory(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var category = JSON.parse(JSON.stringify(this.currentEditCategory));
      category.name = this.formCategory.name;
      category.color = this.formCategory.color;
      category.parent = this.formCategory.parent;
      this.licenseService.editCategory(this.getLicense().idLicense, category, function(){
        this.actionLaunched = false;
        this.launchEditCategory();
        this.setCategoriesSubPage();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteCategory(category){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer la catégorie ' + category.name + ' et ses sous-catégories ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.deleteCategory(this.getLicense().idLicense, category._id, function(subLicense){
            this.actionLaunched = false;
            this.setCategoriesSubPage();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  getAnotherProductsInArray(array){
    var products = [];
    if(array == null){
     products = this.getProducts();
    }
    else {
      var allProducts = this.getProducts();
      for(var i = 0; i < allProducts.length; i++){
        if(array.indexOf(allProducts[i]._id) == -1){
          products.push(allProducts[i]);
        }
      }
    }
    return products;
  }

  createDataGroups(){
    this.dataGroups = this.getGroups();
    for(var i = 0; i < this.dataGroups.length; i++){
      var group = this.dataGroups[i];
      group.position = i;
    }
  }

  getGroupsFiltered():any[]{
    var groups = this.dataGroups;
    var filtered = [];
    for(var i = 0; i < groups.length; i++){
      var group = groups[i];
      var filtersName = this.filtersGroups.name.split(' ');
      var find = true;
      for(var j = 0; j < filtersName.length; j++){
        find = find && (group.name.toLowerCase().indexOf(filtersName[j].toLowerCase()) > -1 || group.publicName.toLowerCase().indexOf(filtersName[j].toLowerCase()) > -1);
      }
      if(find){
        filtered.push(group);
      }
    }
    return filtered;
  }

  getGroups(){
    var groups = [];
    if(this.userService.haveLicense()){
      groups = this.userService.getLicense().groups;
    }
    return groups;
  }

  changePositionGroup(group){
    var license = this.getLicense()
    var index = license.groups.findIndex(function(element){ return element._id == group._id });
    if(index != -1 && index != group.position){
      license.groups.splice(index, 1);
      license.groups.splice(group.position, 0, group);
    }
    this.createDataGroups();
  }

  saveGroupsPositions(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var subLicense = this.userService.getLicense();
      this.userService.post('api/v1/subLicense/:token', {idLicense: subLicense.idLicense, subLicense:subLicense}, data => {
        console.log(data);
        this.actionLaunched = false;
        this.stopEditPositionGroups();
      }, error => {
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error.message, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      });
    }
  }

  launchEditGroup(group){
    this.setGroupSubPage();
    this.currentEditGroup = group;
    if(group == null){
      this.formGroup = {name:'', color:'', publicName:'', categories:[], products:[]};
    }
    else {
      this.formGroup = {name:group.name, color:group.color, publicName:group.publicName, categories:group.categories, products: group.products};
    }
  }

  addCategoryToGroup(){
    if(this.formGroupAddCategory != null){
      this.formGroup.categories.push(this.formGroupAddCategory);
      this.formGroupAddCategory = null;
    }
  }

  addProductToGroup(){
    if(this.formGroupAddProduct != null){
      this.formGroup.products.push(this.formGroupAddProduct);
      this.formGroupAddProduct = null;
    }
  }

  deleteCategoryToGroup(index){
    var category = this.getCategoryById(this.formGroup.categories[index]);
    var categoryName =  '';
    if(category){
      categoryName = category.name;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous retirer le groupe de la catégorie ' + categoryName+ ' ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.formGroup.categories.splice(index, 1);
      }
    });
  }

  deleteProductToGroup(index){
    var product = this.getProductById(this.formGroup.products[index]);
    var productName =  '';
    if(product){
      productName = product.name;
    }
    let dialogRef = this.dialog.open(ConfirmDialog, {
      width: '250px',
      data: { title:'Supprimer ?', text: 'Voulez-vous retirer le produit ' + productName + ' du groupe ?'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.formGroup.products.splice(index, 1);
      }
    });
  }

  addGroup(){
    if(!this.actionLaunched){
      this.actionLaunched = true;
      var group = JSON.parse(JSON.stringify(this.formGroup));
      this.licenseService.addGroup(this.getLicense().idLicense, group, function(){
        this.actionLaunched = false;
        this.launchEditGroup();
        this.setGroupsSubPage();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  editGroup(){
    if(!this.actionLaunched){
      var group = JSON.parse(JSON.stringify(this.currentEditGroup));
      group.name = this.formGroup.name;
      group.color = this.formGroup.color;
      group.publicName = this.formGroup.publicName;
      group.categories = [];
      for(var i = 0; i < this.formGroup.categories.length; i++){
        if(this.getCategoryById(this.formGroup.categories[i])){
          group.categories.push(this.formGroup.categories[i]);
        }
      }
      group.products = [];
      for(var i = 0; i < this.formGroup.products.length; i++){
        if(this.getProductById(this.formGroup.products[i])){
          group.products.push(this.formGroup.products[i]);
        }
      }
      this.actionLaunched = true;
      this.licenseService.editGroup(this.getLicense().idLicense, group, function(){
        this.actionLaunched = false;
        this.launchEditGroup();
        this.setGroupsSubPage();
      }.bind(this), function(error){
        this.actionLaunched = false;
        let snackBarRef = this.snackBar.open(error, 'OK', {
          duration: 8000,
        });
        snackBarRef.onAction().subscribe(() => {
          snackBarRef.dismiss();
        });
      }.bind(this));
    }
  }

  deleteGroup(group){
    if(!this.actionLaunched){
      let dialogRef = this.dialog.open(ConfirmDialog, {
        width: '250px',
        data: { title:'Supprimer ?', text: 'Voulez-vous supprimer le group ' + group.name + ' ?'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.actionLaunched = true;
          this.licenseService.deleteGroup(this.getLicense().idLicense, group._id, function(subLicense){
            this.actionLaunched = false;
            this.setGroupsSubPage();
          }.bind(this), function(error){
            this.actionLaunched = false;
            let snackBarRef = this.snackBar.open(error, 'OK', {
              duration: 8000,
            });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }.bind(this));
        }
      });
    }
  }

  getTotalTTCGroup(group){
    var total = 0;
    for(var i = 0; i < group.products.length; i++){
      var idProduct = group.products[i];
      var product = this.getProductById(idProduct);
      if(product != null){
        total += product.priceTTC;
      }
    }
    return total;
  }
}
