import {Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


declare let html2pdf;

@Component({
  selector: 'pad-dialog',
  templateUrl: 'padDialog.html',
  styleUrls:['./padDialog.css']
})


export class PadDialog {
  public padNumber = 0;

  constructor(public dialogRef: MatDialogRef<PadDialog>, @Inject(MAT_DIALOG_DATA) public data: any,  public dialog: MatDialog) {
    if(this.data != null){
      this.padNumber = this.data.number;
    }
  }

  updatePadNumber(number){
    this.padNumber = number;
  }

  enterPadNumberPressed(event){
    this.dialogRef.close(event);
  }
}
